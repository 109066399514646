import { mapActions, mapState } from 'pinia'

import findCartByParam from '@/lib/utils/findCartByParam'
import { ModalLoader } from '@/lib/utils/loader'

import { currentCartStore } from '@/stores/currentCart'
import { quotesStore } from '@/stores/quotes'

// @vue/component
export const UpdateRouteByCartIdMixin = {
  computed: {
    ...mapState(currentCartStore, ['currentCart', 'currentCartAccountId']),
    pageId() {
      return this.$route.params.cartId ? this.$route.params.cartId : null
    }
  },

  beforeRouteLeave(to, from, next) {
    this.clearCart()
    next()
  },
  beforeRouteUpdate(to, from, next) {
    this.clearCart()
    next()
  },

  methods: {
    ...mapActions(quotesStore, ['getCart', 'getAccountInfo', 'clearCart']),

    async getDataWithParams(cartId) {
      ModalLoader.start()
      await this.getCart({
        cartId: this.pageId || cartId
      })
        .catch(() => {
          this.redirectToQuotesList()
        })
        .then(async (res) => {
          this.areDetailsExpanded = false
          this.currentCartAccountId &&
            (await this.getAccountInfo({
              customerId:
                this.currentCartAccountId || res?.header?.customerAccount || '',
              cart: this.currentCart || res
            }))
        })
        .finally(() => ModalLoader.stop())
    },

    async updateRouteByCartId(cartId) {
      if (this.pageId === cartId) {
        return
      }

      this.areDetailsExpanded = false
      await this.getCart({ cartId }).then(({ header: { type } }) => {
        const foundCart = findCartByParam('type', type)
        if (foundCart) {
          this.$router.push({
            name: foundCart.routeName,
            params: {
              cartId
            }
          })
          return
        }
        this.redirectToQuotesList()
      })
    },
    redirectToQuotesList() {
      this.$router.push({ name: 'carts' })
    },

    async checkRoute() {
      await this.getDataWithParams(this.$route.params.cartId)
    },
    async initializeCart() {
      await this.checkRoute()
      await this.getDetailsFormFields()
    }
  }
}
