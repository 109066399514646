import Vue from 'vue'

// @vue/component
export const DisplayToastMixin = {
  methods: {
    showInfoToast(toastMessage, duration = 1000) {
      Vue.toasted.info(toastMessage, {
        duration: duration,
        icon: {
          name: 'info'
        },
        closeOnSwipe: false,
        action: {
          icon: 'close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    showSuccessToast(toastMessage) {
      Vue.toasted.success(toastMessage, {
        duration: 5000,
        icon: {
          name: 'check_circle'
        },
        closeOnSwipe: false,
        action: {
          icon: 'close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    showErrorToast(toastMessage) {
      Vue.toasted.error(toastMessage, {
        duration: 15000,
        icon: {
          name: 'error'
        },
        action: {
          icon: 'close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    cleanAllToasts() {
      Vue.toasted.clear()
    }
  }
}
