<template>
  <div
    v-click-outside="faded"
    class="mt-input-product-suggest"
    :class="wrapperClass"
  >
    <slot name="before"></slot>
    <app-input
      ref="appInput"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :show-error="showError"
      autocomplete="off"
      data-lpignore
      :input-id="inputId"
      :class-list="inputClass"
      @input="handleSearch"
      @on-focus="focused"
      @keyup-down="handleInputDownPress"
      @keydown-tab="blurred($event)"
      @keydown-enter="blurred($event)"
    />
  </div>
</template>
<script>
import { debounce } from 'lodash'

import api from '@/lib/api'

import { InputProductSelectKeystrokesMixin } from '@/mixins/InputProductSelectKeystrokes.mixin.js'

import AppInput from '@/components/common/AppInput'

import clickOutsideDirective from '@/directives/click-outside'

const debounceWaitingTime = 500

const MIN_INPUT_LENGTH = 3

export default {
  name: 'InputProductSuggest',
  directives: {
    'click-outside': clickOutsideDirective
  },
  components: { AppInput },
  mixins: [InputProductSelectKeystrokesMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    allowReplacement: {
      type: Boolean,
      default: false
    },
    hideBom: {
      type: Boolean,
      default: false
    },
    suggestedSearch: {
      type: Boolean,
      default: true
    },
    showError: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String,
      default: null
    },
    cartType: {
      type: String,
      default: null
    },
    cartId: {
      type: String,
      default: null
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      suggestionsVisible: false,
      suggestions: [],
      promises: []
    }
  },
  computed: {
    isSuggestions() {
      return (
        this.suggestions && this.suggestions.length && this.suggestionsVisible
      )
    }
  },
  watch: {
    isSuggestions(isVisible) {
      this.$emit('suggestions-changed', {
        suggestions: this.suggestions,
        isVisible
      })
    }
  },
  methods: {
    reset() {
      this.$refs.appInput.$refs.input.focus()
      this.suggestionsVisible = false
      this.suggestions = []
    },
    handleSearch(value) {
      const inputValue = value.trim()
      this.$emit('input', inputValue)
      if (!inputValue.length) {
        this.$emit('suggestionSelected', { code: '' })
      }
      if (inputValue.length >= MIN_INPUT_LENGTH) {
        this.debouncedSearch(inputValue)
      } else {
        this.reset()
      }
    },
    debouncedSearch: debounce(async function (val) {
      this.$emit('search-product-event', val)
      await this.suggest(val)
    }, debounceWaitingTime),
    async suggest(searchTerm) {
      this.suggestions = []
      const promiseSearchProducts = api.search.searchProducts(
        searchTerm,
        this.allowReplacement,
        this.hideBom,
        this.suggestedSearch,
        this.customerId,
        this.cartType,
        this.cartId
      )

      this.promises.push(promiseSearchProducts)
      await promiseSearchProducts.then(async () => {
        this.suggestions = (await this.promises.at(-1)).data.products || []
      })
      this.suggestionsVisible = true

      this.$emit('suggestions-changed', {
        suggestions: this.suggestions,
        isVisible: this.suggestions.length
      })
    },
    blurred(e) {
      this.faded()
      this.$emit(
        'blurred',
        {
          code: this.value
        },
        e
      )
    },
    faded() {
      this.suggestionsVisible = false
      document.querySelector('body').classList.remove('no-scroll')
    },
    async focused() {
      if (this.value.length >= MIN_INPUT_LENGTH) await this.suggest(this.value)
    }
  }
}
</script>
