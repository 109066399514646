import googleTagManager from '@analytics/google-tag-manager'
import Analytics from 'analytics'

import settings from '@/lib/core/settings'
import { isProd2Env, isProdEnv, isStaging3Env } from '@/lib/utils/environment'

const googleTagManagerId =
  settings.app.extensionEnvVariables.GOOGLE_TAG_MANAGER_ID

function shouldInitialize() {
  // Run in S3 and Production only
  if (isStaging3Env || isProdEnv || isProd2Env) {
    return true
  }

  return false
}

const analytics = Analytics({
  plugins: [
    googleTagManager({
      containerId: googleTagManagerId,
      enabled: false
    })
  ]
})

const googleTagManagerHelper = {
  init: function initGoogleTagManager() {
    if (shouldInitialize()) {
      analytics.plugins.enable(['google-tag-manager'])
    }
  },
  disable: function disableGoogleTagManager() {
    analytics.plugins.disable(['google-tag-manager'])
  },
  pageView: function trackPageView() {
    analytics.page()
  },
  trackEvent: function trackEvent(eventName, payload) {
    analytics.track(eventName, payload)
  }
}

export default googleTagManagerHelper
