<template src="@/templates/common/ContentLightbox"></template>
<script>
export default {
  name: 'ContentLightbox',
  props: {
    title: {
      type: String,
      default: ''
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true
    },
    closeButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    overlayClicked() {
      if (this.closeOnOverlayClick) {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
