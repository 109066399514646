import { i18n } from '@root/i18n'
import Vue from 'vue'

/**
 * Currency formatter.
 * @param value
 */
const currency = (value) => {
  let amount = 0
  if (
    value !== undefined &&
    value !== null &&
    typeof value === 'object' &&
    'value' in value
  ) {
    amount = value.value
  } else if (!isNaN(value)) {
    amount = value
  }
  return i18n.n(amount, 'currency')
}

/**
 * Number formatter.
 * @param num
 * @returns {string}
 */
const formatNumber = (num) => {
  if (num === null || num === undefined) {
    return '0'
  }
  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

Vue.currency = Vue.prototype.$currency = currency
Vue.currency = Vue.prototype.$formatNumber = formatNumber
