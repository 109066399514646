import get from 'lodash/get'

import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Cart extends ApiBase {
  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getBaseUrl() {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/carts/${userId}`)
  }

  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getEntriesBaseUrl(cartId) {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/cart-entries/${userId}/${cartId}`)
  }

  /**
   * Get API vouchers base URL.
   * @returns {string}
   */
  getCouponsBaseUrl(cartId) {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/coupons/${userId}/${cartId}`)
  }

  /**
   * Create a new cart.
   * @returns {Promise<any>}
   */
  createCart() {
    const url = this.getBaseUrl()
    return new Promise((resolve, reject) => {
      this.transport
        .post(url)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Remove cart by ID.
   * @returns {Promise<any>}
   */
  removeCart(id) {
    const url = `${this.getBaseUrl()}/${id}`
    return new Promise((resolve, reject) => {
      this.transport
        .delete(url)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Get API endpoint base URL.
   * @param cartId
   * @returns {string}
   */
  getCartEntriesBaseUrl(cartId) {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/cart-entries/${userId}/${cartId}`)
  }

  /**
   * Get carts.
   * @returns {Promise<any>}
   */
  getCarts() {
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getBaseUrl())
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Apply a promotional code to the specified cart.
   * @param cart
   * @param voucherId
   * @returns {Promise<any>}
   */
  applyCoupon(cart, coupon) {
    return new Promise((resolve, reject) => {
      if (!coupon.length) {
        reject(new Error('Promo code cannot be empty!'))
        return
      }

      const data = { coupon }
      this.transport
        .post(`${this.getCouponsBaseUrl(cart.id)}`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Cancel a promo code from specified cart.
   * @param cart
   * @param voucherId
   * @returns {Promise<any>}
   */
  cancelCoupon(cart, voucherId) {
    return new Promise((resolve, reject) => {
      if (!voucherId.length) {
        reject(new Error('Promo code cannot be empty!'))
        return
      }

      this.transport
        .delete(`${this.getCouponsBaseUrl(cart.id)}/${voucherId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Add a product to cart.
   * @param cartId
   * @param productId
   * @param quantity
   * @returns {*|AxiosPromise<any>|void}
   */
  add(cartId, productId, quantity = 1) {
    const data = [
      {
        productId: productId,
        quantity
      }
    ]
    return new Promise((resolve, reject) => {
      this.transport
        .post(`${this.getEntriesBaseUrl(cartId)}`, data)
        .then((response) => {
          resolve()
        })
        .catch((reason) => {
          let message = get(reason, 'response.data[0].message')
          if (!message) {
            message = 'The product cannot be found.'
          }
          reject(new Error(message))
        })
    })
  }

  /**
   * Bulk add items to cart.
   * @param cartId
   * @param products
   * @returns {Promise<any>}
   */
  bulkAdd(cartId, products) {
    const data = []
    products.forEach((product) => {
      if (!product.sku || !product.quantity || product.quantity < 0) {
        return
      }
      data.push({
        productId: product.sku,
        quantity: product.quantity
      })
    })

    return new Promise((resolve, reject) => {
      if (!data.length) {
        reject(new Error('cart.noItemsToAdd'))
      }

      this.transport
        .post(`${this.getEntriesBaseUrl(cartId)}`, data)
        .then((response) => {
          resolve()
        })
        .catch((reason) => {
          let message = get(reason, 'response.data[0].message')
          if (!message) {
            message = 'Selected product(s) cannot be added to cart.'
          }
          reject(new Error(message))
        })
    })
  }

  /**
   * Recalculate taxes for the given cart.
   * @param cart
   */
  recalculateTaxes(cart) {
    const userId = auth.getCurrentUserId()
    return this.transport.post(
      this.getEndpointUrl(`/checkout/recalculate-taxes/${userId}/${cart.id}`)
    )
  }

  /**
   * Remove an item from the cart.
   * @param cart
   * @param item
   */
  remove(cart, item) {
    return this.transport.delete(
      `${this.getCartEntriesBaseUrl(cart.id)}/${item.id}`
    )
  }

  /**
   * Update cart entry's quantity.
   * @param cart
   * @param item
   * @param newQuantity
   * @returns {Promise<any>}
   */
  updateQuantity(cart, item, newQuantity) {
    const data = {
      quantity: newQuantity
    }
    return this.transport.patch(
      `${this.getEntriesBaseUrl(cart.id)}/${item.id}`,
      data
    )
  }
}

export default Cart
