<template src="@/templates/common/Idle"></template>
<script>
import { eventBus } from '@/lib/utils/bus'

export default {
  name: 'Idle',
  props: {
    secondsLeft: {
      type: Number,
      default: 0
    }
  },
  methods: {
    cancel() {
      eventBus.$emit('idle.restart')
    }
  }
}
</script>

<style scoped lang="scss">
.idle {
  @apply shadow-lg;

  .btn {
    @apply my-0 mx-auto;
  }
}
</style>
