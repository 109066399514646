import { i18n } from '@root/i18n'
import assignment from 'assignment'
import axios from 'axios'

import Auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'
import { eventBus } from '@/lib/utils/bus'

class Transport {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  /**
   * Get an absolute API URL
   * @param endpoint
   * @returns {string}
   */
  getApiUrl(endpoint, locale) {
    if (!locale || endpoint.startsWith('/rest/api/v3')) {
      return `${this.baseUrl}${endpoint}`
    }
    return `${this.baseUrl}/${locale}${endpoint}`
  }

  /**
   * Get HTTP headers for API request
   * @returns {{'Content-Type': string}}
   */
  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.locale || i18n.defaultLocale,
      'z-token': settings.services.api.apiKey
    }

    if (Auth.auth?.accessToken) {
      headers.authorization = `Bearer ${Auth.auth?.accessToken}`
    }
    return headers
  }

  /**
   * Perform a request.
   * @param method
   * @param endpoint
   * @param data
   * @param params
   * @returns {Promise<any>}
   */
  request(method, endpoint, data, params = {}) {
    const defaults = {
      silent: false
    }

    let {
      headers = {},
      requestOptions = {},
      transportOptions = {},
      locale = i18n.locale || i18n.defaultLocale
    } = params
    const reqOptions = assignment(defaults, transportOptions)
    return new Promise((resolve, reject) => {
      try {
        if (!reqOptions.silent) {
          eventBus.$emit('transport.loading', {
            method,
            endpoint
          })
        }
        headers = assignment(this.getHeaders(), headers)
        const options = {
          url: this.getApiUrl(endpoint, locale),
          method,
          data,
          headers,
          ...requestOptions
        }
        axios(options)
          .then((response) => {
            if (!reqOptions.silent) {
              eventBus.$emit('transport.completed', {
                method,
                endpoint
              })
            }
            resolve(response)
          })
          .catch((error) => {
            if (!reqOptions.silent) {
              eventBus.$emit('transport.completed', {
                method,
                endpoint
              })
            }
            if (!error || !('response' in error) || !error.response) {
              eventBus.$emit('transport.apiError')
            } else {
              if (error.response.status === 401) {
                // Handle "401 - Not Authorized" response.
                eventBus.$emit('auth.notAuthorized')
              }
              if (error.response.status === 500) {
                eventBus.$emit('transport.apiError500', error.response)
              }
            }
            reject(error)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  /**
   * POST request.
   * @param endpoint
   * @param data
   * @param params
   * @returns {*|Promise<any>}
   */
  post(endpoint, data, params) {
    return this.request('post', endpoint, data, params)
  }

  /**
   * DELETE request.
   * @param endpoint
   * @param data
   * @param params
   * @returns {*|Promise<any>}
   */
  delete(endpoint, data, params) {
    return this.request('delete', endpoint, data, params)
  }

  /**
   * GET request.
   * @param endpoint
   * @param data
   * @param params
   * @returns {*|Promise<any>}
   */
  get(endpoint, data, params) {
    return this.request('get', endpoint, data, params)
  }

  /**
   * PUT request.
   * @param endpoint
   * @param data
   * @param params
   * @returns {*|Promise<any>}
   */
  put(endpoint, data, params) {
    return this.request('put', endpoint, data, params)
  }

  /**
   * PATCH request.
   * @param endpoint
   * @param data
   * @param params
   * @returns {*|Promise<any>}
   */
  patch(endpoint, data, params) {
    return this.request('patch', endpoint, data, params)
  }
}

export default Transport
