<template>
  <div v-if="isVisible">
    <div class="overlay" @click.prevent="hide"></div>
    <transition
      :css="false"
      mode="out-in"
      appear
      @enter="simpleTransition.enter"
      @leave="simpleTransition.leave"
    >
      <div class="lightbox">
        <div class="lightbox-container">
          <a href="#" class="close-lightbox icon-close" @click.prevent="hide"
            >Close</a
          >
          <img :src="imageUrl" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import simpleTransition from '@/transitions/simple'

export default {
  name: 'Lightbox',
  data() {
    return {
      simpleTransition,
      imageUrl: '',
      isVisible: false
    }
  },
  methods: {
    show(imageUrl) {
      this.imageUrl = imageUrl
      this.isVisible = true
    },
    hide() {
      this.isVisible = false
    }
  }
}
</script>
