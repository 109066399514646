import api from '@/lib/api'
import storage from '@/lib/core/storage'

let states = storage.read('states')
if (!states) {
  api.data.getStates().then((data) => {
    states = data
    storage.save('states', states)
  })
}

/**
 * Find a state by ISO Code.
 * @param isoCode
 * @returns {null}
 */
const findByIsoCode = (isoCode) => {
  if (!states) {
    return null
  }
  return states.find((state) => state.isoCode === isoCode)
}

export { states, findByIsoCode }
