import { defineStore } from 'pinia'

import api from '@/lib/api'
import { Product } from '@/lib/models/product/product'
import { isPriceError } from '@/lib/utils/errorPriceCode'

const state = {
  currentProduct: null,
  currentProducts: [],
  pagination: {},
  facets: []
}

const mutations = {
  SET_CURRENT_PRODUCT_STOCK_STATUS(stockStatus) {
    this.currentProduct = {
      ...this.currentProduct,
      stockStatus
    }
  },
  SET_CURRENT_REPLACE_PRODUCT(replacementProduct) {
    this.currentProduct = {
      ...this.currentProduct,
      replacementProduct
    }
  }
}

const actions = {
  transformProduct(product) {
    return new Product(product)
  },
  async getProduct({ productId, isMainProduct }) {
    try {
      const { data } = await api.products.getProduct(productId)
      const transformedProduct = await this.transformProduct(data)
      if (isMainProduct) {
        this.currentProduct = transformedProduct
        return
      }

      return transformedProduct
    } catch (e) {
      this.currentProduct = null
      throw e.response.data
    }
  },
  transformProducts(products) {
    return products
      ? products.map((product) => {
          return this.transformProduct(product)
        })
      : []
  },
  async getProducts({ currentPage, pageSize, query, sort }) {
    try {
      const { data } = await api.products.getProducts(
        currentPage,
        pageSize,
        query,
        sort
      )
      let products = this.transformProducts(data.products)
      while (
        products.length === 0 &&
        data.pagination.currentPage < data.pagination.totalPages
      ) {
        const nextPage = data.pagination.currentPage + 1
        const { data: nextData } = await api.products.getProducts(
          nextPage,
          pageSize,
          query,
          sort
        )
        products = this.transformProducts(nextData.products)
        data.pagination = nextData.pagination
      }
      this.currentProducts = currentPage
        ? [...this.currentProducts, ...products]
        : products
      this.pagination = {
        page: data.pagination.currentPage,
        ...data.pagination
      }
      this.facets = data.facets || []
    } catch (e) {
      this.currentProducts = []
      this.pagination = {}
      this.facets = []
      throw e
    }
  },
  async calculatePriceWithStockStatus({
    productSKU,
    customerAccount,
    quantity = 1
  }) {
    try {
      const { data } = await api.quotes.calculateProductPrice({
        productSKU,
        customerAccount,
        quantity
      })
      return {
        price: data.lines[0].netPrice,
        stockStatus: data.lines[0].stockStatus,
        lowestQtyAllowed: data.lines[0].lowestQtyAllowed,
        multipleQty: data.lines[0].multipleQty
      }
    } catch (error) {
      const { data } = error.response
      if (data.lines?.length && data.lines[0]) {
        const response = {
          error: {
            errorCode: data.lines[0].errorCode,
            errorMessage: data.lines[0].errorMessage
          },
          isPriceError: isPriceError(data.lines[0].errorCode),
          messages: data.messages || data.lines[0].errorMessage,
          stockStatus: data.lines[0].stockStatus
        }
        throw response
      } else {
        throw data
      }
    }
  },

  async getAvailability({ customerId, itemData, cartId }) {
    try {
      const { data } = await api.products.getAvailability({
        customerId,
        itemData,
        cartId
      })
      return data?.data
    } catch (error) {
      throw error
    }
  }
}

export const productsStore = defineStore('products', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions: {
    ...mutations,
    ...actions
  }
})
