'use strict'
import { TweenMax, Power4 } from 'gsap'

export default {
  mode: 'out-in',
  enter: function (el, done) {
    const languagesList = el.querySelector('.content-area')
    if (languagesList) {
      TweenMax.fromTo(
        languagesList,
        0.35,
        { right: -400 },
        {
          right: 0,
          ease: Power4.easeInOut,
          onComplete: function () {
            done()
          }
        }
      )
    }
  },
  leave: function (el, done) {
    const languagesList = el.querySelector('.content-area')
    if (languagesList) {
      TweenMax.fromTo(
        languagesList,
        0.3,
        {
          right: 0
        },
        {
          right: -400,
          ease: Power4.easeIn,
          onComplete: function () {
            done()
          }
        }
      )
    }
  }
}
