<template>
  <div
    class="mt-app-footer bg-black static text-gray-200 bottom-0 left-0 w-full text-left p-0"
  >
    <div class="bg-secondary-500">
      <div
        class="mt-container clearfix mt-container-relative max-w-6xl py-0 px-2"
      >
        <div class="py-10 px-16 h-64 xl:h-full sm:p-5">
          <div class="flex items-start justify-between w-full lg:flex-wrap">
            <slot name="HelpNav">
              <help-nav />
            </slot>
            <div class="mt-3 mb-3 mx-2">
              <slot name="SocialNetworks">
                <social-networks />
              </slot>
            </div>
            <slot name="PoweredInfo">
              <powered-info />
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-container clearfix mt-container-relative max-w-7xl text-left"
    >
      <div class="mt-app-footer__content flex justify-between">
        <p class="text-gray-300 text-sm block my-auto mx-0 p-0">
          {{ copyrightText }}
        </p>
        <ul class="flex my-auto mx-0 list-none">
          <li
            v-for="({ url, text, fileName }, index) in links"
            :key="url"
            class="py-0 px-3"
            :class="{ 'border-r border-white': links.length - 1 !== index }"
          >
            <app-button
              v-if="isMexicoRegion"
              variant="light-link"
              color="gray"
              size="xs"
              modifiers="no-paddings"
              @click="download(url, fileName)"
            >
              <span class="text-sm">{{ text }}</span>
            </app-button>
            <a
              v-else
              class="mt-btn-content flex mt-btn-content--start hover:no-underline focus:no-underline"
              :href="`${url}`"
            >
              <span class="text-sm">{{ text }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import api from '@/lib/api'
import { momentDateEST } from '@/lib/utils/formatDate'

import { BlobMixin } from '@/mixins/Blob.mixin.js'
import { DisplayToastMixin } from '@/mixins/DisplayToast.mixin.js'

import AppButton from '@/components/common/AppButton'

import HelpNav from '@/components/footer/HelpNav'
import PoweredInfo from '@/components/footer/PoweredInfo'
import SocialNetworks from '@/components/footer/SocialNetworks'
import { userStore } from '@/stores/user'

const URL_PREFIX = '/static/mxn/legal/'

export default {
  name: 'AppFooter',
  components: {
    AppButton,
    SocialNetworks,
    HelpNav,
    PoweredInfo
  },
  mixins: [BlobMixin, DisplayToastMixin],
  computed: {
    ...mapState(userStore, ['isMexicoRegion']),
    copyrightText() {
      return this.$t('footer.copyright', {
        year: momentDateEST().year()
      })
    },
    links() {
      if (this.isMexicoRegion) {
        return [
          {
            text: this.$t('footer.terms'),
            url: `${URL_PREFIX}Terminos-y-condiciones-del-servicio-MW-vf.docx`,
            fileName: 'Terminos y condicions del devicio MW vf'
          },
          {
            text: this.$t('footer.privacy'),
            url: `${URL_PREFIX}Aviso-de-Privacidad-MW-vf.docx`,
            fileName: 'Aviso de Privacidad MW vf'
          },
          {
            text: this.$t('footer.cookie'),
            url: `${URL_PREFIX}Uso-de-Cookies-MW-vf.docx`,
            fileName: 'Uso de Cookies MW vf'
          }
        ]
      }
      return [
        {
          text: this.$t('footer.terms'),
          url: `https://www.milwaukeetool.com/Footer/Legal`
        },
        {
          text: this.$t('footer.privacy'),
          url: `https://www.milwaukeetool.com/Footer/Privacy`
        },
        {
          text: this.$t('footer.contact'),
          url: `https://www.milwaukeetool.com/Contact`
        }
      ]
    }
  },
  methods: {
    async download(downloadUrl, fileName) {
      try {
        const response = await api.user.downloadFile({ downloadUrl })
        this.downloadBlob(response, fileName)
      } catch (error) {
        this.showErrorToast(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-app-footer {
  &__content {
    min-height: 52px;
  }
}
</style>
