import ApiBase from '@/lib/api/v1/api-base'

class Data extends ApiBase {
  /**
   * Get a list of states.
   * @returns {Promise<any>}
   */
  getStates() {
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getEndpointUrl('/data/states'))
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Data
