<template>
  <ul
    :class="[
      'mt-input-suggestions mt-max-height--300 mt-inputs-suggest-list-shadow absolute p-0 rounded-lg mt-z-100 overflow-auto bg-white',
      {
        'mt-input-product-suggest-list--active': suggestions.length
      },
      classList
    ]"
  >
    <li
      v-for="item in suggestions"
      :key="item.sku"
      class="mt-input-suggestions__item border-b border-solid border-gray-100 p-2.5 flex items-start justify-between text-xs cursor-pointer hover:bg-gray-50 focus:bg-gray-50"
      tabindex="-1"
      @click.prevent="select(item)"
      @touchstart.prevent="select(item)"
      @keyup.enter="keySelect($event, item)"
      @keydown.tab="keySelect($event, item)"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-xs text-gray-800 mr-3" v-html="item.name"></div>
      <span>{{ $t('common.sku') }}: {{ item.code }}</span>
    </li>
  </ul>
</template>
<script>
import disableBodyScroll from '@/lib/utils/disableBodyScroll'

export default {
  name: 'InputProductSuggestList',
  props: {
    suggestions: {
      type: Array,
      required: true
    },
    classList: {
      type: String,
      default: 'm-0'
    }
  },
  methods: {
    select(item) {
      disableBodyScroll(false)
      this.$emit('select', item)
    },
    keySelect(event, item) {
      disableBodyScroll(false)
      event.preventDefault()
      this.$emit('select', item)
    }
  }
}
</script>