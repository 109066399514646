import IntegrityCheck from '@/lib/core/integrity-check'

export default {
  methods: {
    /**
     * Get app's version.
     * @return String
     */
    appVersion() {
      const pkg = require('@/../package')
      return pkg.version
    },

    /**
     * Output app's version.
     */
    displayAppVersion() {
      console.log(`🛒 ZCommerce v${this.appVersion()}`)
    },

    /**
     * Perform a verification of app's settings.
     */
    selfCheck() {
      const check = new IntegrityCheck()
      const missingSettings = check.missingSettings
      if (missingSettings && missingSettings.length > 0) {
        this.$router.push('/integrity-check-failed')
      }
    }
  },
  created() {
    this.displayAppVersion()
    this.selfCheck()
  }
}
