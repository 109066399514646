import { currentCartStore } from '@/stores/currentCart'
import { customersStore } from '@/stores/customers'
import { invoicesStore } from '@/stores/invoices'
import { metricsStore } from '@/stores/metrics'
import { ordersStore } from '@/stores/orders'
import { productsStore } from '@/stores/products'
import { quotesStore } from '@/stores/quotes'
import { rolesStore } from '@/stores/roles'
import { uploadOrdersStore } from '@/stores/upload-orders'
import { userStore } from '@/stores/user'

export default {
  currentCartStore,
  customersStore,
  invoicesStore,
  metricsStore,
  ordersStore,
  productsStore,
  quotesStore,
  rolesStore,
  uploadOrdersStore,
  userStore
}
