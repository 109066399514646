import settings from '@/lib/core/settings'

const ENVIRONMENTS = {
  local: 'LOCAL',
  d1: 'MET-D1',
  d2: 'MET-D2',
  s2: 'MET-S2',
  s3: 'MET-S3',
  p1: 'MET-P1',
  p2: 'MET-P2'
}

export const appEnv = settings.app.extensionEnvVariables.APP_ENV

export const isDevEnv =
  appEnv === ENVIRONMENTS.local ||
  appEnv === ENVIRONMENTS.d1 ||
  appEnv === ENVIRONMENTS.d2

export const isStaging2Env = appEnv === ENVIRONMENTS.s2

export const isStaging3Env = appEnv === ENVIRONMENTS.s3

export const isStagingEnv = isStaging2Env || isStaging3Env

export const isProd2Env = appEnv === ENVIRONMENTS.p2

export const isNonProdEnv = isDevEnv || isStagingEnv || isProd2Env

export const isProdEnv = appEnv === ENVIRONMENTS.p1
