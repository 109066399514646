import { getActivePinia } from 'pinia'

import router from '@/router'
import { rolesStore } from '@/stores/roles'

router.beforeEach((to, from, next) => {
  if (getActivePinia() && to.meta.pageRestriction) {
    const isPageRestricted = !rolesStore()[to.meta.pageRestriction]
    if (isPageRestricted) return next('/')
  }
  next()
})

export default router
