<template>
  <div v-body-scroll-locker="isBodyScrollLocked">
    <navigation-mobile
      v-if="!getScreenWidth.fromLg"
      :is-nav-visible="isNavVisible"
      :links="filteredLinks"
      @isNavVisibleChange="setNavState"
    />
    <header
      ref="appHeader"
      class="mt-app-header fixed top-0 right-0 left-0 lg:pt-2 bg-primary-500 border-primary-500 mt-z-300"
    >
      <div
        class="mt-app-header__container mt-container flex justify-between items-center flex-nowrap lg:flex-wrap lg:py-0"
      >
        <button
          class="js-app-header__menu-btn hidden lg:flex items-center mr-3"
          @click="toggleNavState"
        >
          <app-icon icon="menu" color="text-white" size="xxxl" />
        </button>

        <slot name="logo">
          <logo class="sm:mr-3" />
        </slot>

        <navigation-desktop
          v-if="getScreenWidth.fromLg"
          :links="filteredLinks"
        />

        <div
          v-if="isPLPorPDP"
          class="w-full flex order-none flex-1 mr-10 ml-auto relative mt:order-last p-0 m-0 rounded-lg sm:m-1 sm:my-3 lg:block lg:my-3 lg:mx-5 lg:mt-flex-basis"
        >
          <input-product-suggest
            v-model="skuNumber"
            :placeholder="$t('common.search')"
            :suggested-search="false"
            hide-bom
            wrapper-class="w-full sm:my-3 sm:ml-0"
            input-class="mt-0 w-full h-11 pr-16 pl-4 rounded-lg border border-gray-200 text-gray-900 text-sm"
            @blurred="doGlobalSearch"
            @suggestions-changed="suggestionsChanged"
            @search-product-event="setSearchTerm"
          />
          <template v-if="skuNumber">
            <button
              class="absolute right-10 top-1/2 transform -translate-y-1/2"
              @click="resetGlobalSearch"
            >
              <app-icon
                icon="close"
                color="text-gray-300"
                size="xxxl"
                class="leading-normal"
              />
            </button>
            <button
              class="absolute right-2 top-1/2 transform -translate-y-1/2"
              @click="doGlobalSearch"
            >
              <app-icon
                icon="search"
                color="text-gray-300"
                size="xxxl"
                class="leading-normal"
              />
            </button>
          </template>
          <input-product-suggest-list
            v-if="isSearchVisible && skuNumber.length"
            v-click-outside="hideSuggestions"
            class-list="w-full m-0 top-full left-0"
            :suggestions="suggestions"
            @select="redirectToSearchedProduct"
          />
        </div>

        <div class="flex items-center sm:mx-auto">
          <language-select-dropdown v-if="isLanguageSelectDropdown" />
          <create-new-cart-dropdown />
          <account
            :is-account-menu-visible="isAccountMenuVisible"
            @changeAccountMenuState="setAccountMenuState"
          />
        </div>
      </div>
    </header>
    <div
      class="mt-app-header-static-layer"
      :style="{ height: headerHeight + 'px' }"
    ></div>
    <transition name="fade">
      <div
        v-show="isNavVisible"
        class="hidden opacity-70 bg-gray-900 lg:block fixed top-0 right-0 bottom-0 left-0 transition duration-300 ease-in-out mt-z-300"
      ></div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { ClickOutside } from '@/lib/utils/click-outside'
import { bodyScrollLocker } from '@/lib/utils/directives/bodyScrollLocker'
import { isNonProdEnv } from '@/lib/utils/environment'

import { ScreenSizeMixin } from '@/mixins/ScreenSize.mixin.js'

import Account from '@/components/common/AppHeader/Account'
import CreateNewCartDropdown from '@/components/common/AppHeader/CreateNewCartDropdown'
import LanguageSelectDropdown from '@/components/common/AppHeader/LanguageSelectDropdown'
import NavigationDesktop from '@/components/common/AppHeader/NavigationDesktop'
import NavigationMobile from '@/components/common/AppHeader/NavigationMobile'
import AppIcon from '@/components/common/AppIcon'
import InputProductSuggest from '@/components/common/InputProductSuggest'
import InputProductSuggestList from '@/components/common/InputProductSuggestList'
import Logo from '@/components/common/Logo'

import clickOutsideDirective from '@/directives/click-outside'
import { productsStore } from '@/stores/products'
import { rolesStore } from '@/stores/roles'
import { userStore } from '@/stores/user'

export default {
  name: 'AppHeader',
  directives: {
    bodyScrollLocker,
    'click-outside': clickOutsideDirective
  },
  components: {
    NavigationDesktop,
    NavigationMobile,
    InputProductSuggest,
    LanguageSelectDropdown,
    Account,
    Logo,
    CreateNewCartDropdown,
    InputProductSuggestList,
    AppIcon
  },
  mixins: [
    ClickOutside(['.js-app-header__menu-btn', '.js-nav-mobile']),
    ScreenSizeMixin
  ],
  data() {
    return {
      isNavVisible: false,
      isAccountMenuVisible: false,
      headerHeight: 0,
      skuNumber: this.$route.query.query,
      suggestions: [],
      isSearchVisible: false
    }
  },
  computed: {
    ...mapState(rolesStore, [
      'isPLPorPDP',
      'isCartsAndCartDetailsPages',
      'isOrdersAndOrderDetailsPages',
      'isInvoicesPage',
      'isAccountsPage'
    ]),
    ...mapState(userStore, ['obrPhase']),

    isProductsLinkVisible() {
      return this.isPLPorPDP
    },

    navigationLinks() {
      let links = [
        {
          title: this.$t('header.products'),
          path: '/products',
          condition: this.isProductsLinkVisible,
          id: 'nav-products-link'
        },
        {
          title: this.$t('header.carts'),
          path: '/carts',
          condition: this.isCartsAndCartDetailsPages,
          id: 'nav-carts-link'
        },
        {
          title: this.$t('header.orders'),
          path: '/orders',
          condition: this.isOrdersAndOrderDetailsPages,
          id: 'nav-orders-link'
        },
        {
          title: this.$t('header.accounts'),
          path: '/customers',
          condition: this.isAccountsPage,
          id: 'nav-customers-link'
        }
      ]

      if (!this.obrPhase) {
        links.splice(3, 0, {
          title: this.$t('header.invoices'),
          path: '/invoices',
          condition: this.isInvoicesPage,
          id: 'nav-invoices-link'
        })
      }

      return links
    },

    filteredLinks() {
      return this.navigationLinks.filter((link) => link.condition)
    },

    isBodyScrollLocked() {
      return this.isNavVisible || this.isAccountMenuVisible
    },
    isLanguageSelectDropdown() {
      return isNonProdEnv && !this.obrPhase
    }
  },
  watch: {
    '$route.query.query'(val) {
      if (!val && this.$route.name === 'products') {
        this.skuNumber = ''
      }
    }
  },
  async mounted() {
    this.$on('clicked-outside', this.closeNav)
    this.$nextTick(this.getHeaderHeight())
    window.addEventListener('resize', this.getHeaderHeight)
  },
  beforeDestroy() {
    this.$off('clicked-outside', this.closeNav)
    window.removeEventListener('resize', this.getHeaderHeight)
  },
  methods: {
    ...mapActions(productsStore, ['getProducts']),
    setAccountMenuState(value) {
      this.isAccountMenuVisible = value
    },
    openNav() {
      this.isNavVisible = true
    },
    closeNav() {
      this.isNavVisible = false
    },
    toggleNavState() {
      this.isNavVisible = !this.isNavVisible
    },
    setNavState(value) {
      this.isNavVisible = value
    },
    getHeaderHeight() {
      this.headerHeight = this.$refs.appHeader.clientHeight
    },
    doGlobalSearch() {
      this.isSearchVisible = false

      this.$router.push({
        path: '/products',
        query: {
          query: this.skuNumber
        }
      })
    },
    redirectToSearchedProduct(item) {
      this.isSearchVisible = false

      // do not navigate to the same product otherwise proceed to another product
      if (
        this.$route.name === 'product' &&
        this.$route.params?.productId === item.code
      )
        return
      this.$router.push({ path: `/product/${item.code}` })
    },
    suggestionsChanged(params) {
      this.isSearchVisible = params.isVisible
      this.suggestions = params.suggestions
    },
    setSearchTerm(term) {
      this.isSearchVisible = true
      this.skuNumber = term
    },
    hideSuggestions() {
      this.isSearchVisible = false
    },
    resetGlobalSearch() {
      this.skuNumber = ''
      this.isSearchVisible = false
      if (this.$route.path === '/products') {
        this.$router.push({
          path: '/products',
          query: {
            query: this.skuNumber
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mt-app-header {
  &__container {
    @apply py-6 px-16 lg:px-5;
  }
}
</style>
