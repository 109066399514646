<template>
  <div v-if="path && path.length" class="breadcrumbs-control">
    <div class="breadcrumbs-container">
      <ul class="breadcrumbs">
        <li v-if="displayHome">
          <router-link :to="rootRoute.path">{{ homeTitle }}</router-link>
        </li>
        <li v-for="(item, n) in path" :key="n">
          <router-link
            v-if="item.link && item.link.length > 0 && n < path.length - 1"
            :to="item.link"
            >{{ item.name }}</router-link
          >
          <span v-else>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import breadcrumbsPath from '@/components/common/mixins/breadcrumbs-path'

export default {
  name: 'Breadcrumbs',
  mixins: [breadcrumbsPath],
  props: {
    displayHome: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      path: []
    }
  }
}
</script>
