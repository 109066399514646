const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/components/b2b/dashboard/Dashboard'
  )
const Product = () =>
  import(/* webpackChunkName: "products" */ '@/components/b2b/product/Product')
const AllProducts = () =>
  import(
    /* webpackChunkName: "products" */ '@/components/b2b/catalog/AllProducts'
  )
const Quotes = () =>
  import(/* webpackChunkName: "quotes" */ '@/components/b2b/quotes/Quotes')
const QuoteFormWrapper = () =>
  import(
    /* webpackChunkName: "quotes" */ '@/components/b2b/quotes/QuoteFormWrapper'
  )
const ReturnForm = () =>
  import(/* webpackChunkName: "quotes" */ '@/components/b2b/return/ReturnForm')
const NoChargeForm = () =>
  import(
    /* webpackChunkName: "quotes" */ '@/components/b2b/no-charge/NoChargeForm'
  )
const CreditMemoForm = () =>
  import(
    /* webpackChunkName: "quotes" */ '@/components/b2b/credit-memo/CreditMemoForm'
  )
const Accounts = () => import('@/components/b2b/user/Accounts')
const UploadOrders = () => import('@/components/b2b/upload-orders/UploadOrders')
const AccountAddresses = () =>
  import(
    /* webpackChunkName: "account" */ '@/components/b2b/user/AccountAddresses'
  )
const AccountSettings = () =>
  import(
    /* webpackChunkName: "account" */ '@/components/b2b/user/AccountSettings'
  )
const QuoteReview = () =>
  import(/* webpackChunkName: "quotes" */ '@/components/b2b/quotes/QuoteReview')
const QuoteReviewConfirmation = () =>
  import(
    /* webpackChunkName: "quotes" */ '@/components/b2b/quotes/QuoteReviewConfirmation'
  )
const CustomerSelection = () =>
  import(
    /* webpackChunkName: "quotes" */ '@/components/b2b/customer-selection/CustomerSelection'
  )
const Login = () =>
  import(/* webpackChunkName: "login" */ '@/components/user/Login')
const LoginError = () =>
  import(/* webpackChunkName: "login-error" */ '@/components/user/LoginError')
const Invoices = () =>
  import(
    /* webpackChunkName: "invoices" */ '@/components/b2b/invoices/Invoices'
  )
const Customers = () =>
  import(
    /* webpackChunkName: "customers" */ '@/components/b2b/customers/Customers'
  )
const Orders = () =>
  import(/* webpackChunkName: "orders" */ '@/components/b2b/orders/Orders')
const Order = () =>
  import(/* webpackChunkName: "order" */ '@/components/b2b/orders/Order')

const ConsentsPage = () =>
  import(/* webpackChunkName: "consents" */ '@/components/user/ConsentsPage')
const NotFound = () =>
  import(/* webpackChunkName: "not-found" */ '@/components/NotFound')
const Redirect = () =>
  import(/* webpackChunkName: "redirect" */ '@/components/Redirect')
const ErrorIntegrity = () =>
  import(
    /* webpackChunkName: "integrity-check-failed" */ '@/components/ErrorIntegrity'
  )
const ErrorApi = () =>
  import(/* webpackChunkName: "error-api" */ '@/components/ErrorApi')

export default [
  {
    path: '/',
    root: true,
    name: 'dashboard',
    pageTitle: 'routesTitles.dashboard',
    component: Dashboard
  },
  {
    hideBreadcrumbs: true,
    path: '/accounts',
    name: 'accounts',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.accounts',
    component: Accounts,
    meta: {
      pageRestriction: 'isAccountsPage'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/upload-orders',
    name: 'uploadOrders',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.uploadOrders',
    component: UploadOrders,
    meta: {
      pageRestriction: 'isAnyUpload'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/account/settings',
    name: 'account-settings',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.accountSettings',
    component: AccountSettings,
    meta: {
      pageRestriction: 'isPersonalInformationPage'
    }
  },
  // addresses logic is for the future sprint, now this logic is not actual
  {
    hideBreadcrumbs: true,
    path: '/addresses',
    name: 'addresses',
    skipIdle: false,
    publicRoute: false,
    meta: {
      featureToggle: 'addresses.enabled'
    },
    pageTitle: 'routesTitles.addresses',
    component: AccountAddresses
  },
  {
    hideBreadcrumbs: false,
    path: '/product/:productId?',
    name: 'product',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.product',
    component: Product,
    meta: {
      pageRestriction: 'isPLPorPDP'
    }
  },
  {
    hideBreadcrumbs: false,
    path: '/products/:categoryId?',
    name: 'products',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.products',
    component: AllProducts,
    meta: {
      pageRestriction: 'isPLPorPDP'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/carts',
    name: 'carts',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.carts',
    component: Quotes,
    meta: {
      pageRestriction: 'isCartsAndCartDetailsPages'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/quote-form/:cartId?',
    name: 'quote-form',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.quoteForm',
    component: QuoteFormWrapper,
    meta: {
      pageRestriction: 'isCartsAndCartDetailsPages'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/return-form/:cartId?',
    name: 'return-form',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.returnForm',
    component: ReturnForm,
    meta: {
      pageRestriction: 'isCartsAndCartDetailsPages'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/no-charge-form/:cartId?',
    name: 'no-charge-form',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.noChargeForm',
    component: NoChargeForm,
    meta: {
      pageRestriction: 'isCartsAndCartDetailsPages'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/credit-memo-form/:cartId?',
    name: 'credit-memo-form',
    skipIdle: false,
    publicRoute: false,
    pageTitle: 'routesTitles.creditMemoForm',
    component: CreditMemoForm,
    meta: {
      pageRestriction: 'isCartsAndCartDetailsPages'
    }
  },
  {
    hideBreadcrumbs: true,
    path: '/quote-review',
    name: 'quote-review',
    skipIdle: false,
    publicRoute: true,
    simpleView: true,
    pageTitle: 'routesTitles.quoteReview',
    component: QuoteReview
  },
  {
    hideBreadcrumbs: true,
    path: '/quote-review/:status',
    name: 'quote-review-confirmation',
    props: true,
    skipIdle: false,
    publicRoute: true,
    simpleView: true,
    pageTitle: 'routesTitles.quoteReview',
    component: QuoteReviewConfirmation
  },
  {
    hideBreadcrumbs: true,
    path: '/customer-selection',
    name: 'customer-selection',
    props: true,
    skipIdle: true,
    publicRoute: false,
    pageTitle: 'routesTitles.customerSelection',
    component: CustomerSelection
  },
  {
    hideBreadcrumbs: true,
    path: '/invoices',
    name: 'invoices',
    pageTitle: 'routesTitles.invoices',
    component: Invoices,
    meta: {
      pageRestriction: 'isInvoicesPage'
    }
  },
  {
    path: '/customers',
    name: 'customers',
    hideBreadcrumbs: true,
    pageTitle: 'routesTitles.customers',
    component: Customers,
    meta: {
      pageRestriction: 'isAccountsPage'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    hideBreadcrumbs: true,
    pageTitle: 'routesTitles.orders',
    component: Orders,
    meta: {
      pageRestriction: 'isOrdersAndOrderDetailsPages'
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    hideBreadcrumbs: true,
    pageTitle: 'routesTitles.order',
    parent: 'orders',
    component: Order,
    meta: {
      pageRestriction: 'isOrdersAndOrderDetailsPages'
    }
  },
  {
    path: '/login',
    name: 'login',
    publicRoute: true,
    skipIdle: true,
    pageTitle: 'routesTitles.login',
    component: Login
  },
  {
    hideBreadcrumbs: true,
    path: '/login/error',
    name: 'login-fail',
    skipIdle: true,
    publicRoute: true,
    simpleView: true,
    pageTitle: 'routesTitles.loginError',
    component: LoginError
  },
  {
    hideBreadcrumbs: true,
    simpleView: true,
    path: '/consents',
    name: 'consents',
    pageTitle: 'routesTitles.consents',
    component: ConsentsPage
  },
  {
    path: '/redirect',
    name: 'redirect',
    publicRoute: true,
    skipIdle: true,
    component: Redirect
  },
  {
    path: '/integrity-check-failed',
    name: 'integrity-check-failed',
    publicRoute: true,
    fatalError: true,
    skipIdle: true,
    component: ErrorIntegrity
  },
  {
    path: '/404',
    name: 'not-found',
    publicRoute: true,
    skipIdle: true,
    simpleView: true,
    pageTitle: 'routesTitles.notFound',
    component: NotFound
  },
  {
    path: '/error/api',
    name: 'error-api',
    publicRoute: true,
    fatalError: true,
    skipIdle: true,
    pageTitle: 'routesTitles.errorApi',
    component: ErrorApi
  },
  {
    path: '*',
    redirect: '/404'
  }
]
