import { uniqueId } from 'lodash'

import { QuoteStatuses } from '@/lib/models/quotes/quoteStatuses'

import { rolesStore } from '@/stores/roles'

export const flattenObjectProperties = (object) => {
  const properties = []

  for (const prop in object) {
    if (!Object.hasOwn(object, prop)) continue

    if (typeof object[prop] === 'object' && object[prop] !== null) {
      const flatObject = flattenObjectProperties(object[prop])
      for (const nestedProp of flatObject) {
        properties.push(prop + '.' + nestedProp)
      }
    } else {
      properties.push(prop)
    }
  }
  return properties
}

export function getCartTypeFromResponse(errResponseData) {
  return errResponseData?.header?.type
}

export function getCartDisabledStatusByCartStatus(cartStatus) {
  switch (cartStatus) {
    case QuoteStatuses.REVIEW:
    case QuoteStatuses.SUBMITTED:
    case QuoteStatuses.COMPLETED:
    case QuoteStatuses.EXPIRED:
      return true
    case QuoteStatuses.ORDER_ON_HOLD:
      return rolesStore().distributor
    default:
      return false
  }
}

export const getUniqueId = () => {
  return uniqueId(Math.round(new Date().getTime() / 1000))
}

export const onTableScroll = (
  { target: { scrollTop, clientHeight, scrollHeight } },
  cb
) => {
  if (scrollTop + clientHeight >= scrollHeight) cb()
}
