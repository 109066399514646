import { US_REGION, MEXICO_REGION } from '@/lib/models/user/met-region'

export const ROLES = {
  [US_REGION]: {
    salesRep: 'salesrepgroup',
    salesManager: 'salesmanagergroup',
    cxRep: 'cxrepgroup',
    cxManager: 'cxsupervisorgroup',
    itAdmin: 'cxcommgsupportadmingroup',
    pricingSuperUser: 'cxcommgpricingsuperusergroup',
    channelMarketing: 'channelmarketinggroup',
    logistic: 'logisticsgroup',
    salesDirector: 'dirofsalesgroup',
    financeDirector: 'financedirectorgroup',
    arCoordinator: 'arcoordinatorgroup',
    distributor: 'distributorgroup',
    superuser: 'superusergroup'
  },
  [MEXICO_REGION]: {
    salesRep: 'mx_salesrepgroup',
    salesManager: 'mx_salesmanagergroup',
    cxRep: 'mx_cxrepgroup',
    cxManager: 'mx_cxsupervisorgroup',
    itAdmin: 'mx_cxcommgsupportadmingroup',
    pricingSuperUser: 'mx_cxcommgpricingsuperusergroup',
    channelMarketing: 'mx_channelmarketinggroup',
    logistic: 'mx_logisticsgroup',
    salesDirector: 'mx_dirofsalesgroup',
    financeDirector: 'mx_financedirectorgroup',
    arCoordinator: 'mx_arcoordinatorgroup',
    distributor: 'mx_distributorgroup',
    superuser: 'mx_superusergroup'
  }
}

export const DEFAULT_STATE = {
  salesRep: false,
  salesManager: false,
  cxRep: false,
  cxManager: false,
  itAdmin: false,
  pricingSuperUser: false,
  channelMarketing: false,
  logistic: false,
  salesDirector: false,
  financeDirector: false,
  arCoordinator: false,
  distributor: false,
  superuser: false
}
