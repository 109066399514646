import { getUniqueId } from '@/lib/utils/helpers'

export class QuoteProduct {
  constructor(product) {
    this.itemNumber = product.itemNumber
    this.quantity = product.quantity
    this.pceCode = product.pceCode || ''
    this.unitPrice = product.unitPrice
    this.standardValue = product.standardValue
    this.netPrice = product.netPrice
    this.imapRetail = product.imapRetail
    this.totalRetailPrice = product.totalRetailPrice
    this.ManualFreeGood = product.ManualFreeGood
    this.AutoFreeGood = product.AutoFreeGood
    this.ledgerAcctStdValue = product.ledgerAcctStdValue
    this.ledgerAcctNetPrice = product.ledgerAcctNetPrice
    this.statementLine = product.statementLine
    this.lineRecId = product.lineRecId ? product.lineRecId : getUniqueId()
    this.priceOverridden = product.priceOverridden
    this.originalImapRetail = product.originalImapRetail
    this.NetZeroFlag = product.NetZeroFlag
    this.totalDiscount = product.totalDiscount
    this.hierarchy = product.hierarchy
    this.entryNumber = product.entryNumber
    this.promotions = product.promotions
    this.aceFreight = product.aceFreight
    this.errorCode = product.errorCode
    this.errorMessage = product.errorMessage
    this.overrideBy = product.overrideBy
    this.highestQtyAllowed = product.highestQtyAllowed
    this.lowestQtyAllowed = product.lowestQtyAllowed
    this.multipleQty = product.multipleQty
    this.totalPrice = product.totalPrice
    this.stockStatus = product.stockStatus
    this.searchName = product.searchName || ''
    this.identifier = product.identifier || ''
    this.isSelected = false
    this.replacementItemNumber = product.replacementItemNumber
  }

  getRequestPayload() {
    return {
      hierarchy: this.hierarchy,
      itemNumber: this.itemNumber,
      quantity: this.quantity,
      pceCode: this.pceCode,
      netPrice: this.netPrice,
      ManualFreeGood: this.ManualFreeGood,
      replacementItemNumber: this.replacementItemNumber,
      lineRecId: this.lineRecId || getUniqueId()
    }
  }
}
