import { set } from 'lodash'
import { defineStore } from 'pinia'
import Vue from 'vue'

import api from '@/lib/api'
import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { CreditMemo } from '@/lib/models/credit-memo/creditMemo'
import { CreditMemoProduct } from '@/lib/models/credit-memo/creditMemoProduct'
import { NoCharge } from '@/lib/models/no-charge/noCharge'
import { NoChargeProduct } from '@/lib/models/no-charge/noChargeProduct'
import { Quote } from '@/lib/models/quotes/quote'
import { QuoteProduct } from '@/lib/models/quotes/quoteProduct'
import { QuoteStatuses } from '@/lib/models/quotes/quoteStatuses'
import { Return } from '@/lib/models/return/return'
import { ReturnProduct } from '@/lib/models/return/returnProduct'
import { DEFAULT_DEPENDENT_DETAILS_FIELDS } from '@/lib/utils/defaultDependentCartDetailsFields'
import errorTypes from '@/lib/utils/errorTypes'
import {
  getCartTypeFromResponse,
  getCartDisabledStatusByCartStatus
} from '@/lib/utils/helpers'

import { currentCartStore } from '@/stores/currentCart'
import { userStore } from '@/stores/user'

const state = {
  products: [],
  currentQuotes: [],
  saleOrdersQuotes: [],
  currentQuote: null,
  currentReturn: null,
  currentNoCharge: null,
  currentCreditMemo: null,
  currentQuoteSaved: false,
  currentReturnSaved: false,
  currentNoChargeSaved: false,
  currentCreditMemoSaved: false,
  currentAccount: null,
  currentShippingAddress: null,
  currentDropshipAddress: null,
  isReasonCodeModalShown: false,
  paginationData: {},
  currentWorkflow: {},
  newProductReset: false,
  isReplace: false,
  typeCodes: [],
  detailsFormFields: {
    paymentOrderFields: [],
    deliveryReasons: [],
    ...DEFAULT_DEPENDENT_DETAILS_FIELDS
  }
}

const mutations = {
  UPDATE_PRODUCT_QTY({ product, value }) {
    product.quantity = value
  },
  UPDATE_PRODUCT_PROMO({ product, value }) {
    product.pceCode = value
  },
  UPDATE_PRODUCT_FREE_GOOD({ product, value }) {
    product.ManualFreeGood = value
  },
  UPDATE_PRODUCT_PRICE({ product, value }) {
    product.netPrice.value = value
  },
  TOGGLE_PRODUCT({ product, value }) {
    product.isSelected = value
  },
  TOGGLE_ALL_PRODUCTS({ value, cartType }) {
    this[cartType].lines.forEach((product) => {
      product.isSelected = value
    })
  },
  CLEAR_NEW_PRODUCT() {
    this.newProductReset = true
  },
  RESET_NEW_PRODUCT() {
    this.newProductReset = false
  },
  SET_REPLACE(value) {
    this.isReplace = value
  },
  SET_QUOTES(quotes) {
    this.currentQuotes = quotes
  },
  SET_SALE_ORDERS_QUOTES(quotes) {
    this.saleOrdersQuotes = quotes
  },
  ADD_SALE_ORDERS_QUOTES(quotes) {
    this.saleOrdersQuotes = this.saleOrdersQuotes.concat(quotes)
  },
  UPDATE_QUOTE_FIELD(payload) {
    const quoteIndex = this.currentQuotes.findIndex(
      (quote) => quote.id === this.currentQuote.id
    )

    // if (quoteIndex > 0) { index should be > -1
    this.currentQuote = set(this.currentQuote, payload.path, payload.value)
    this.currentQuotes[quoteIndex] = this.currentQuote
    // }
  },
  UPDATE_RETURN_FIELD(payload) {
    const returnIndex = this.currentQuotes.findIndex(
      (quote) => quote.id === this.currentReturn.id
    )

    this.currentReturn = set(this.currentReturn, payload.path, payload.value)
    this.currentQuotes[returnIndex] = this.currentReturn
  },
  UPDATE_NO_CHARGE_FIELD(payload) {
    const returnIndex = this.currentQuotes.findIndex(
      (quote) => quote.id === this.currentNoCharge.id
    )

    this.currentNoCharge = set(
      this.currentNoCharge,
      payload.path,
      payload.value
    )
    this.currentQuotes[returnIndex] = this.currentNoCharge
  },
  UPDATE_CREDIT_MEMO_FIELD(payload) {
    const returnIndex = this.currentQuotes.findIndex(
      (quote) => quote.id === this.currentCreditMemo.id
    )

    this.currentCreditMemo = set(
      this.currentCreditMemo,
      payload.path,
      payload.value
    )
    this.currentQuotes[returnIndex] = this.currentCreditMemo
  },
  UPDATE_CURRENT_ACCOUNT(account) {
    this.currentAccount = account
  }
}

const actions = {
  transformQuoteProduct({ quoteProduct }) {
    const product = new QuoteProduct(quoteProduct)
    return product
  },
  transformReturnProduct({ returnProduct }) {
    const product = new ReturnProduct(returnProduct)
    return product
  },
  transformReturnProducts({ returnProducts }) {
    return returnProducts?.map((product) =>
      this.transformReturnProduct({
        returnProduct: product
      })
    )
  },
  transformNoChargeProduct({ noChargeProduct }) {
    const product = new NoChargeProduct(noChargeProduct)
    return product
  },
  transformNoChargeProducts({ noChargeProducts }) {
    return noChargeProducts?.map((product) =>
      this.transformNoChargeProduct({
        noChargeProduct: product
      })
    )
  },
  transformCreditMemoProduct({ creditMemoProduct }) {
    const product = new CreditMemoProduct(creditMemoProduct)
    return product
  },
  transformCreditMemoProducts({ creditMemoProducts }) {
    return creditMemoProducts?.map((product) =>
      this.transformCreditMemoProduct({
        creditMemoProduct: product
      })
    )
  },
  async handleQuotesRequest({ callBack, params }) {
    try {
      const res = await api.quotes[callBack](params)
      const transformedQuotes = this.transformQuotes({
        quotes: res.data.orders
      })
      this.SET_QUOTES(transformedQuotes)
      this.paginationData = res.data.pagination
      return res.data
    } catch (error) {
      this.SET_QUOTES([])
      this.paginationData = {}
      throw error
    }
  },
  transformQuote({ quote }) {
    const products = quote.lines?.map((product, index) =>
      this.transformQuoteProduct({
        quoteProduct: {
          ...product,
          lineNo: index
        }
      })
    )
    const updatedQuote = { ...quote, ...{ lines: products } }
    return new Quote(updatedQuote)
  },
  transformReturn({ returnCart }) {
    const products = this.transformReturnProducts({
      returnProducts: returnCart.lines
    })
    const updatedReturn = { ...returnCart, ...{ lines: products } }
    return new Return(updatedReturn)
  },
  transformNoCharge({ noChargeCart }) {
    const products = this.transformNoChargeProducts({
      noChargeProducts: noChargeCart.lines
    })
    const updatedNoCharge = { ...noChargeCart, ...{ lines: products } }
    return new NoCharge(updatedNoCharge)
  },
  transformCreditMemo({ creditMemoCart }) {
    const products = this.transformCreditMemoProducts({
      creditMemoProducts: creditMemoCart.lines
    })
    const updatedCreditMemo = { ...creditMemoCart, ...{ lines: products } }
    return new CreditMemo(updatedCreditMemo)
  },
  transformQuotes({ quotes }) {
    return quotes.map((cart) => {
      switch (cart.header.type) {
        case CART_TYPES.QUOTE.type:
          return this.transformQuote({ quote: cart })
        case CART_TYPES.RETURN.type:
          return this.transformReturn({ returnCart: cart })
        case CART_TYPES.NO_CHARGE.type:
          return this.transformNoCharge({ noChargeCart: cart })
        case CART_TYPES.CREDIT_MEMO.type:
          return this.transformCreditMemo({
            creditMemoCart: cart
          })
        default:
          return cart
      }
    })
  },
  getQuotes({
    searchData,
    asc = false,
    code = 'code',
    currentPage = 0,
    pageSize = 10,
    fields = 'DEFAULT',
    type = null
  }) {
    return this.handleQuotesRequest({
      callBack: 'getQuotes',
      params: { type, asc, code, currentPage, pageSize, fields, ...searchData }
    })
  },
  getFilteredQuotes({
    asc = false,
    code = 'code',
    customerStatus,
    filter,
    customerAccount,
    currentPage,
    pageSize,
    type = CART_TYPES.QUOTE.type
  }) {
    return this.handleQuotesRequest({
      callBack: 'getQuotes',
      params: {
        asc,
        code,
        filter,
        customerAccount,
        currentPage,
        pageSize,
        fields: 'DEFAULT',
        customerStatus,
        type
      }
    })
  },
  searchQuotes({
    term,
    currentPage = 0,
    pageSize = 10,
    filter,
    customerAccount,
    applyingSearch = false,
    asc = false,
    code = 'code',
    type = null,
    customerStatus
  }) {
    if (applyingSearch) {
      return this.handleQuotesRequest({
        callBack: 'getQuotes',
        params: {
          term,
          currentPage,
          pageSize,
          filter,
          customerAccount,
          asc,
          code,
          type,
          customerStatus
        }
      })
    } else {
      return api.quotes
        .getQuotes({
          term,
          currentPage,
          pageSize,
          filter,
          customerAccount,
          asc,
          code,
          type,
          customerStatus
        })
        .then(async (res) => {
          return res.data
        })
    }
  },
  getCartsSaleOrders({
    asc = false,
    code = 'code',
    tab,
    currentPage,
    pageSize,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    isAdditionalLoad = false,
    type
  }) {
    return api.quotes
      .getCartsSaleOrders({
        asc,
        code,
        tab,
        currentPage,
        pageSize,
        customerAccount,
        employee,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode,
        type
      })
      .then(async (res) => {
        if (isAdditionalLoad) {
          this.ADD_SALE_ORDERS_QUOTES(res.data.orders)
        } else {
          this.SET_SALE_ORDERS_QUOTES(res.data.orders ? res.data.orders : [])
        }
        return res.data
      })
  },
  getWorkflowApproval({ cartCode, shortList = false }) {
    return api.quotes
      .getWorkflowApproval({ cartCode, shortList })
      .then(async (res) => {
        this.currentWorkflow = { ...res.data }
        return res.data
      })
  },
  submitWorkflowAction(data) {
    return api.quotes.submitWorkflowAction(data).then((res) => {
      return res.data
    })
  },
  getCartsQtyByStatus({
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  }) {
    return api.quotes
      .getCartsQtyByStatus(
        customerAccount,
        employee,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode,
        type
      )
      .then((res) => {
        return res.data
      })
  },

  getFailedQuotesQtyByStatus({
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  }) {
    return api.quotes
      .getFailedQuotesQtyByStatus(
        customerAccount,
        employee,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode,
        type
      )
      .then((res) => {
        return res.data
      })
  },
  createQuote() {
    return api.quotes.createCart(CART_TYPES.QUOTE.type).then((res) => {
      this.currentQuote = new Quote(res.data)
      return res.data.header.cartCode
    })
  },
  createReturn() {
    return api.quotes.createCart(CART_TYPES.RETURN.type).then((res) => {
      return res.data.header.cartCode
    })
  },
  createNoCharge() {
    return api.quotes.createCart(CART_TYPES.NO_CHARGE.type).then((res) => {
      return res.data.header.cartCode
    })
  },
  createCreditMemo() {
    return api.quotes.createCart(CART_TYPES.CREDIT_MEMO.type).then((res) => {
      return res.data.header.cartCode
    })
  },
  deleteCart(cartId) {
    return api.quotes.deleteCart(cartId)
  },
  async addMultipleProductsToMultipleQuotes(quotes) {
    try {
      await api.quotes.addMultipleProductsToMultipleQuotes(quotes)
    } catch (e) {
      throw e.response.data
    }
  },
  async addProductsList({ product, cartId }) {
    try {
      const lines = product.map((item) => {
        return item.getRequestPayload()
      })

      const { data } = await api.quotes.addListToQuote({
        cartId: cartId,
        lines,
        cartType: currentCartStore().currentCartType,
        businessUnit: userStore().businessUnit
      })
      this.updateSpecificCart({
        type: data.header.type,
        cartData: data
      })
      return data
    } catch (err) {
      const { data, status } = err.response
      if (errorTypes.isBadRequest(status) && getCartTypeFromResponse(data)) {
        this.updateSpecificCart({
          type: data.header.type,
          cartData: data
        })
        return data
      } else {
        throw data
      }
    }
  },
  updateQuoteStatus(payload) {
    return api.quotes.updateQuoteStatus(payload)
  },
  saveQuoteChanges() {
    const {
      lines,
      summary,
      id,
      company,
      warehouses,
      sites,
      autoSave,
      conversations,
      attachments,
      validationErrors,
      paymentAddress,
      deliveryAddress,
      isDisabledProductSuggest,
      isDisabledQtyInput,
      isDisabledPromoCell,
      isDisabledFreeGoodCell,
      cartType,
      isDisabledByStatus,
      ...payload
    } = this.currentQuote
    return api.quotes
      .saveQuote(payload)
      .then((res) => {
        const quote = this.transformQuote({
          quote: res.data
        })
        this.currentQuoteSaved = true
        this.currentQuote = new Quote(quote)
        return res
      })
      .catch((err) => {
        if (errorTypes.isBadRequest(err.response.status)) {
          const quote = this.transformQuote({
            quote: err.response.data
          })
          this.currentQuoteSaved = true
          this.currentQuote = new Quote(quote)
          return err.response
        }
      })
  },
  saveReturnChanges() {
    const {
      lines,
      summary,
      id,
      autoSave,
      validationErrors,
      deliveryAddress,
      paymentAddress,
      ...payload
    } = this.currentReturn

    const businessUnit = userStore().businessUnit

    return api.quotes
      .saveQuote(payload, businessUnit)
      .catch((err) => {
        if (errorTypes.isBadRequest(err.response.status)) {
          const returnCart = this.transformReturn({
            returnCart: err.response.data
          })
          this.currentReturnSaved = true
          this.currentReturn = new Return(returnCart)
          return err.response
        }
      })
      .then((res) => {
        const returnCart = this.transformReturn({
          returnCart: res.data
        })
        this.currentReturnSaved = true
        this.currentReturn = new Return(returnCart)
        return res
      })
  },
  saveNoChargeChanges() {
    const {
      lines,
      summary,
      id,
      autoSave,
      validationErrors,
      deliveryAddress,
      paymentAddress,
      ...payload
    } = this.currentNoCharge
    return api.quotes
      .saveQuote(payload)
      .catch((err) => {
        if (errorTypes.isBadRequest(err.response.status)) {
          const noChargeCart = this.transformNoCharge({
            noChargeCart: err.response.data
          })

          this.currentNoChargeSaved = true
          this.currentNoCharge = new NoCharge(noChargeCart)
          return err.response
        }
      })
      .then((res) => {
        const noChargeCart = this.transformNoCharge({
          noChargeCart: res.data
        })

        this.currentNoChargeSaved = true
        this.currentNoCharge = new NoCharge(noChargeCart)
        return res
      })
  },
  saveCreditMemoChanges() {
    const { lines, summary, id, autoSave, validationErrors, ...payload } =
      this.currentCreditMemo
    return api.quotes
      .saveQuote(payload)
      .catch((err) => {
        if (errorTypes.isBadRequest(err.response.status)) {
          const creditMemoCart = this.transformCreditMemo({
            creditMemoCart: err.response.data
          })

          this.currentCreditMemoSaved = true
          this.currentCreditMemo = new CreditMemo(creditMemoCart)
          return err.response
        }
      })
      .then((res) => {
        const creditMemoCart = this.transformCreditMemo({
          creditMemoCart: res.data
        })

        this.currentCreditMemoSaved = true
        this.currentCreditMemo = new CreditMemo(creditMemoCart)
        return res
      })
  },
  updateQuoteField(payload) {
    this.UPDATE_QUOTE_FIELD(payload)
    this.currentQuoteSaved = false
  },

  updateReturnField(payload) {
    this.UPDATE_RETURN_FIELD(payload)
    this.currentReturnSaved = false
  },

  updateNoChargeField(payload) {
    this.UPDATE_NO_CHARGE_FIELD(payload)
    this.currentNoChargeSaved = false
  },

  updateCreditMemoField(payload) {
    this.UPDATE_CREDIT_MEMO_FIELD(payload)
    this.currentCreditMemoSaved = false
  },

  setNoteQuoteField(payload) {
    Vue.set(this.currentQuote.other.notes[payload.index], 'note', payload.note)
  },
  setNoteReturnField(payload) {
    Vue.set(this.currentReturn.other.notes[payload.index], 'note', payload.note)
  },
  setNoteNoChargeField(payload) {
    Vue.set(
      this.currentNoCharge.noChargeManagement.notes[payload.index],
      'note',
      payload.note
    )
  },
  setNoteCreditMemoField(payload) {
    Vue.set(
      this.currentCreditMemo.creditMemoManagement.notes[payload.index],
      'note',
      payload.note
    )
  },

  updateQuoteFields(payload) {
    payload.path.forEach((path) => {
      this.updateQuoteField({
        path,
        value: payload.value
      })
    })
  },

  updateNoChargeFields(payload) {
    payload.path.forEach((path) => {
      this.updateNoChargeField({
        path,
        value: payload.value
      })
    })
  },

  updateQuoteHeader(payload) {
    this.currentQuote.header = payload
    return this.saveQuoteChanges()
  },
  clearErrorInHeader() {
    if (
      this.currentQuote.header.errorMessage &&
      this.currentQuote.header.errorCode
    ) {
      this.currentQuote.header.errorMessage = ''
      this.currentQuote.header.errorCode = ''
    }
  },
  async quoteFieldChanged() {
    await this.clearErrorInHeader()
    return this.saveQuoteChanges()
  },
  returnFieldChanged() {
    return this.saveReturnChanges()
  },
  noChargeFieldChanged() {
    return this.saveNoChargeChanges()
  },
  creditMemoFieldChanged() {
    return this.saveCreditMemoChanges()
  },
  getCart({ cartId = currentCartStore().currentCart.id }) {
    const businessUnit = userStore().businessUnit

    return api.quotes
      .getQuote(cartId, businessUnit)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        if (errorTypes.isBadRequest(err.response.status)) {
          return err.response.data
        } else {
          throw new Error(err)
        }
      })
      .then(async (data) => {
        const cart = this.updateSpecificCart({
          type: data.header.type,
          cartData: data
        })
        return cart
      })
  },
  updateSpecificCart({ type, cartData }) {
    switch (type) {
      case CART_TYPES.QUOTE.type: {
        const quote = this.transformQuote({
          quote: cartData
        })
        this.currentQuote = new Quote(quote)
        return quote
      }
      case CART_TYPES.RETURN.type: {
        const returnCart = this.transformReturn({
          returnCart: cartData
        })
        this.currentReturn = new Return(returnCart)
        return returnCart
      }
      case CART_TYPES.NO_CHARGE.type: {
        const noChargeCart = this.transformNoCharge({
          noChargeCart: cartData
        })
        this.currentNoCharge = new NoCharge(noChargeCart)
        return noChargeCart
      }
      case CART_TYPES.CREDIT_MEMO.type: {
        const creditMemoCart = this.transformCreditMemo({
          creditMemoCart: cartData
        })
        this.currentCreditMemo = new CreditMemo(creditMemoCart)
        return creditMemoCart
      }
    }
  },
  async editCartProduct({
    productData,
    updatePrice = false,
    updatePce = false
  }) {
    const cartId = currentCartStore().currentCart.id
    const { entryNumber, quantity, ManualFreeGood, pceCode, netPrice } =
      productData

    const payload = {
      cartId,
      entryNumber,
      quantity,
      ManualFreeGood,
      pceCode,
      cartType: currentCartStore().currentCartType,
      businessUnit: userStore().businessUnit
    }

    // only send netPrice when it has been updated
    if (updatePrice) {
      payload.netPrice = netPrice
    }

    if (updatePce) {
      payload.pceCode = pceCode
    }

    return await api.quotes
      .editCartEntry(payload)
      .then(async (res) => {
        await this.updateSpecificCart({
          type: res.data.header.type,
          cartData: res.data
        })

        return res.data
      })
      .catch(async (err) => {
        if (
          errorTypes.isBadRequest(err.response.status) &&
          getCartTypeFromResponse(err.response.data)
        ) {
          await this.updateSpecificCart({
            type: err.response.data.header.type,
            cartData: err.response.data
          })
          return err.response.data
        } else {
          throw err.response.data
        }
      })
  },

  async removeProduct({ entryNumber, cartId }) {
    return await api.quotes
      .removeCartEntry({ cartId, entryNumber })
      .then(async ({ data }) => {
        await this.updateSpecificCart({
          type: data.header.type,
          cartData: data
        })
        return data
      })
      .catch(async (error) => {
        if (error.response?.status === 400) {
          await this.updateSpecificCart({
            type: error.response.data.header.type,
            cartData: error.response.data
          })
          return error.response
        }
        throw error
      })
  },
  async deleteSelectedProducts({ lines, cartId }) {
    return api.quotes
      .removeMultipleCartEntries(cartId, lines)
      .then(async (response) => {
        await this.updateSpecificCart({
          type: response.data.header.type,
          cartData: response.data
        })
        return response
      })
      .catch(async (error) => {
        if (error.response?.status === 400) {
          await this.updateSpecificCart({
            type: error.response.data.header.type,
            cartData: error.response.data
          })
          return error.response
        }
        throw error
      })
  },
  async submitOrder(quoteId) {
    try {
      return await api.quotes.submitOrder({
        quoteId,
        businessUnit: userStore().businessUnit,
        cartType: currentCartStore().currentCartType
      })
    } catch (e) {
      if (e?.response?.data?.header?.type) {
        await this.updateSpecificCart({
          type: e.response.data.header.type,
          cartData: e.response.data
        })
      }
      throw e
    }
  },
  sendQuoteToCustomer({ quoteId, emails }) {
    return api.quotes.sendQuoteToCustomer({ quoteId, emails })
  },
  convertQuoteToOrder(cartCode) {
    return api.quotes.convertQuoteToOrder(cartCode).then(({ data }) => {
      const transformQuote = this.transformQuote({
        quote: data
      })
      this.currentQuote = new Quote(transformQuote)
    })
  },
  getAccountInfo({ customerId, cart }) {
    const cartId = cart.header?.cartCode || cart.action?.cartCode
    return api.quotes.getAccountInfo(customerId).then(async (res) => {
      this.UPDATE_CURRENT_ACCOUNT(res.data)
      await this.getDropshipAddress(cartId)
    })
  },
  setShippingAddress(addressData) {
    return api.quotes.setShippingAddress(addressData).then(async () => {
      await this.getCart({ cartId: addressData.cartId })
    })
  },

  setDropshipAddress({ cartId, address }) {
    return api.quotes.setDropshipAddress({ cartId, address }).then(async () => {
      await this.getDropshipAddress(cartId)
      await this.getCart({ cartId })
    })
  },
  getDropshipAddress(quoteId) {
    return api.quotes.getDropshipAddress(quoteId).then((res) => {
      this.currentDropshipAddress = res.data
    })
  },
  async createOrder() {
    return api.orders.createOrder().then((res) => {
      return res.data.header.cartCode
    })
  },
  deleteAttachment({ cartId, attachmentCode }) {
    return api.quotes.deleteAttachment(cartId, attachmentCode).then(() => {
      this.getCart({ cartId })
    })
  },
  downloadAttachment({ cartId, attachmentCode }) {
    return api.quotes.downloadAttachment(cartId, attachmentCode)
  },
  downloadCart({ cartId, fileType }) {
    return api.quotes.downloadCart(cartId, fileType)
  },
  downloadQuotesXlsx(searchData) {
    return api.quotes.downloadQuotesXlsx(searchData)
  },
  showReasonCodeModal() {
    this.isReasonCodeModalShown = true
  },
  hideReasonCodeModal() {
    this.isReasonCodeModalShown = false
  },
  getNonAuthorizedCart(token) {
    return api.quotes.getNonAuthorizedCart(token)
  },
  approveQuote(data) {
    return api.quotes.approveQuote(data)
  },
  rejectQuote(data) {
    return api.quotes.rejectQuote(data)
  },

  callDetailsFormFieldRequest(callback) {
    return async function () {
      return await callback
        .bind(api.quotes)(...arguments)
        .then((res) => res.data)
        .catch(() => [])
    }
  },

  async getSalesOriginIdOrderFields(
    b2bUnitUid = currentCartStore().currentCartAccountId
  ) {
    if (this.detailsFormFields.salesOriginIds) {
      this.detailsFormFields.salesOriginIds =
        await this.callDetailsFormFieldRequest(
          api.quotes.getSalesOriginIdOrderFields
        )(b2bUnitUid)
    }
  },
  async getModeOfDelivery({
    cartCode = currentCartStore().currentCart.id,
    warehouse
  }) {
    if (this.detailsFormFields.deliveryModes) {
      this.detailsFormFields.deliveryModes =
        await this.callDetailsFormFieldRequest(api.quotes.getModeOfDelivery)(
          cartCode,
          warehouse
        )
    }
  },
  async getPaymentOrderFields() {
    if (!this.detailsFormFields.paymentOrderFields.length) {
      this.detailsFormFields.paymentOrderFields =
        await this.callDetailsFormFieldRequest(
          api.quotes.getPaymentOrderFields
        )()
    }
  },
  async getWarehouseId(cartCode = currentCartStore().currentCart.id) {
    if (!this.detailsFormFields.inventoryWarehouseIds.length) {
      this.detailsFormFields.inventoryWarehouseIds =
        await this.callDetailsFormFieldRequest(api.quotes.getWarehouseId)(
          cartCode
        )
    }
  },
  async getDeliveryReasonsFields() {
    if (!this.detailsFormFields.deliveryReasons.length) {
      this.detailsFormFields.deliveryReasons =
        await this.callDetailsFormFieldRequest(
          api.quotes.getDeliveryReasonsFields
        )()
    }
  },
  async getReasonCodes({
    b2bUnitUid = currentCartStore().currentCartAccountId,
    type = currentCartStore().currentCartType
  }) {
    if (!this.detailsFormFields.reasonCodes.length) {
      this.detailsFormFields.reasonCodes =
        await this.callDetailsFormFieldRequest(api.quotes.getReasonCodes)(
          b2bUnitUid,
          type
        )
    }
  },
  async getTypeCodes() {
    if (!this.typeCodes.length) {
      this.typeCodes = await this.callDetailsFormFieldRequest(
        api.quotes.getTypeCodes
      )()
    }
  },
  async getCostCenters(b2bUnitUid = currentCartStore().currentCartAccountId) {
    if (!this.detailsFormFields.costCenters.length) {
      this.detailsFormFields.costCenters =
        await this.callDetailsFormFieldRequest(api.quotes.getCostCenters)(
          b2bUnitUid
        )
    }
  },
  async getReturnLocations(
    b2bUnitUid = currentCartStore().currentCartAccountId
  ) {
    if (!this.detailsFormFields.returnLocations.length) {
      this.detailsFormFields.returnLocations =
        await this.callDetailsFormFieldRequest(api.quotes.getReturnLocations)(
          b2bUnitUid
        )
    }
  },

  duplicatePONumberCheck(orderSearchData) {
    return api.quotes.duplicatePONumberCheck(orderSearchData)
  },
  checkFreightPolicy(quoteId) {
    return api.quotes.checkFreightPolicy(quoteId)
  },
  addImportedQuoteData(quoteData) {
    const quote = this.transformQuote({
      quote: quoteData
    })
    this.currentQuote = new Quote(quote)
  },
  addImportedNoChargeData(noChargeData) {
    const noCharge = this.transformNoCharge({
      noChargeCart: noChargeData
    })
    this.currentNoCharge = new NoCharge(noCharge)
  },
  addImportedReturnData(returnData) {
    const cart = this.transformReturn({
      returnCart: returnData
    })
    this.currentReturn = new Return(cart)
  },
  addImportedCreditMemoData(creditMemoData) {
    const cart = this.transformCreditMemo({
      creditMemoCart: creditMemoData
    })
    this.currentCreditMemo = new CreditMemo(cart)
  },
  updateAttachments({ currentCart, attachments }) {
    this[currentCart].attachments = attachments
  },
  async getSalesReference(payload) {
    const { data } = await api.quotes.getSalesReference(payload)
    return data
  },
  async getReturnSuggestedProducts(payload) {
    const { data } = await api.quotes.getReturnSuggestedProducts(payload)
    return data
  },
  addReturnWithReferenceProducts(products) {
    return api.quotes
      .addListToQuote({
        cartId: this.currentReturn.id,
        lines: products,
        cartType: currentCartStore().currentCartType,
        businessUnit: userStore().businessUnit,
        returnLevelInvoiceId: this.currentReturn.returnManagement.invoiceId
      })
      .then((res) => {
        const { data } = res
        this.updateSpecificCart({
          type: data.header.type,
          cartData: data
        })
        return data
      })
  },
  clearCart() {
    const cartPropName = Object.values(CART_TYPES).find((cart) => {
      return cart.type === currentCartStore().currentCartType
    })?.currentCart
    this[cartPropName] = null
    this.clearDetailsFormFields()
  },
  clearDetailsFormFields() {
    Object.assign(this.detailsFormFields, DEFAULT_DEPENDENT_DETAILS_FIELDS)
  }
}

const getters = {
  currentQuoteProducts: (state) => {
    return state.currentQuote?.lines
  },
  currentQuoteCustomerId: (state) =>
    state.currentQuote?.header?.customerAccount,
  currentQuoteSummary: (state) => state.currentQuote?.summary,
  currentQuoteFutureDate: (state) =>
    state.currentQuote?.orderManagement.priceEffectiveDate,
  currentQuoteCreatedDate: (state) =>
    state.currentQuote?.header?.quoteCreationDate,
  currentQuoteMargin: (state) => state.currentQuote?.marginCalculator,
  currentQuoteValidationErrors: (state) => state.currentQuote?.validationErrors,
  hasCurrentQuoteValidationErrors: (state) =>
    state.currentQuote?.validationErrors &&
    !!Object.keys(state.currentQuote.validationErrors).length,
  hasCurrentQuoteLineItemErrors: (state) =>
    !!state.currentQuote?.lines.find((line) => line.errorCode),
  isOrder: (state) =>
    state.currentQuote?.header?.cartStatus
      ? state.currentQuote?.header?.cartStatus === QuoteStatuses.ORDER_ON_HOLD
      : false,
  isQuoteInReview: (state) =>
    state.currentQuote?.header?.cartStatus
      ? state.currentQuote?.header?.cartStatus === QuoteStatuses.REVIEW
      : false,
  currentReturnValidationErrors: (state) =>
    state.currentReturn?.validationErrors,
  hasCurrentReturnValidationErrors: (state) =>
    state.currentReturn?.validationErrors &&
    !!Object.keys(state.currentReturn.validationErrors).length,
  isReturnInReview: (state) =>
    state.currentReturn?.header?.cartStatus
      ? state.currentReturn?.header?.cartStatus === QuoteStatuses.REVIEW
      : false,
  currentReturnSummary: (state) => state.currentReturn?.summary,
  currentNoChargeValidationErrors: (state) =>
    state.currentNoCharge?.validationErrors,
  hasCurrentNoChargeValidationErrors: (state) =>
    state.currentNoCharge?.validationErrors &&
    !!Object.keys(state.currentNoCharge.validationErrors).length,
  isNoChargeInReview: (state) =>
    state.currentNoCharge?.header?.cartStatus
      ? state.currentNoCharge?.header?.cartStatus === QuoteStatuses.REVIEW
      : false,
  currentNoChargeSummary: (state) => state.currentNoCharge?.summary,
  currentCreditMemoValidationErrors: (state) =>
    state.currentCreditMemo?.validationErrors,
  hasCurrentCreditMemoValidationErrors: (state) =>
    state.currentCreditMemo?.validationErrors &&
    !!Object.keys(state.currentCreditMemo.validationErrors).length,
  isCreditMemoInReview: (state) =>
    state.currentCreditMemo?.header?.cartStatus
      ? state.currentCreditMemo?.header?.cartStatus === QuoteStatuses.REVIEW
      : false,
  currentCreditMemoSummary: (state) => state.currentCreditMemo?.summary,
  isReasonCodeFilled: (state) =>
    !state.isOrder || !!state.currentQuote?.other.reasonCode,
  isCartFormDisabledByStatus: (state) => {
    return getCartDisabledStatusByCartStatus(
      currentCartStore().currentCart?.header.cartStatus
    )
  },
  isCartFormDisabledByAccountStatus:
    (state) =>
    (cartType = currentCartStore().currentCartType) => {
      const cartTypeProperties = Object.values(CART_TYPES).find(
        (cart) => cart.type === cartType
      )
      if (cartTypeProperties && Object.keys(cartTypeProperties).length) {
        const errorCodes = cartTypeProperties?.errorCodes
        const currentCartErrorCode =
          state[cartTypeProperties?.currentCart]?.header.errorCode
        return errorCodes.includes(currentCartErrorCode)
      }
      return false
    }
}

export const quotesStore = defineStore('quotes', {
  persist: true,
  state: () => ({
    ...state
  }),

  getters,

  actions: {
    ...mutations,
    ...actions
  }
})
