<template>
  <div class="logo" @click="redirectToLogin">
    <router-link v-if="isLink" to="/">
      <img class="w-full lg:max-h-10" :src="logoSrc" />
    </router-link>
    <img v-else class="w-full lg:max-h-10" :src="logoSrc" />
  </div>
</template>

<script>
import get from 'lodash/get'

import auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'
import { eventBus } from '@/lib/utils/bus'

export default {
  name: 'Logo',
  props: {
    type: {
      type: String,
      default: 'general',
      validator: (value) => ['general', 'welcome'].indexOf(value) > -1
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    logoSrc() {
      const src = get(settings, `client.${this.type}Logo`, '')
      return src || require('@/assets/images/logotype.svg')
    }
  },
  methods: {
    redirectToLogin() {
      if (!auth.getIsLoggedIn()) {
        eventBus.$emit('auth.logout', { status: 'success' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  @apply mr-3 lg:mr-0 lg:ml-5 md:ml-0;

  a {
    @apply block;
    max-width: 106px;
  }
}
</style>
