import settings from '@/lib/core/settings'

const supportedModels = ['b2b']
if (!supportedModels.includes(settings.model)) {
  throw new Error(`Model "${settings.model}" is not supported.`)
}

let App = null

if (settings.model === 'b2b') {
  require('@/components/b2b/index')
  App = require('@/components/b2b/App').default
}

export default App
