import disableBodyScroll from '@/lib/utils/disableBodyScroll'

// @vue/component
export const InputProductSelectKeystrokesMixin = {
  data() {
    return {
      tabIndexTracker: 0
    }
  },
  beforeDestroy() {
    this.handleRemoveListener()
  },
  methods: {
    handleInputDownPress(e) {
      if (this.suggestionsVisible && this.isSuggestions) {
        const el = document.querySelector(
          '.mt-input-product-suggest-list--active.mt-input-suggestions'
        )
        el.addEventListener('keydown', this.handleListKeyPress)
        disableBodyScroll(true)
        el.querySelector('.mt-input-suggestions__item').focus()
        this.tabIndexTracker = 0
      }
      this.$emit('handle-input-down-press')
    },
    handleListKeyPress(e) {
      const listLength = document.querySelectorAll(
        '.mt-input-suggestions .mt-input-suggestions__item'
      ).length
      // Up Key
      if (e.key === 'ArrowUp') {
        if (this.tabIndexTracker <= 0) {
          this.tabIndexTracker = listLength
        } else {
          this.tabIndexTracker -= 1
          document
            .querySelectorAll(
              '.mt-input-suggestions .mt-input-suggestions__item'
            )
            [this.tabIndexTracker].focus()
        }
      }
      // Down Key
      if (e.key === 'ArrowDown') {
        if (this.tabIndexTracker >= listLength - 1) {
          this.tabIndexTracker = -1
        } else {
          this.tabIndexTracker += 1
          document
            .querySelectorAll(
              '.mt-input-suggestions .mt-input-suggestions__item'
            )
            [this.tabIndexTracker].focus()
        }
      }
      // Escape Key
      if (e.key === 'Escape') {
        this.exitListTabbing()
      }
    },
    exitListTabbing() {
      this.tabIndexTracker = 0
      document.querySelector('.input-product-suggest input').focus()
      this.handleRemoveListener()
    },
    handleRemoveListener() {
      const el = document.querySelector('.mt-input-suggestions')
      if (el) {
        el.removeEventListener('keydown', this.handleListKeyPress)
      }
    }
  }
}
