import { i18n } from '@root/i18n'
import { defineStore } from 'pinia'
import Vue from 'vue'

import api from '@/lib/api'
import { OrderHistoryEntry } from '@/lib/models/orders/orderHistoryEntry'

const state = {
  uploadOrders: [],
  paginationData: {},
  pendingUploads: []
}
const mutations = {
  SET_UPLOAD_ORDER_HISTORY(entries) {
    this.uploadOrders = entries.map((entry) => new OrderHistoryEntry(entry))
  },
  ADD_NEW_UPLOAD_TO_PENDING(fileUid) {
    this.pendingUploads.push(fileUid)
  },
  REMOVE_UPLOAD_FROM_PENDING(fileUid) {
    this.pendingUploads.splice(this.pendingUploads.indexOf(fileUid), 1)
  }
}
const actions = {
  getUploadOrderHistory({ code, asc, currentPage, pageSize }) {
    return api.orders
      .getUploadOrderHistory({ code, asc, currentPage, pageSize })
      .then((res) => {
        this.SET_UPLOAD_ORDER_HISTORY(
          res.data?.historyEntries ? res.data.historyEntries : []
        )
        this.paginationData = res.data.pagination
        return res.data
      })
      .catch((e) => {
        this.SET_UPLOAD_ORDER_HISTORY([])
        this.paginationData = {}
      })
  },
  downloadUploadOrderTemplate(orderUploadType) {
    return api.orders.downloadUploadOrderTemplate(orderUploadType)
  },

  getUploadOrderHistoryStatuses({ fileUids }) {
    return api.orders.getUploadOrderHistoryStatuses(fileUids).then((res) => {
      if (res.data.statuses.length) {
        res.data.statuses.forEach((file) => {
          if (file.status !== 'IN_PROGRESS') {
            this.REMOVE_UPLOAD_FROM_PENDING(file.fileId)
          }
          if (file.status === 'FAILED') {
            const error = i18n.tc('order.upload.errorMessage', file.fileName, {
              fileName: file.fileName
            })
            Vue.toasted.error(error, {
              icon: {
                name: 'error'
              },
              duration: null,
              action: {
                icon: 'close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          }
          if (file.status === 'SUCCESS') {
            Vue.toasted.info(file.message, {
              icon: {
                name: 'info'
              },
              duration: null,
              action: {
                icon: 'close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          }
        })
        if (this.pendingUploads.length) {
          setTimeout(() => {
            this.getUploadOrderHistoryStatuses({
              fileUids: this.pendingUploads
            })
          }, 60000)
        } else {
          return res
        }
      }
    })
  }
}

export const uploadOrdersStore = defineStore('uploadOrders', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions: {
    ...mutations,
    ...actions
  }
})
