<template src="@/templates/b2b/App"></template>
<script>
import { mapState } from 'pinia'

import Auth from '@/lib/core/auth'
import routes from '@/lib/utils/routes'

import appInit from '@/components/common/mixins/app-init'
import appSelfCheck from '@/components/common/mixins/app-selfcheck'
import idle from '@/components/common/mixins/idle'
import loader from '@/components/common/mixins/loader'

import AppFooter from '@/components/common/AppFooter'
import AppHeader from '@/components/common/AppHeader'
import Breadcrumbs from '@/components/common/Breadcrumbs'
import LanguageSelector from '@/components/common/i18n/LanguageSelector'
import Idle from '@/components/common/Idle'

import CartSidebar from '@/components/b2b/cart/Cart/CartSidebar'
import { cartStore } from '@/stores/cart'
import cartSidebarTransition from '@/transitions/cart-sidebar'

export default {
  name: 'App',
  components: {
    LanguageSelector,
    CartSidebar,
    Idle,
    AppFooter,
    AppHeader,
    Breadcrumbs
  },
  mixins: [appInit, appSelfCheck, idle, loader],
  data() {
    return {
      cartSidebarTransition,
      auth: Auth
    }
  },
  computed: {
    ...mapState(cartStore, ['isSidebarVisible']),
    canShowFooter() {
      return !routes.isFatalError(this.$route.name)
    },
    canShowHeader() {
      return this.isLoggedIn && !routes.isPublic(this.$route.name)
    },
    canShowBreadcrumbs() {
      return this.canShowHeader && !routes.isHideBreadcrumbs(this.$route.name)
    },
    isLoggedIn() {
      return this.auth.getIsLoggedIn()
    }
  }
}
</script>
