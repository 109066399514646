/*
  Directive that will signal when the user clicks outside the given element
  Credit: Jamie Reynolds (dev.to) and Taha Shashtari (medium.com)
*/
export default {
  bind(el, binding) {
    const handleOutsideClick = (e) => {
      if (!el.contains(e.target) && el !== e.target) {
        binding.value(e)
      }
    }
    // assign handler so it's easier to remove
    el.handleOutsideClick = handleOutsideClick

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind(el) {
    document.removeEventListener('click', el.handleOutsideClick)
    document.removeEventListener('touchstart', el.handleOutsideClick)
  }
}
