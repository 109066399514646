import { internalFormat, momentDateEST } from '@/lib/utils/formatDate'

export class Invoice {
  constructor(invoice) {
    this.accountName = invoice?.accountName ? invoice.accountName : ''
    this.createdDate = invoice?.createdDate
      ? invoice.createdDate
      : momentDateEST().format(internalFormat)
    this.customerAccountNumber = invoice?.customerAccountNumber
      ? invoice.customerAccountNumber
      : ''
    this.id = invoice?.invoiceNumber ? invoice.invoiceNumber : ''
    this.invoiceCreatedDate = invoice?.invoiceCreatedDate
      ? invoice.invoiceCreatedDate
      : momentDateEST().format(internalFormat)
    this.invoiceNumber = invoice?.invoiceNumber ? invoice.invoiceNumber : ''
    this.invoiceTotal = invoice?.invoiceTotal ? invoice.invoiceTotal : ''
    this.orderNumber = invoice?.orderNumber ? invoice.orderNumber : ''
    this.packingSlipNumbers = invoice?.packingSlipNumbers
      ? invoice.packingSlipNumbers
      : []
    this.pickingListNumber = invoice?.pickingListNumber
      ? invoice.pickingListNumber
      : ''
    this.purchaseOrder = invoice?.purchaseOrder ? invoice.purchaseOrder : ''
    this.transactionSource = invoice?.transactionSource
      ? invoice.transactionSource
      : ''
    this.customerTransactionId = invoice?.customerTransactionId
      ? invoice.customerTransactionId
      : ''
  }
}
