const updateClass = (selector, action, className) => {
  const el = document.querySelector(selector)
  if (el && el.classList) {
    el.classList[action](className)
  }
}

/**
 * Add CSS class to body element.
 * @param className
 */
const addBodyCssClass = (className) => {
  updateClass('body', 'add', className)
}
/**
 * Add CSS class to body element.
 * @param className
 */
const removeBodyCssClass = (className) => {
  updateClass('body', 'remove', className)
}

export { addBodyCssClass, removeBodyCssClass }
