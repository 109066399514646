import { defineStore } from 'pinia'

// roles enum
const roles = {
  ADMIN: 'cxcommgadmingroup',
  PRICE_MANAGER: 'cxcommgpricingsuperusergroup',
  RESOLUTIONS: 'cxcommgresolutionusergroup',
  SUPPORT_ADMIN: 'cxcommgsupportadmingroup',
  CX_REP: 'cxrepgroup',
  CX_HELP: 'cxhelpgroup',
  CX_SUPERVISOR: 'cxsupervisorgroup',
  SALES_REP: 'salesrepgroup',
  SALES_MANAGER: 'salesmanagergroup',
  SERVICE: 'servicerolegroup',
  CHANNEL_MARKETING: 'channelmarketinggroup',
  MREPS: 'mrepsgroup',
  FIELD_MARKETING_MANAGER: 'fieldmarketingmanagergroup'
}

const state = {
  roles: []
}

const getters = {
  hasRole: (state) => (role) =>
    typeof role === 'string'
      ? state.roles.includes(roles[role])
      : role.some((item) => roles.includes(item))
}

const actions = {
  setRoles(payload) {
    if (typeof payload === 'string') {
      this.roles = [...payload]
    } else if (Array.isArray(payload)) {
      this.roles = [...payload]
    } else {
      this.roles = [...roles]
    }
  }
}

export const userStore = defineStore('user', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions
})
