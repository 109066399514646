import {
  Swiper as SwiperClass,
  Pagination,
  Navigation
} from 'swiper/swiper.esm'
import vClickOutside from 'v-click-outside'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import Fragment from 'vue-fragment'
import VueLetterAvatar from 'vue-letter-avatar'
import Toasted from 'vue-toasted'

import settings from '@/lib/core/settings'
import googleTagManagerHelper from '@/lib/utils/googleTagManagerHelper'

import ModalDialog from '@/components/common/dialog'
import ImageLoader from '@/components/common/ImageLoader'
import Lightbox from '@/components/common/Lightbox'
import Loader from '@/components/common/Loader'

import RouterGuard from '@/router/routes-guards'

if (settings.services.pubnub.enabled) {
  require('@/lib/services/pubnub')
}

Vue.component('loader', Loader)
Vue.component('image-loader', ImageLoader)

Vue.use(VueLetterAvatar)
SwiperClass.use([Pagination, Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(AsyncComputed)
Vue.use(Lightbox)
Vue.use(ModalDialog)
Vue.use(vClickOutside)
Vue.use(VTooltip, {
  defaultHtml: false
})

Vue.use(Toasted, {
  position: 'top-center',
  duration: 4500,
  fitToScreen: true,
  closeOnSwipe: true
})

Vue.use(Fragment.Plugin)

googleTagManagerHelper.init()
