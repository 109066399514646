import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Notifications extends ApiBase {
  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getBaseUrl() {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/notifications/${userId}`)
  }

  /**
   * Get all notifications
   */
  all() {
    const url = this.getBaseUrl()
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Notifications
