var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-account-menu__wrapper mt-z-400 flex flex-col absolute top-full right-0 py-5 mt-1.5 bg-white shadow-md rounded-xl lg:fixed lg:left-0 lg:top-0 lg:bottom-0 lg:p-0 lg:pt-5 lg:mt-0 lg:rounded-none"},[_c('button',{staticClass:"hidden lg:block absolute top-2 right-2 w-7 h-7 text-secondary-500 cursor-pointer z-10",on:{"click":_vm.closeMenu}},[_c('app-icon',{attrs:{"icon":"close"}})],1),_c('ul',[_c('li',[(_vm.isPersonalInformationPage)?_c('router-link',{staticClass:"mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline",class:{
          'mt-account-menu__item-active lg:text-primary-500':
            _vm.activeItem === 'account-settings'
        },attrs:{"to":"/account/settings"}},[_c('span',{staticClass:"mr-2 material-icons-outlined"},[_vm._v("person")]),_vm._v(" "+_vm._s(_vm.$t('header.account.personalInfo'))+" ")]):_vm._e()],1),(_vm.isAccountsPage)?_c('li',[_c('router-link',{staticClass:"mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline",class:{
          'mt-account-menu__item-active lg:text-primary-500':
            _vm.activeItem === 'accounts'
        },attrs:{"to":"/accounts"}},[_c('span',{staticClass:"mr-2 material-icons-outlined"},[_vm._v("group")]),_vm._v(" "+_vm._s(_vm.$t('header.account.accounts'))+" ")])],1):_vm._e(),(_vm.showUploadOrders)?_c('li',[_c('router-link',{staticClass:"mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline",class:{
          'mt-account-menu__item-active lg:text-primary-500':
            _vm.activeItem === 'uploadOrders'
        },attrs:{"to":"/upload-orders"}},[_c('span',{staticClass:"mr-2 material-icons-outlined"},[_vm._v("file_upload")]),_vm._v(" "+_vm._s(_vm.$t('header.uploadOrders'))+" ")])],1):_vm._e(),(_vm.$settings.feature('addresses.enabled'))?_c('li',[_c('router-link',{staticClass:"mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline",class:{
          'mt-account-menu__item-active lg:text-primary-500':
            _vm.activeItem === 'addresses'
        },attrs:{"to":"/addresses"}},[_c('span',{staticClass:"mr-2 material-icons-outlined"},[_vm._v("location_on")]),_vm._v(" "+_vm._s(_vm.$t('header.account.addresses'))+" ")])],1):_vm._e(),_c('li',{staticClass:"my-1 mx-5 h-px bg-gray-50"}),_c('li',[_c('a',{staticClass:"mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline text-gray-400",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('span',{staticClass:"mr-2 material-icons-outlined"},[_vm._v("logout")]),_vm._v(" "+_vm._s(_vm.$t('header.account.logout'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }