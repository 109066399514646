class ApiBase {
  constructor(transport) {
    if (!transport) {
      throw new Error('Api transport cannot be undefined')
    }
    this.transport = transport
  }

  getEndpointUrl(endpoint) {
    return `/v1${endpoint}`
  }

  isSuccess(response) {
    return response && response.data && response.data.success
  }
}

export default ApiBase
