import { eventBus } from '@/lib/utils/bus'

export default {
  methods: {
    modalLoaderStart() {
      this.isLoaderVisible = true
      this.isLoaderModal = true
    },
    loaderStart() {
      this.isLoaderVisible = true
      this.isLoaderModal = false
    },
    loaderStop() {
      this.isLoaderVisible = false
    }
  },
  created() {
    eventBus.$on('modalLoader.start', this.modalLoaderStart)
    eventBus.$on('modalLoader.stop', this.loaderStop)
    eventBus.$on('loader.start', this.loaderStart)
    eventBus.$on('loader.stop', this.loaderStop)
  },
  beforeDestroy() {
    eventBus.$off('modalLoader.start', this.modalLoaderStart)
    eventBus.$off('modalLoader.stop', this.loaderStop)
    eventBus.$off('loader.start', this.loaderStart)
    eventBus.$off('loader.stop', this.loaderStop)
  },
  data() {
    return {
      isLoaderVisible: false,
      isLoaderModal: false
    }
  }
}
