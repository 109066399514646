<template src="@/templates/common/dialog/ModalMessage"></template>
<script>
import { TimelineMax } from 'gsap'

export default {
  name: 'ModalMessage',
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
    const tl = new TimelineMax()
    tl.fromTo(
      '.modal-message .content-area',
      0.5,
      {
        top: '55%',
        opacity: 0,
        position: 'absolute'
      },
      {
        top: '50%',
        scale: 1,
        opacity: 1
      }
    )
  }
}
</script>
