'use strict'
import TweenMax from 'gsap'

export default {
  mode: 'out-in',
  enter: function (el, done) {
    TweenMax.fromTo(
      el,
      0.2,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: function () {
          done()
        }
      }
    )
  },
  leave: function (el, done) {
    done()
  }
}
