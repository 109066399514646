import { render, staticRenderFns } from "@/templates/common/Idle?vue&type=template&id=316b8942&scoped=true&"
import script from "./Idle.vue?vue&type=script&lang=js&"
export * from "./Idle.vue?vue&type=script&lang=js&"
import style0 from "./Idle.vue?vue&type=style&index=0&id=316b8942&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "316b8942",
  null
  
)

export default component.exports