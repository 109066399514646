import assignment from 'assignment'
import get from 'lodash/get'

import api from '@/lib/api'
import storage from '@/lib/core/storage'
import { eventBus } from '@/lib/utils/bus'

import router from '@/router/index'

const defaultAuthData = {
  username: '',
  accessToken: '',
  idToken: '',
  refreshToken: '',
  expiresIn: 0,
  loggedIn: 0,
  accountDetails: {}
}

const Auth = {
  auth: Object.assign(
    JSON.parse(JSON.stringify(defaultAuthData)),
    storage.read('authData')
  ),

  /**
   * Check whether the current user is logged in or not.
   * @returns {*|{username: string; password: string}|AxiosBasicCredentials|boolean}
   */
  getIsLoggedIn() {
    const auth = this.auth
    const now = Math.floor(Date.now() / 1000)
    return (
      auth &&
      Object.keys(auth).length &&
      now - auth.loggedIn < auth.expiresIn &&
      auth.username.length > 0 &&
      auth.accessToken.length > 0 &&
      auth.idToken.length > 0
    )
  },

  /**
   * Get object with current user data.
   * @returns {*|{username: string; password: string}|AxiosBasicCredentials|null}
   */
  getCurrentUser() {
    return this.auth
  },

  /**
   * Get ID of the current user
   */
  getCurrentUserId() {
    return this.getCurrentUser().username
  },

  /**
   * Get authData from HTTP response on login.
   */
  getAuthDataFromResponse(response) {
    if (!response || response.status < 200 || response.status > 201) {
      return null
    }

    const authData = {
      accessToken: get(response, 'data.access_token', ''),
      idToken: get(response, 'data.id_token', ''),
      refreshToken: get(response, 'data.refresh_token', ''),
      expiresIn: get(response, 'data.expires_in', 0)
    }

    if (
      !authData.accessToken ||
      !authData.idToken ||
      !authData.refreshToken ||
      !authData.expiresIn
    ) {
      return null
    }
    return authData
  },

  /**
   * Refresh auth token.
   */
  refreshToken() {
    api.auth
      .refreshAuthToken(this.getCurrentUser().refreshToken)
      .then((response) => {
        eventBus.$emit('auth.refreshToken')
        const authData = this.getAuthDataFromResponse(response)
        if (!authData) {
          throw new Error('Refresh token has failed.')
        }

        // Set user data
        this.setAuthData({ ...authData })
      })
  },

  /**
   * Load authData from storage and set to store.
   */
  loadAuthDataFromStorage() {
    const authData = this.readAuthData()
    if (
      authData &&
      typeof authData === 'object' &&
      Object.keys(authData).length > 0
    ) {
      this.setAuthData(authData)
    }
    return authData || {}
  },

  /**
   * Reset session authentication data and emit global 'auth.logout' event.
   */
  resetSessionData() {
    this.resetAuthData()
  },

  /**
   * Logout user.
   */
  logout() {
    eventBus.$emit('auth.logout', { status: 'begin' })
    api.auth
      .logout()
      .then(() => {
        eventBus.$emit('auth.logout', { status: 'success' })
        this.resetSessionData()
        router.push('/login')
      })
      .catch((reason) => {
        eventBus.$emit('auth.logout', {
          status: 'error'
        })
      })
  },

  /**
   * Login user
   * @param username
   * @param password
   * @param rememberMe
   * @returns {Promise<any>}
   */
  login(username, password, rememberMe = false) {
    return new Promise((resolve, reject) => {
      try {
        eventBus.$emit('auth.login', { status: 'begin' })
        api.auth
          .login(username, password)
          .then((response) => {
            const authData = this.getAuthDataFromResponse(response)
            if (!authData) {
              throw new Error('Login failed')
            }

            // Set user data
            const now = Math.floor(Date.now() / 1000)
            this.setAuthData({
              ...authData,
              username,
              loggedIn: now
            })

            api.user.accountDetails(username).then((accountDetails) => {
              this.setAuthData({ accountDetails })
              eventBus.$emit('auth.login', { status: 'success' })
              resolve(true)
            })
          })
          .catch((reason) => {
            eventBus.$emit('auth.login', { status: 'error' })
            reject(reason)
          })
      } catch (e) {
        console.log(e)
        eventBus.$emit('auth.login', { status: 'error' })
        reject(e)
      }
    })
  },

  /**
   * Refresh account details.
   * @param username
   * @returns {Promise<any>}
   */
  refreshAccountDetails(username) {
    return new Promise((resolve, reject) => {
      api.user
        .accountDetails(username)
        .then((accountDetails) => {
          this.setAuthData({ accountDetails })
          resolve(true)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  /**
   * Read authData from storage.
   * @returns {*}
   */
  readAuthData() {
    const authData = storage.read('authData')
    return authData
  },

  /**
   *
   * @param authData
   */
  storeAuthData(authData) {
    storage.save('authData', authData)
  },

  resetAuthData() {
    const defaults = JSON.parse(JSON.stringify(defaultAuthData))
    this.auth = defaults
    this.storeAuthData(this.auth)
  },

  setAuthData(authData) {
    const defaults = JSON.parse(JSON.stringify(defaultAuthData))
    this.auth = assignment(defaults, this.auth, authData)
    this.storeAuthData(this.auth)
  }
}

export default Auth
