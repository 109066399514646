import 'swiper/swiper.min.css'
import '@/styles/app.scss'
import axios from 'axios'
import Vue from 'vue'

import pinia, { bootstrapPiniaStores } from '@/stores'

import api from '@/lib/api'
import auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'

import { i18n } from '../i18n'

import router from '@/router'

require('@/lib/bootstrap')
const App = require('@/lib/core/extension-models').default
require('@/lib/core/plugins')
require('@/lib/api/google')
require('@/lib/utils/object')
require('@/lib/utils/currency')
require('@/lib/utils/date-time')

Vue.http = Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.settings = Vue.prototype.$settings = settings
Vue.api = Vue.prototype.$api = api

auth.loadAuthDataFromStorage()

/* eslint-disable no-new */
export const app = new Vue({
  components: { App },
  router,
  pinia,
  i18n,
  template: '<App/>'
}).$mount('#app')

bootstrapPiniaStores()
