import assignment from 'assignment'
import { Order, OrdersQuery, OrdersList } from 'zcmodels'

import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Orders extends ApiBase {
  /**
   * Get a list of recent orders.
   * @mocked
   * @returns {Promise<any>}
   */
  getRecentOrders(limit = 6) {
    return this.getOrders({
      perPage: limit,
      page: 0
    })
  }

  /**
   * Get order by ID.
   * @param id
   * @returns {Promise<any>}
   */
  getOrder(id) {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/orders/${userId}/${id}`)
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          const order = new Order(response.data)
          resolve(order)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  async getStats() {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/orders-stats/${userId}`)
    const response = await this.transport.get(url)
    return response.data
  }

  /**
   * Get list of orders.
   * @param userQuery
   * @returns {Promise<any>}
   */
  getOrders(userQuery) {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/orders/${userId}`)
    const defaults = {
      status: '',
      perPage: 10,
      page: 0
    }
    userQuery = assignment(defaults, userQuery)
    return new Promise((resolve, reject) => {
      const query = new OrdersQuery({
        status: userQuery.status,
        perPage: userQuery.perPage,
        page: userQuery.page
      })

      this.transport
        .get(url, query)
        .then((response) => {
          const ordersList = new OrdersList(response.data)
          resolve(ordersList)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Orders
