import ApiBase from '@/lib/api/v1/api-base'

class Content extends ApiBase {
  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getContent(contentType, id) {
    const url = this.getEndpointUrl(`/content/${contentType}/${id}`)
    return this.transport.get(url)
  }
}

export default Content
