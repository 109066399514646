import Vue from 'vue'
import Router from 'vue-router'

import auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'
import { eventBus } from '@/lib/utils/bus'
import { addBodyCssClass, removeBodyCssClass } from '@/lib/utils/dom'

import routes from '@/router/routes'
import { appStore } from '@/stores/app'
import { userStore } from '@/stores/user'

Vue.use(Router)

const router = new Router({ routes })
let isEnabled = true

/**
 * Add css class to body with a name of the current route.
 */
router.afterEach((to, from) => {
  removeBodyCssClass(from.name)
  addBodyCssClass(to.name)

  appStore().setPreviousRoute(from)
  eventBus.$emit('route.after', {
    to,
    from
  })
})

/**
 * Force to login if not authorized.
 */
router.beforeEach((to, from, next) => {
  if (!isEnabled) {
    return
  }

  // check settings feature to determine if route is allowed
  if (to.meta.featureToggle && !settings.feature(to.meta.featureToggle)) {
    return next('/404')
  }

  // disallow route based on user roles
  if (to.meta.disallowedRoles) {
    const hasRole = userStore().hasRole()

    if (hasRole(to.meta.disallowedRoles)) {
      return next('/404')
    }
  }

  const isLoggedIn = auth.getIsLoggedIn()
  const goingToLogin = !isLoggedIn && to.name === 'login'

  const params = {
    to,
    from,
    next
  }
  eventBus.$emit('route.before', params)
  if (isLoggedIn || goingToLogin) {
    if (goingToLogin) {
      eventBus.$emit('auth.logout', { status: 'success' })
    }
    params.next()
  } else {
    const nextRoute = routes.find((el) => el.name === to.name)
    const publicRoute =
      nextRoute && 'publicRoute' in nextRoute && nextRoute.publicRoute
    if (publicRoute || isLoggedIn) {
      params.next()
    } else {
      params.next('/login')
    }
  }
})

/**
 * Disable router.
 */
eventBus.$off('router.stop').$on('router.stop', () => {
  isEnabled = false
})

/**
 * Enable router.
 */
eventBus.$off('router.start').$on('router.start', () => {
  isEnabled = true
})

export default router
