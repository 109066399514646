import { SearchSuggestion, SearchQuery } from 'zcmodels'

import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Search extends ApiBase {
  searchQuery(query, filter = 'all') {
    const searchQuery = new SearchQuery({
      query,
      filter
    })
    return new Promise((resolve) => {
      const url = this.getEndpointUrl(`/search/query?${searchQuery.getQuery()}`)
      this.transport
        .get(url, null, { transportOptions: { silent: true } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          resolve(null)
        })
    })
  }

  /**
   * Suggest a list of items based on the entered query.
   * @param query
   * @param filter
   * @returns {Promise<any>}
   */
  suggest(query, filter = 'all') {
    const suggestion = new SearchSuggestion({
      query,
      filter
    })
    return new Promise((resolve) => {
      const url = this.getEndpointUrl(
        `/search/suggest?${suggestion.getQuery()}`
      )
      this.transport
        .get(url, null, { transportOptions: { silent: true } })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          resolve(null)
        })
    })
  }

  /**
   * Get picked products for the current user.
   * @returns {Promise<any>}
   */
  pickedProducts(limit = -1) {
    const userId = auth.getCurrentUserId()
    return new Promise((resolve) => {
      const url = this.getEndpointUrl(
        `/search/picked-products/${userId}?limit=${limit}`
      )
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          resolve(null)
        })
    })
  }

  /**
   * Get home page featured products.
   * @returns {Promise<any>}
   */
  homeProducts() {
    return new Promise((resolve) => {
      const url = this.getEndpointUrl('/search/home-products')
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          resolve(null)
        })
    })
  }
}

export default Search
