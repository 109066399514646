export default {
  /**
   * Read a value from storage.
   * @param name
   * @returns {*}
   */
  read(name) {
    const value = localStorage.getItem(name)
    if (!value) {
      return null
    }

    return JSON.parse(value)
  },

  /**
   * Save a value to storage.
   * @param name
   * @param value
   */
  save(name, value) {
    localStorage.setItem(name, JSON.stringify(value))
  }
}
