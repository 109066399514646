import { defineStore } from 'pinia'

import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { ROLES, DEFAULT_STATE } from '@/lib/models/user/roles'

import { currentCartStore } from '@/stores/currentCart'
import { quotesStore } from '@/stores/quotes'
import { userStore } from '@/stores/user'

const state = { ...DEFAULT_STATE }

const actions = {
  setRoles(roles) {
    if (roles && roles.length) {
      for (const role in DEFAULT_STATE) {
        this[role] = roles.includes(ROLES[userStore().metRegion][role])
      }
    }
  },
  resetRoles() {
    Object.assign(this, DEFAULT_STATE)
  }
}

const getters = {
  isKPIMetricsWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.salesDirector,
  isQuotesSalesOrdersWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator ||
    state.superuser ||
    state.distributor,
  isTeamMetricsWidget: (state) =>
    state.salesManager || state.superuser || state.cxManager,
  isRecentInvoicesWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.superuser ||
    state.distributor,
  isRecentOrdersWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.distributor,
  isQuickQuoteWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.superuser ||
    state.distributor,
  isFailedCartWidget: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser,
  isDashboardHeaderBanner: (state) =>
    state.channelMarketing || state.distributor,
  isDashboardFilters: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isDashboardFiltersAccount: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isDashboardFiltersProduct: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isDashboardFiltersTeamMember: (state) =>
    state.salesManager ||
    state.cxRep ||
    state.superuser ||
    state.cxManager ||
    state.salesDirector,
  isDashboardFiltersPL: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.salesDirector,
  isDashboardFiltersBU: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.salesDirector,
  isDashboardFiltersPCE: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isPicksForYou: () => false,
  isPopularSearches: () => false,
  isPLPorPDP: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isAddProductBtnPLPorPDPDisabled: (state) => state.salesDirector,
  isStockStatus: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.superuser ||
    state.distributor,
  isPriceBreakdown: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isWorkflowApproveReject: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator,
  isQuoteManagement: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.superuser ||
    state.distributor,
  isOrderManagement: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isOrderOrQuoteManagement: (state) =>
    quotesStore().isOrder ? state.isOrderManagement : state.isQuoteManagement,
  isReturnManagement: (state) =>
    state.cxRep ||
    state.cxManager ||
    state.superuser ||
    state.itAdmin ||
    state.pricingSuperUser,
  isCreditMemoManagement: (state) =>
    state.cxRep ||
    state.cxManager ||
    state.superuser ||
    state.itAdmin ||
    state.pricingSuperUser,
  isNoChargeManagement: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isCartManagement: (state) => {
    switch (currentCartStore().currentCartType) {
      case CART_TYPES.QUOTE.type:
        return state.isOrderOrQuoteManagement
      case CART_TYPES.RETURN.type:
        return state.isReturnManagement
      case CART_TYPES.NO_CHARGE.type:
        return state.isNoChargeManagement
      case CART_TYPES.CREDIT_MEMO.type:
        return state.isCreditMemoManagement
      default:
        return false
    }
  },
  isCartSubmit: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.distributor,
  isSendQuoteToCustomer: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isCreateOrderOnHold: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isCartImportExport: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser ||
    state.distributor,
  isQuoteNetPriceEdit: (state) => state.itAdmin || state.pricingSuperUser,
  isCreditMemoNetPriceEdit: (state) =>
    state.cxRep || state.cxManager || state.itAdmin || state.pricingSuperUser,
  isReturnNetPriceEdit: (state) =>
    state.cxRep || state.cxManager || state.itAdmin || state.pricingSuperUser,
  isUploadOrder: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.superuser ||
    state.distributor,
  isUploadCreditMemo: (state) =>
    state.cxRep ||
    state.cxManager ||
    state.superuser ||
    state.itAdmin ||
    state.pricingSuperUser,
  isUploadNoCharge: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser,
  isAnyUpload: (state) =>
    state.isUploadOrder || state.isUploadCreditMemo || state.isUploadNoCharge,
  isOrdersAndOrderDetailsPages: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.superuser ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator ||
    state.distributor,
  isInvoicesPage: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.superuser ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator ||
    state.distributor,
  isCartsAndCartDetailsPages: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator ||
    state.superuser ||
    state.distributor,
  isAccountsPage: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.superuser ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator ||
    state.distributor,
  isPersonalInformationPage: (state) =>
    state.salesRep ||
    state.salesManager ||
    state.cxRep ||
    state.cxManager ||
    state.itAdmin ||
    state.pricingSuperUser ||
    state.channelMarketing ||
    state.superuser ||
    state.salesDirector ||
    state.logistic ||
    state.financeDirector ||
    state.arCoordinator
}

export const rolesStore = defineStore('roles', {
  persist: true,
  state: () => ({
    ...state
  }),

  getters,
  actions
})
