var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
    'mt-input-suggestions mt-max-height--300 mt-inputs-suggest-list-shadow absolute p-0 rounded-lg mt-z-100 overflow-auto bg-white',
    {
      'mt-input-product-suggest-list--active': _vm.suggestions.length
    },
    _vm.classList
  ]},_vm._l((_vm.suggestions),function(item){return _c('li',{key:item.sku,staticClass:"mt-input-suggestions__item border-b border-solid border-gray-100 p-2.5 flex items-start justify-between text-xs cursor-pointer hover:bg-gray-50 focus:bg-gray-50",attrs:{"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.select(item)},"touchstart":function($event){$event.preventDefault();return _vm.select(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.keySelect($event, item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.keySelect($event, item)}}},[_c('div',{staticClass:"text-xs text-gray-800 mr-3",domProps:{"innerHTML":_vm._s(item.name)}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.sku'))+": "+_vm._s(item.code))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }