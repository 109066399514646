<template>
  <div class="mt-logo m-0" @click="redirectToLogin">
    <router-link
      v-if="isLink"
      to="/"
      class="mt-logo__link block"
      :class="customSize"
    >
      <img class="w-full lg:max-h-10" :src="logoSrc" />
    </router-link>
    <img v-else :class="customSize" class="w-full lg:max-h-10" :src="logoSrc" />
  </div>
</template>

<script>
import Logo from '@/components/common/Logo'

export default {
  extends: Logo,
  props: {
    customSize: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.mt-logo {
  &__link {
    max-width: 106px;
  }
}
</style>
