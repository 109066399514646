import Vue from 'vue'

import settings from '@/lib/core/settings'
import routes from '@/lib/utils/routes'

export default {
  /**
   * Get page's title by route name.
   * @param routeName
   * @returns {*}
   */
  getPageTitleByRouteName(routeName) {
    const routeData = routes.find(routeName)
    let title = settings.app.title.defaultTitle
    if (routeData && routeData.pageTitle) {
      const pageTitle = Vue.translate(routeData.pageTitle)
      if (
        settings.app.title.separator &&
        settings.app.title.separator.length > 0 &&
        pageTitle !== settings.app.title.defaultTitle
      ) {
        title = `${pageTitle}${settings.app.title.separator}${settings.app.title.defaultTitle}`
      } else {
        title = `${pageTitle}`
      }
    }
    return title
  },

  /**
   * Set the title.
   * @param title
   */
  setTitle(title) {
    window.document.title = title
  },

  /**
   * Set new title by passing the title as a string.
   * @param newTitle
   */
  updateAsString(newTitle) {
    let title = settings.app.title.defaultTitle
    if (newTitle && newTitle.length && newTitle !== title) {
      title = `${newTitle}${settings.app.title.separator}${title}`
    }
    this.setTitle(title)
  },

  /**
   * Update page's title.
   * @param route
   */
  update(route) {
    let title = settings.app.title.defaultTitle
    if (route && 'name' in route) {
      title = this.getPageTitleByRouteName(route.name)
    }
    this.setTitle(title)
  }
}
