<template>
  <div v-if="createBtnListVisible.length">
    <dropdown
      v-if="createBtnListVisible.length > 1"
      positioning="bottom right"
      @isOpen="addNewDropdownToggle"
    >
      <template #trigger>
        <app-button color="secondary" :class="{ active: isModalOpen }" icon>
          <app-icon icon="add" color="text-white" size="xxl" />
        </app-button>
      </template>
      <div class="p-2 grid col-span-1 gap-1">
        <create-btn
          v-for="btn in createBtnListVisible"
          :key="btn.type"
          :title="btn.title"
          color="secondary"
          variant="outline"
          @create="createNewCart(btn.type)"
        />
      </div>
    </dropdown>
    <create-btn
      v-else
      :title="createBtnListVisible[0].title"
      color="secondary"
      @create="createNewCart(createBtnListVisible[0].type)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { UpdateRouteByCartIdMixin } from '@/mixins/cart/UpdateRouteByCartId.mixin.js'

import AppButton from '@/components/common/AppButton'
import AppIcon from '@/components/common/AppIcon'

import Dropdown from '@/components/b2b/common/dropdown/Dropdown.vue'

import CreateBtn from '@/components/b2b/cart/common/CreateBtn'

import { quotesStore } from '@/stores/quotes'
import { rolesStore } from '@/stores/roles'
import { userStore } from '@/stores/user'

export default {
  name: 'CreateNewCartDropdown',
  components: {
    Dropdown,
    CreateBtn,
    AppButton,
    AppIcon
  },
  mixins: [UpdateRouteByCartIdMixin],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapState(rolesStore, [
      'isQuoteManagement',
      'isOrderManagement',
      'isReturnManagement',
      'isNoChargeManagement',
      'isCreditMemoManagement'
    ]),
    ...mapState(userStore, ['obrPhase']),
    createBtnList() {
      return [
        {
          title: this.$t('header.createNew.quote'),
          type: 'createQuote',
          isVisible: this.isQuoteManagement
        },
        {
          title: this.$t('header.createNew.order'),
          type: 'createOrder',
          isVisible: this.isOrderManagement && !this.obrPhase
        },
        {
          title: this.$t('header.createNew.return'),
          type: 'createReturn',
          isVisible: this.isReturnManagement && !this.obrPhase
        },
        {
          title: this.$t('header.createNew.noCharge'),
          type: 'createNoCharge',
          isVisible: this.isNoChargeManagement && !this.obrPhase
        },
        {
          title: this.$t('header.createNew.creditMemo'),
          type: 'createCreditMemo',
          isVisible: this.isCreditMemoManagement && !this.obrPhase
        }
      ]
    },
    createBtnListVisible() {
      return this.createBtnList.filter((btn) => btn.isVisible)
    }
  },
  methods: {
    ...mapActions(quotesStore, [
      'createQuote',
      'createOrder',
      'createReturn',
      'createNoCharge',
      'createCreditMemo'
    ]),
    addNewDropdownToggle(isOpen) {
      this.isModalOpen = isOpen
    },
    async createNewCart(type) {
      await this[type]().then(async (id) => {
        await this.updateRouteByCartId(id)
      })
    }
  }
}
</script>
