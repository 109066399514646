// @vue/component
export const BlobMixin = {
  methods: {
    downloadBlob(response, name = '') {
      const url = window.URL.createObjectURL(response.data)
      const fileName = name || response.headers['file-name']
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }
}
