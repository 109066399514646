import { defineStore } from 'pinia'

import api from '@/lib/api'
import string from '@/lib/utils/applyWildCards'

const state = {
  otherAccounts: [],
  favoriteAccounts: [],
  customersList: [],
  customersGroups: [],
  regions: [],
  currencies: [],
  customerStatuses: [],
  priceScheduleIds: [],
  keyAccountIds: [],
  statisticsGroups: [],
  customerGroupIds: [],
  companyChains: [],
  salesDistricts: [],
  distributorSlicers: [],
  distributorGroups0: [],
  distributorGroups1: [],
  distributorGroups2: [],
  distributorGroups3: [],
  distributorGroups4: [],
  distributorGroups5: [],
  paginationData: {},
  paginationDataFavorites: {}
}

const actions = {
  getOtherAccounts(payload) {
    return api.customers
      .getOtherAccounts(payload)
      .then((res) => {
        this.otherAccounts = res.data
      })
      .catch((e) => {
        this.otherAccounts = []
      })
  },
  getFavoriteAccounts({ query, currentPage, pageSize }) {
    return api.customers
      .getFavoriteAccounts({ query, currentPage, pageSize })
      .then((res) => {
        this.favoriteAccounts = res.data.units
        this.paginationDataFavorites = res.data.pagination
      })
      .catch((e) => {
        this.favoriteAccounts = []
        this.paginationDataFavorites = {}
      })
  },
  searchFavoriteAccounts({ term, currentPage, pageSize }) {
    return api.customers
      .getFavoriteAccounts({
        query: string.applyWildCards(term),
        currentPage,
        pageSize
      })
      .then((res) => {
        return res.data
      })
  },
  async addFavoriteAccount(accountId) {
    await api.customers.addFavoriteAccount(accountId)
    return this.fetchAllAccounts()
  },
  async removeFavoriteAccount(accountId) {
    await api.customers.removeFavoriteAccount(accountId)
    return this.fetchAllAccounts()
  },
  fetchAllAccounts() {
    return Promise.allSettled([
      this.getOtherAccounts({ page: 0, query: '' }),
      this.getFavoriteAccounts({ currentPage: 0, pageSize: 9 })
    ])
  },
  getCustomers({ searchData, currentPage, pageSize, asc, code }) {
    return api.customers
      .getCustomers({ searchData, currentPage, pageSize, asc, code })
      .then((res) => {
        this.customersList = res.data.units
        this.paginationData = res.data.pagination
      })
      .catch((e) => {
        this.customersList = []
        this.paginationData = {}
      })
  },
  searchCustomers({ term, currentPage = 0, pageSize = 10 }) {
    return api.customers
      .getCustomers({
        searchData: {
          query: string.applyWildCards(term)
        },
        currentPage,
        pageSize
      })
      .then((res) => {
        return res.data
      })
  },
  advancedSearchFavorites({ searchData, currentPage, pageSize }) {
    return api.customers
      .advancedSearchFavorites(searchData, currentPage, pageSize)
      .then((res) => {
        this.favoriteAccounts = res.data.units
        this.paginationDataFavorites = res.data.pagination
      })
      .catch((e) => {
        this.favoriteAccounts = []
        this.paginationDataFavorites = {}
      })
  },
  getCustomersGroups() {
    return api.customers.getLookupTableValue('CustomerGroup').then((res) => {
      this.customersGroups = res.data
    })
  },
  getRegions(countryCode) {
    return api.customers.getRegions(countryCode).then((res) => {
      this.regions = res.data.regions
    })
  },
  getCurrencies() {
    return api.customers.getCurrencies().then((res) => {
      this.currencies = res.data.currencies
    })
  },
  getCustomerStatuses() {
    return api.customers.getLookupTableValue('CustomerStatus').then((res) => {
      this.customerStatuses = res.data
    })
  },
  getPriceScheduleId() {
    return api.customers.getLookupTableValue('PriceScheduleId').then((res) => {
      this.priceScheduleIds = res.data
    })
  },
  getKeyAccountId() {
    return api.customers.getLookupTableValue('KeyAccountID').then((res) => {
      this.keyAccountIds = res.data
    })
  },
  getStatisticsGroup() {
    return api.customers.getLookupTableValue('StatisticsGroup').then((res) => {
      this.statisticsGroups = res.data
    })
  },
  getCustomersGroupId() {
    return api.customers.getLookupTableValue('CustomerGroupID').then((res) => {
      this.customerGroupIds = res.data
    })
  },
  getCompanyChain() {
    return api.customers.getLookupTableValue('CompanyChain').then((res) => {
      this.companyChains = res.data
    })
  },
  getSalesDistrict() {
    return api.customers.getLookupTableValue('SalesDistrict').then((res) => {
      this.salesDistricts = res.data
    })
  },
  getDistributorSlicer() {
    return api.customers
      .getLookupTableValue('DistributorSlicer')
      .then((res) => {
        this.distributorSlicers = res.data
      })
  },
  getDistributorGroup0() {
    return api.customers
      .getLookupTableValue('DistributorGroup0')
      .then((res) => {
        this.distributorGroups0 = res.data
      })
  },
  getDistributorGroup1() {
    return api.customers
      .getLookupTableValue('DistributorGroup1')
      .then((res) => {
        this.distributorGroups1 = res.data
      })
  },
  getDistributorGroup2() {
    return api.customers
      .getLookupTableValue('DistributorGroup2')
      .then((res) => {
        this.distributorGroups2 = res.data
      })
  },
  getDistributorGroup3() {
    return api.customers
      .getLookupTableValue('DistributorGroup3')
      .then((res) => {
        this.distributorGroups3 = res.data
      })
  },
  getDistributorGroup4() {
    return api.customers
      .getLookupTableValue('DistributorGroup4')
      .then((res) => {
        this.distributorGroups4 = res.data
      })
  },
  getDistributorGroup5() {
    return api.customers
      .getLookupTableValue('DistributorGroup5')
      .then((res) => {
        this.distributorGroups5 = res.data
      })
  }
}

export const customersStore = defineStore('customers', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions
})
