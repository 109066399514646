import { mapState, mapActions } from 'pinia'

import auth from '@/lib/core/auth'
import appInitAuth0 from '@/lib/services/auth0/app-init-auth0'
import { eventBus } from '@/lib/utils/bus'
import pageTitle from '@/lib/utils/page-title'
import routes from '@/lib/utils/routes'
import scroll from '@/lib/utils/scroll'

import appInitQueue from '@/components/common/mixins/app-init-queue'
import refreshToken from '@/components/common/mixins/refresh-token'

import { cartStore } from '@/stores/cart'
import { langStore } from '@/stores/lang'

require('@/lib/utils/states')

export default {
  mixins: [refreshToken, appInitQueue, appInitAuth0],
  data() {
    return {
      ready: false
    }
  },
  computed: {
    ...mapState(langStore, ['localeId'])
  },
  methods: {
    ...mapActions(cartStore, ['refreshCarts']),
    ...mapActions(langStore, ['setLang']),
    /**
     * Un-initialize global application events.
     */
    removeGlobalEventListeners() {
      eventBus.$off('idle.autoLogout')
      eventBus.$off('app.refreshCarts')
      eventBus.$off('auth.logout')
      eventBus.$off('auth.login')
      eventBus.$off('auth.refreshToken')
      eventBus.$off('auth.notAuthorized')
      eventBus.$off('auth.authorizedError')
      eventBus.$off('transport.apiError')
      eventBus.$off('route.after')
    },

    /**
     * Add event listeners to the global app events.
     */
    addGlobalEventListeners() {
      eventBus.$on('idle.autoLogout', () => {
        auth.logout()
      })
      eventBus.$on('auth.logout', (payload) => {
        this.ready = payload.status !== 'begin'
      })
      eventBus.$on('auth.refreshToken', (payload) => {
        this.startRefreshToken()
      })
      eventBus.$on('auth.logout', (payload) => {
        this.ready = payload.status !== 'begin'
        if (payload.status === 'success') {
          this.stopRefreshToken()
        }
      })
      eventBus.$on('auth.notAuthorized', () => {
        if (routes.isPublic(this.$route.name)) return

        eventBus.$emit('auth.logout', { status: 'success' })
        auth.resetSessionData()
        this.$router.push('/login')
      })
      eventBus.$on('auth.login', (payload) => {
        if (payload.status === 'success') {
          this.initFetchData()
        }
      })
      eventBus.$on('transport.apiError', () => {
        this.$router.push('/error/api')
        this.ready = true
      })
      eventBus.$on('route.after', (payload) => {
        pageTitle.update(payload.to)
        scroll.pageToTop()
      })
    },

    /**
     * Check whether the app can fetch initialization data.
     * @returns {*}
     */
    canInitFetchData() {
      return auth.getIsLoggedIn()
    },

    /**
     * Fetch required data on application initialization.
     * @return {Promise<any>}
     */
    initFetchData() {
      return new Promise((resolve, reject) => {
        if (auth.getIsLoggedIn()) {
          this.startRefreshToken()
        }
        if (this.canInitFetchData()) {
          this.initApplication()
            .then(() => {
              resolve()
            })
            .catch((reason) => {
              reject(reason)
            })
        } else {
          resolve()
        }
      })
    }
  },
  beforeDestroy() {
    this.removeGlobalEventListeners()
  },
  mounted() {
    this.setLang(this.localeId)
    pageTitle.update(this.$route)
    this.addGlobalEventListeners()

    this.initFetchData().then(() => (this.ready = true))
  },
  watch: {
    // running auth process after the router initialization
    '$route.name'() {
      if (this.ready && !this.isLoggedIn) eventBus.$emit('auth.init')
    }
  }
}
