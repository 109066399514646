<template>
  <div class="mt-app-input w-full relative">
    <label v-if="label" class="m-0 pb-2 text-sm text-gray-400">{{
      label
    }}</label>
    <div class="flex">
      <input
        :id="inputId"
        ref="input"
        type="text"
        :disabled="disabled"
        :value="text"
        :class="[
          'mt-input bg-white border border-gray-200 rounded-sm w-full p-2 m-0 text-gray-400 text-sm outline-none focus:border-accent-500',
          classList,
          {
            'mt-input__error mt-form-error border-none': showError,
            'mt-input__warning': showWarning,
            'mt-visible': !disabilityStyles && disabled,
            truncate: truncate
          }
        ]"
        :placeholder="placeholderText"
        :maxlength="maxLength"
        tabindex="0"
        @input="$emit('input', $event.target.value)"
        @change="$emit('on-change', $event.target.value)"
        @blur="$emit('on-blur', $event.target.value)"
        @focus="$emit('on-focus', $event.target.value)"
        @keyup.down="$emit('keyup-down')"
        @keyup.enter="$emit('keyup-enter')"
        @keyup.delete="$emit('keyup-delete')"
        @keydown.enter="$emit('keydown-enter')"
        @keydown.tab="($event) => $emit('keydown-tab', $event)"
        @keyup.tab="($event) => $emit('keyup-tab', $event)"
      />
      <button
        v-if="appendIcon"
        class="flex items-center -ml-9 z-10"
        :class="{ 'cursor-default': disableAppendIcon }"
        :disabled="disableAppendIcon"
        @click="$emit('click-append-icon')"
      >
        <span class="material-icons-outlined">{{ appendIcon }}</span>
      </button>
    </div>
    <div
      v-if="showError && errorMessage"
      class="mt-app-input__message mt-app-input__message-error w-full absolute left-0 text-white bg-primary-400 px-5 py-1.5 rounded shadow z-20"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="showWarning && warningMessage"
      class="mt-app-input__message mt-app-input__message-warning text-secondary-700 w-full absolute left-0 bg-warning-400 px-5 py-1.5 rounded shadow z-20"
    >
      {{ warningMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: null
    },
    showError: {
      type: [Boolean, String],
      default: false
    },
    showWarning: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: ''
    },
    disableAppendIcon: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    dataLpignore: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    warningMessage: {
      type: String,
      default: ''
    },
    classList: {
      type: String,
      default: ''
    },
    disabilityStyles: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      text: ''
    }
  },
  computed: {
    placeholderText() {
      return this.isRequired ? `*${this.placeholder}` : this.placeholder
    }
  },
  watch: {
    value() {
      // set text for an async value that may not be ready by mount time
      if (this.value !== this.text) {
        this.text = this.value || ''
      }
    }
  },
  mounted() {
    this.text = this.value
  }
}
</script>
<style lang="scss" scoped>
.mt-app-input {
  &__message {
    top: calc(100% + 7px);

    &:before {
      content: '';
      @apply w-2 h-2 absolute top-0 right-10 transform -translate-y-1/2 rotate-45;
    }
    &-error {
      &:before {
        @apply bg-primary-400;
      }
    }

    &-warning {
      &:before {
        @apply bg-warning-400;
      }
    }
  }
}

.mt-input {
  &__error {
    scroll-margin: 15rem 0 0;
    box-shadow: 0px 0px 0px 2px var(--color-error-500);
  }
  &__warning {
    @apply border-warning-600 rounded-sm;
  }

  &:disabled {
    @apply opacity-50;

    &.mt-visible {
      @apply opacity-100;
    }
  }
}
</style>
