<template src="@/templates/common/i18n/LanguageList"></template>
<script>
import ContentLightbox from '@/components/common/ContentLightbox'

export default {
  name: 'LanguageList',
  components: { ContentLightbox },
  props: {
    locales: {
      type: Object,
      required: true
    },
    currentLocale: {
      type: Object,
      required: true
    }
  },
  methods: {
    hideLocales() {
      this.$emit('hide-locales')
    },
    selectLocale(locale) {
      this.$emit('locale-selected', locale)
    }
  }
}
</script>
