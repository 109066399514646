import Vue from 'vue'

import { i18n } from '../../../i18n'

/**
 * Date / time formatter.
 * @param value
 */
const dateTime = (value) => {
  if (!value) {
    return ''
  }
  const dt = new Date(value)
  return i18n.d(dt, 'long')
}

/**
 * Date formatter.
 * @param value
 */
const date = (value) => {
  const dt = new Date(value)
  return i18n.d(dt, 'short')
}

Vue.currency = Vue.prototype.$dateTime = dateTime
Vue.currency = Vue.prototype.$date = date
