import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { DefaultCreditMemoProduct } from '@/lib/models/product/product'
import {
  internalFormat,
  momentDateEST,
  getInternalformatDate
} from '@/lib/utils/formatDate'
import { getCartDisabledStatusByCartStatus } from '@/lib/utils/helpers'
import { validateOrderSubmit } from '@/lib/utils/validation'

import { quotesStore } from '@/stores/quotes'

export class CreditMemo {
  constructor(cart) {
    this.header = {
      APIType: cart?.header?.APIType ? cart.header.APIType : '',
      cartCode: cart?.header?.cartCode ? cart.header.cartCode : '',
      cartStatus: cart?.header?.cartStatus ? cart.header.cartStatus : '',
      createdBy: cart?.header?.createdBy ? cart.header.createdBy : '',
      currency: cart?.header?.currency ? cart.header.currency : '',
      customerAccount: cart?.header?.customerAccount
        ? cart.header.customerAccount
        : '',
      customerName: cart?.header?.customerName ? cart.header.customerName : '',
      errorCode: cart?.header?.errorCode ? cart.header.errorCode : '',
      errorMessage: cart?.header?.errorMessage ? cart.header.errorMessage : '',
      invoiceAccount: cart?.header?.invoiceAccount
        ? cart.header.invoiceAccount
        : '',
      lastUpdatedOn: cart?.header?.lastUpdatedOn
        ? getInternalformatDate(cart.header.lastUpdatedOn)
        : '',
      priceDate: cart?.header?.priceDate
        ? getInternalformatDate(cart.header.priceDate)
        : '',
      quoteCreationDate: cart?.header?.quoteCreationDate
        ? getInternalformatDate(cart.header.quoteCreationDate)
        : '',
      quoteExpirationDate: cart?.header?.quoteExpirationDate
        ? getInternalformatDate(cart.header.quoteExpirationDate)
        : '',
      salesId: cart?.header?.salesId ? cart.header.salesId : '',
      salesOriginId: cart?.header?.salesOriginId
        ? cart.header.salesOriginId
        : '',
      salesType: cart?.header?.salesType ? cart.header.salesType : '',
      shortTypeCode: cart?.header?.shortTypeCode
        ? cart.header.shortTypeCode
        : '',
      type: cart?.header?.type ? cart.header.type : 'Credit Memo'
    }

    this.account = {
      invoiceAccount: cart?.account?.invoiceAccount
        ? cart.account.invoiceAccount
        : '',
      salesContact1: cart?.account?.salesContact1
        ? cart.account.salesContact1
        : '',
      salesContact2: cart?.account?.salesContact2
        ? cart.account.salesContact2
        : '',
      customerRequirements: cart?.account?.customerRequirements
        ? cart.account.customerRequirements
        : ''
    }

    this.creditMemoManagement = {
      creditMemoDate: cart?.creditMemoManagement?.creditMemoDate
        ? getInternalformatDate(cart.creditMemoManagement.creditMemoDate)
        : momentDateEST().format(internalFormat),
      creditMemoReason: cart?.creditMemoManagement?.creditMemoReason
        ? cart.creditMemoManagement.creditMemoReason
        : '',
      costCenter: cart?.creditMemoManagement?.costCenter,
      invoiceNumber: cart?.creditMemoManagement?.invoiceNumber
        ? cart.creditMemoManagement.invoiceNumber
        : '',
      notes: cart?.creditMemoManagement?.notes
        ? cart.creditMemoManagement.notes
        : []
    }

    this.payment = {
      PO: cart?.payment?.PO ? cart.payment.PO : '',
      paymentTerms: cart?.payment?.paymentTerms
        ? cart.payment.paymentTerms
        : '',
      debit: cart?.payment?.debit ? cart.payment.debit : false
    }

    this.autoSave = false

    this.id = cart?.header?.cartCode ? cart.header.cartCode : ''

    this.lines = cart?.lines ? cart.lines : []

    this.internalNotes = cart?.internalNotes ? cart.internalNotes : ''

    this.summary = {
      subTotal: cart?.summary?.subTotal ? cart.summary.subTotal : null,
      totalTaxes: cart?.summary?.totalTaxes ? cart.summary.totalTaxes : null,
      totalPrice: cart?.summary?.totalPrice ? cart.summary.totalPrice : null,
      taxCalculationStatus: cart?.summary?.taxCalculationStatus
        ? cart.summary.taxCalculationStatus
        : null
    }

    this.paymentAddress = cart?.paymentAddress ? cart.paymentAddress : {}
    this.deliveryAddress = cart?.deliveryAddress ? cart.deliveryAddress : {}

    this.attachments = cart?.attachments?.length ? cart.attachments : []

    this.validationErrors = cart?.validationErrors || {}
    this.isDisabledProductSuggest = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledQtyInput = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledPromoCell = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.cartType = CART_TYPES.CREDIT_MEMO.currentCart

    this.isDisabledByStatus = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
  }

  updateField(payload, withPatchRequest = true) {
    quotesStore().updateCreditMemoField(payload)
    if (withPatchRequest) return quotesStore().creditMemoFieldChanged()
  }

  setNoteField(payload) {
    quotesStore().setNoteCreditMemoField(payload)
  }

  createDefaultProduct() {
    return new DefaultCreditMemoProduct()
  }

  createNew() {
    return quotesStore().createCreditMemo()
  }

  submitValidationRules() {
    const isCostCenterRequired =
      quotesStore().detailsFormFields.reasonCodes.find((reasonCode) => {
        return reasonCode.code === this.creditMemoManagement.creditMemoReason
      })?.costCenterRequired || false

    return {
      creditMemoManagement: {
        creditMemoDate: {
          required: true
        },
        creditMemoReason: {
          required: true
        },
        costCenter: {
          required: isCostCenterRequired
        },
        invoiceNumber: {
          required: true
        }
      }
    }
  }

  validateSubmitOrderPayload() {
    const validationRules = this.submitValidationRules()
    const sections = ['creditMemoManagement']
    return validateOrderSubmit(sections, this, validationRules)
  }
}
