export default {
  methods: {
    addToInitQueue(name, promise) {
      this.initQueue[name] = promise
    },
    setupInitQueue() {},
    initApplication() {
      this.setupInitQueue()
      return new Promise((resolve, reject) => {
        Promise.all(Object.values(this.initQueue))
          .then(() => {
            resolve()
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    }
  },
  data() {
    return {
      initQueue: {}
    }
  }
}
