// @vue/component
export const ScreenSizeMixin = {
  data() {
    return {
      screenWidth: null
    }
  },
  computed: {
    getScreenWidth() {
      return {
        xs: this.screenWidth <= 576,
        sm: this.screenWidth > 576 && this.screenWidth <= 768,
        fromSm: this.screenWidth > 576,
        md: this.screenWidth > 768 && this.screenWidth <= 1025,
        fromMd: this.screenWidth > 768,
        toMd: this.screenWidth < 768,
        lg: this.screenWidth > 1025 && this.screenWidth <= 1200,
        fromLg: this.screenWidth > 1025,
        toLg: this.screenWidth < 1025,
        xl: this.screenWidth > 1200 && this.screenWidth <= 1400,
        fromXl: this.screenWidth > 1200,
        toXl: this.screenWidth < 1200,
        xxl: this.screenWidth > 1400,
        toXxl: this.screenWidth < 1400
      }
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    init() {
      window.addEventListener('resize', this.updateScreenWidth)
      this.updateScreenWidth()
    },
    destroy() {
      window.removeEventListener('resize', this.updateScreenWidth)
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    }
  }
}
