import Vue from 'vue'

const cloneObject = (source) => {
  return JSON.parse(JSON.stringify(source))
}

const compareObjects = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

const removeEmpty = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)))
}

Vue.cloneObject = Vue.prototype.$cloneObject = cloneObject
Vue.compareObjects = Vue.prototype.$compareObjects = compareObjects

export { cloneObject, compareObjects, removeEmpty }
