<template>
  <ul class="flex flex-col list-none">
    <li
      v-for="{ link, img, text } in socialNetworks"
      :key="link"
      class="p-0 pt-3"
    >
      <a
        :href="link"
        target="_blank"
        class="text-xs leading-5 flex items-center"
      >
        <img :src="img" class="w-auto h-4" />
        <span class="ml-2">{{ text }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'pinia'

import fb from '@/assets/img/facebook.svg'
import ig from '@/assets/img/instagram.svg'
import tw from '@/assets/img/twitter.svg'
import yt from '@/assets/img/youtube.svg'
import { userStore } from '@/stores/user'

export default {
  name: 'SocialNetworks',
  computed: {
    ...mapState(userStore, ['isMexicoRegion']),
    socialNetworks() {
      if (this.isMexicoRegion) {
        return [
          {
            link: 'https://www.facebook.com/MilwaukeeToolLatinoamerica',
            img: fb,
            text: this.$t('footer.socialNetworks.facebook')
          },
          {
            link: 'https://www.instagram.com/milwaukeetool_latam/',
            img: ig,
            text: this.$t('footer.socialNetworks.instagram')
          }
        ]
      }

      return [
        {
          link: 'https://www.facebook.com/MilwaukeeTool',
          img: fb,
          text: this.$t('footer.socialNetworks.facebook')
        },
        {
          link: 'https://www.instagram.com/MilwaukeeTool/',
          img: ig,
          text: this.$t('footer.socialNetworks.instagram')
        },
        {
          link: 'https://twitter.com/milwaukeetool',
          img: tw,
          text: this.$t('footer.socialNetworks.twitter')
        },
        {
          link: 'https://www.youtube.com/c/MilwaukeeTool',
          img: yt,
          text: this.$t('footer.socialNetworks.youtube')
        }
      ]
    }
  }
}
</script>
