<template>
  <div
    class="mt-account-menu__wrapper mt-z-400 flex flex-col absolute top-full right-0 py-5 mt-1.5 bg-white shadow-md rounded-xl lg:fixed lg:left-0 lg:top-0 lg:bottom-0 lg:p-0 lg:pt-5 lg:mt-0 lg:rounded-none"
  >
    <button
      class="hidden lg:block absolute top-2 right-2 w-7 h-7 text-secondary-500 cursor-pointer z-10"
      @click="closeMenu"
    >
      <app-icon icon="close" />
    </button>
    <ul>
      <li>
        <router-link
          v-if="isPersonalInformationPage"
          to="/account/settings"
          class="mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline"
          :class="{
            'mt-account-menu__item-active lg:text-primary-500':
              activeItem === 'account-settings'
          }"
        >
          <span class="mr-2 material-icons-outlined">person</span>
          {{ $t('header.account.personalInfo') }}
        </router-link>
      </li>
      <li v-if="isAccountsPage">
        <router-link
          to="/accounts"
          class="mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline"
          :class="{
            'mt-account-menu__item-active lg:text-primary-500':
              activeItem === 'accounts'
          }"
        >
          <span class="mr-2 material-icons-outlined">group</span>
          {{ $t('header.account.accounts') }}
        </router-link>
      </li>
      <li v-if="showUploadOrders">
        <router-link
          to="/upload-orders"
          class="mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline"
          :class="{
            'mt-account-menu__item-active lg:text-primary-500':
              activeItem === 'uploadOrders'
          }"
        >
          <span class="mr-2 material-icons-outlined">file_upload</span>
          {{ $t('header.uploadOrders') }}
        </router-link>
      </li>
      <li v-if="$settings.feature('addresses.enabled')">
        <router-link
          to="/addresses"
          class="mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline"
          :class="{
            'mt-account-menu__item-active lg:text-primary-500':
              activeItem === 'addresses'
          }"
        >
          <span class="mr-2 material-icons-outlined">location_on</span>
          {{ $t('header.account.addresses') }}
        </router-link>
      </li>
      <li class="my-1 mx-5 h-px bg-gray-50"></li>
      <li>
        <a
          href="#"
          class="mt-account-menu__item flex items-center relative py-2 px-5 hover:no-underline focus:no-underline text-gray-400"
          @click.prevent="logout"
        >
          <span class="mr-2 material-icons-outlined">logout</span>
          {{ $t('header.account.logout') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import auth from '@/lib/core/auth'

import AppIcon from '@/components/common/AppIcon'

import { rolesStore } from '@/stores/roles'

export default {
  name: 'AccountMenu',
  components: {
    AppIcon
  },
  computed: {
    ...mapState(rolesStore, [
      'isAnyUpload',
      'isAccountsPage',
      'isPersonalInformationPage'
    ]),
    activeItem() {
      return this.$route.name
    },
    showUploadOrders() {
      return this.isAnyUpload
    }
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu')
    },
    logout() {
      auth.logout()
    }
  }
}
</script>

<style scoped lang="scss">
.mt-account-menu {
  &__wrapper {
    width: 305px;
    box-shadow: 0 3px 8px rgba(203, 208, 214, 0.55);

    &:before {
      @apply absolute right-4 bottom-full;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent var(--color-white) transparent;
      content: '';
    }

    @screen lg {
      &:before {
        @apply hidden;
      }
    }
  }

  &__item {
    &-active {
      ::before {
        @screen lg {
          @apply absolute left-0 top-0 bottom-0 w-1 bg-primary-500;
          content: '';
        }
      }
    }
  }
}
</style>

