import moment from 'moment-timezone'

import { userStore } from '@/stores/user'

export const serverTimezone = 'America/New_York' // EST
/** client side internal date format */
export const internalFormat = 'MM/DD/YYYY'

export function getDateFormat() {
  return userStore().getConfig('dateFormat')
}

export default function formatDate({ date, hasTime = false }) {
  const dateFormat = getDateFormat()
  if (!date) {
    // if the date is new that create a new date in serverTimezone
    return momentDateEST().format(dateFormat)
  }
  if (hasTime) {
    return moment(new Date(date)).format(`${dateFormat} hh:mm A`)
  }
  return moment(new Date(date)).format(dateFormat)
}

/**
 * @param {string} [dateString], optional, should be formatted in 'MM/DD/YYYY'/internalFormat format
 * or provide format to be recognized
 * @param {string} [formatToRecognize], optional, provide format to be recognized
 * if string is formatted not as 'MM/DD/YYYY'/internalFormat
 * @returns {Object} moment date object
 */
export function momentDateEST(dateString, formatToRecognize = internalFormat) {
  let result
  if (dateString) {
    // consider dateString is already in a serverTimezone time
    result = moment.tz(dateString, formatToRecognize, serverTimezone)
  } else {
    // create a new moment date in serverTimezone time now
    result = moment().tz(serverTimezone)
  }
  return result
}

export function localToServerDateWithoutShift(dateJs) {
  const removeLocalTimezone = dateJs.toLocaleString('en-CA', {
    dateStyle: 'short',
    timeStyle: 'short',
    hourCycle: 'h23'
  })
  const result = moment.tz(
    removeLocalTimezone,
    'YYYY-MM-DD, HH:mm',
    serverTimezone
  )
  return result
}

export function disabledDates(date, pricingSuperUser) {
  const currentDate = momentDateEST()
  const previousDay = momentDateEST().subtract(1, 'days')
  const lastAvailableDay = currentDate.add(30, 'days')
  const dateToServerTimezone = localToServerDateWithoutShift(date)
  if (pricingSuperUser) {
    return dateToServerTimezone > lastAvailableDay
  } else {
    return (
      dateToServerTimezone < previousDay ||
      dateToServerTimezone > lastAvailableDay
    )
  }
}

export function disabledFutureDates(date, createdCartDate, isPricingSuperUser) {
  const createdCartDateMomentObject = momentDateEST(createdCartDate).subtract(
    30,
    'days'
  )

  const lastAvailableDay = momentDateEST(createdCartDate).add(30, 'days')
  const dateToServerTimezone = localToServerDateWithoutShift(date)
  if (isPricingSuperUser) {
    return dateToServerTimezone > lastAvailableDay
  } else {
    return (
      dateToServerTimezone < createdCartDateMomentObject ||
      dateToServerTimezone > lastAvailableDay
    )
  }
}

export function getInternalformatDate(date) {
  return moment(new Date(date)).format(internalFormat)
}
