<template>
  <dropdown positioning="bottom right" @isOpen="addNewDropdownToggle">
    <template #trigger>
      <button
        v-if="currentLocale"
        class="mr-5 relative bg-white rounded-lg w-11 h-11 cursor-pointer"
      >
        {{ currentLocale.lang }}
      </button>
    </template>
    <div class="p-2 grid col-span-1 gap-2">
      <app-button
        v-for="item in locales"
        :key="`${item.id}`"
        @click="setLocale(`${item.id}`)"
      >
        {{ item.lang }}
      </app-button>
    </div>
  </dropdown>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'

import AppButton from '@/components/common/AppButton'

import Dropdown from '@/components/b2b/common/dropdown/Dropdown.vue'

import { langStore } from '@/stores/lang'

export default {
  name: 'LanguageSelectDropdown',
  components: {
    Dropdown,
    AppButton
  },
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapState(langStore, ['localeId']),
    currentLocale() {
      const lang = this.localeId || this.$settings.i18n.defaultLocale
      return _.find(this.$settings.i18n.locales, (i) => i.id === lang)
    },
    locales() {
      return _.filter(this.$settings.i18n.locales, (i) => i)
    }
  },
  methods: {
    ...mapActions(langStore, ['setLang']),
    addNewDropdownToggle(isOpen) {
      this.isModalOpen = isOpen
    },
    setLocale(locale) {
      this.setLang(locale)
    }
  }
}
</script>
