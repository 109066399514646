import throttle from 'lodash/throttle'

import settings from '@/lib/core/settings'
import { eventBus } from '@/lib/utils/bus'

import routes from '@/router/routes'

export default {
  beforeDestroy() {
    this.resetInactivityTimer()
    this.resetAutoLogoutTimer()
    eventBus.$off('idle.restart')
  },
  mounted() {
    this.init()
  },
  methods: {
    /**
     * Initialize idle.
     */
    init() {
      if (!this.isIdleEnabled) {
        return
      }
      this.startInactivityTimer()

      const events = ['keydown', 'mousemove', 'touchstart', 'touchend']
      for (const i in events) {
        document.removeEventListener([i], this.restartTimer)
        document.addEventListener(events[i], this.restartTimer)
      }

      // Handle global events

      const vm = this
      eventBus.$on('idle.restart', () => {
        vm.restartTimer(true)
      })
      eventBus.$on('route.after', (data) => {
        this.resetAutoLogoutTimer()
        this.resetInactivityTimer()
        this.startInactivityTimer()
      })
    },

    /**
     * Start auto logout timer.
     */
    startAutoLogoutTimer() {
      this.secondsLeft = settings.app.idle.autoCancelPeriod
      const vm = this
      this.inactivityTimer = setInterval(() => {
        vm.secondsLeft--
        if (vm.secondsLeft <= 0) {
          vm.cancel()
        }
      }, 1000)
    },

    /**
     * Cancel idle screen.
     */
    cancel() {
      this.resetInactivityTimer()
      this.resetAutoLogoutTimer()
      this.showIdleScreen = false
      eventBus.$emit('idle.autoLogout')
    },

    /**
     * Start inactivity timer.
     */
    startInactivityTimer() {
      const currentRoute = routes.find((item) => item.name === this.$route.name)
      const skipIdle =
        currentRoute && 'skipIdle' in currentRoute && currentRoute.skipIdle

      // Don't start the timer for routes that have 'skipIdle' on.
      if (!currentRoute || skipIdle) {
        return
      }

      const vm = this
      this.inactivityTimer = setTimeout(() => {
        vm.timeElapsed()
      }, settings.app.idle.inactivityPeriod * 1000)
    },

    /**
     * Inactive time has elapsed.
     */
    timeElapsed() {
      this.showIdleScreen = true
      this.startAutoLogoutTimer()
    },

    /**
     * Throttled restart timer method.
     */
    restartTimer: throttle(function (force = false) {
      force = force && typeof force === 'boolean'
      if (!this.showIdleScreen || force) {
        this.resetInactivityTimer()
        this.startInactivityTimer()
      }
    }, 100),

    /**
     * Reset auto logout timer.
     */
    resetAutoLogoutTimer() {
      if (this.autoLogoutTimer) {
        clearInterval(this.autoLogoutTimer)
      }
    },

    /**
     * Reset inavtivity timer.
     */
    resetInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer)
      }
      this.showIdleScreen = false
    }
  },
  computed: {
    isIdleEnabled() {
      return settings.app.idle.enabled
    }
  },
  data() {
    return {
      secondsLeft: 0,
      inactivityTimer: 0,
      autoLogoutTimer: 0,
      showIdleScreen: false
    }
  }
}
