<template>
  <span class="cart-remove-button">
    <loader v-if="processing" inline :size="16"></loader>
    <a
      v-else
      href="#"
      class="remove-btn"
      :class="iconClassName"
      :title="$t('cart.remove')"
      @click.prevent="remove"
    >
      <span>{{ $t('cart.remove') }}</span>
    </a>
  </span>
</template>
<script>
import { mapActions } from 'pinia'

import { cartStore } from '@/stores/cart'

export default {
  name: 'RemoveButton',
  props: {
    cart: {
      type: Object,
      required: true
    },
    iconClassName: {
      type: String,
      default: 'icon-trash'
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false
    }
  },
  methods: {
    ...mapActions(cartStore, ['removeItem']),
    remove() {
      this.processing = true
      this.$emit('removing')
      this.removeItem({
        cart: this.cart,
        item: this.item
      })
        .then(() => {
          this.$emit('removed')
        })
        .catch(() => {
          this.$emit('removed')
          this.processing = false
        })
    }
  }
}
</script>
