<template src="@/templates/b2b/cart/SelectCart/SelectCartDialog"></template>
<script>
import { i18n } from '@root/i18n'
import { mapActions, mapState } from 'pinia'

import { Loader } from '@/lib/utils/loader'

import ContentLightbox from '@/components/common/ContentLightbox'

import { cartStore } from '@/stores/cart'

export default {
  name: 'SelectCart',
  components: { ContentLightbox },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(cartStore, ['carts'])
  },
  methods: {
    ...mapActions(cartStore, ['refreshCarts']),
    t(...args) {
      return i18n.t(...args)
    },
    createCart() {
      Loader.start()
      this.$api.cart
        .createCart()
        .then(this.refreshCarts)
        .then(Loader.stop)
        .catch((reason) => {
          this.$toasted.error(reason.message)
          Loader.stop()
        })
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    selectCart(cart) {
      this.$emit('selected', cart.id)
    }
  }
}
</script>
