import { i18n } from '@root/i18n'
import get from 'lodash/get'

import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Billing extends ApiBase {
  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getBaseUrl() {
    const userId = auth.getCurrentUserId()
    return `/rest/api/v1/users/${userId}`
  }

  /**
   * Get payment methods for the given cart.
   * @param cart
   * @returns {Promise<any>}
   */
  getPaymentMethods(cart) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/payment-methods/${userId}/${cart.id}`
    )

    return new Promise((resolve, reject) => {
      this.transport
        .get(endpoint)
        .then((response) => response.data)
        .then((methods) => this.formatPaymentMethods(methods))
        .then((methods) => {
          resolve(methods)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Place an order.
   * @param cart
   * @returns {Promise<any>}
   */
  placeOrder(cart) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/place-order/${userId}/${cart.id}`
    )

    return new Promise((resolve, reject) => {
      this.transport
        .post(endpoint)
        .then((response) => {
          const orderId = get(response, 'data.code', 0)
          resolve(orderId)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Get billing addresses.
   * @returns {Promise<any>}
   */
  getBillingAddresses() {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/checkout/billing-addresses/${userId}`)
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          if (!('data' in response) || !response.data) {
            resolve([])
          } else {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Format data for payment methods.
   * @param methods
   * @returns {*}
   */
  formatPaymentMethods(methods) {
    if (!methods || !methods.length) {
      return []
    }

    methods.forEach((method) => {
      if (!method.displayName) {
        const translationKey = `billing.paymentMethod.${method.code}`
        if (i18n.te(translationKey)) {
          method.displayName = i18n.t(translationKey)
        } else {
          method.displayName = method.code
        }
      }
    })
    return methods
  }

  /**
   * Add billing address
   * @param address
   */
  addAddress(address) {
    return this.transport.post(`${this.getBaseUrl()}/addresses`, address)
  }

  /**
   * Set payment details to the given cart.
   * @param cart
   * @param paymentType
   * @param data
   * @param address
   * @returns {*|AxiosPromise<any>|void}
   */
  setPaymentDetails(cart, address, paymentType, data) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/payment-details/${userId}/${cart.id}`
    )
    const postData = {
      paymentType,
      ...data,
      billingAddress: {
        id: address.id
      }
    }
    return this.transport.post(endpoint, postData)
  }
}

export default Billing
