import settings from '@/lib/core/settings'

const addGoogleApiKey = () => {
  if (
    !('google' in settings.services) ||
    !('mapsApiKey' in settings.services.google) ||
    !settings.services.google.mapsApiKey.length
  ) {
    return
  }

  const src = `https://maps.googleapis.com/maps/api/js?key=${settings.services.google.mapsApiKey}&libraries=places`
  const scriptElement = document.createElement('script')
  scriptElement.src = src

  document.head.appendChild(scriptElement)
}

if (settings.services.google.mapsApiKey) {
  addGoogleApiKey()
}
