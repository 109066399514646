import { defineStore } from 'pinia'

import findCartByParam from '@/lib/utils/findCartByParam'

import { quotesStore } from '@/stores/quotes'

class CurrentCart {
  getters = {
    currentCart: (state) => {
      const quotes = quotesStore()
      const foundCart =
        findCartByParam('routeName', state.router.app._route.name) || null
      const currentCartObj = foundCart ? quotes[foundCart.currentCart] : null
      if (foundCart && currentCartObj) {
        if (currentCartObj instanceof foundCart.model) {
          return currentCartObj
        } else {
          // otherwise `currentCartObj` is a JSON.parse result w/o any methods and it should be wrapped into class
          const wrapObjectWithClassMethods = new foundCart.model(currentCartObj)
          quotes[foundCart.currentCart] = wrapObjectWithClassMethods // update the old currentQuote/currentReturn to be the same obj
          const quoteIndex = quotes.currentQuotes.findIndex(
            (quote) => quote.id === wrapObjectWithClassMethods.id
          )
          if (quoteIndex)
            quotes.currentQuotes[quoteIndex] = wrapObjectWithClassMethods // update the old currentQuotes to include the same obj
          return wrapObjectWithClassMethods
        }
      } else {
        return null
      }
    },
    currentCartType: (state) => {
      return state.currentCart?.header?.type
    },
    currentCartAccountId: (state) => {
      return state.currentCart?.header?.customerAccount
    },
    currentCartProducts: (state) => state.currentCart?.lines,
    currentCartProductsQty: (state) => state.currentCartProducts?.length,
    currentCartSelectedProducts: (state) =>
      state.currentCartProducts?.filter((product) => product.isSelected),
    currentCartSelectedProductsQty: (state) =>
      state.currentCartSelectedProducts?.length
  }
}

export const currentCartStore = defineStore('currentCart', {
  persist: true,
  ...new CurrentCart()
})
