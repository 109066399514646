import BaseProducts from '@/lib/api/v1/products'

class Products extends BaseProducts {
  getProduct(productId) {
    return this.transport.get(
      `/rest/api/v3/products/${productId}`,
      { productId },
      { locale: '' }
    )
  }

  getProducts(currentPage, pageSize = 10, query = '', sort = 'name-asc') {
    return this.transport.get('/rest/api/v3/products/search', null, {
      locale: '',
      requestOptions: {
        params: {
          currentPage,
          pageSize,
          query,
          sort,
          fields: 'FULL'
        }
      }
    })
  }

  getAvailability({ customerId, itemData, cartId }) {
    return this.transport.post(
      `/rest/api/v3/products/availability`,
      {
        customerId,
        cartId,
        itemData
      },
      {
        locale: ''
      }
    )
  }
}

export default Products
