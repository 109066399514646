import { LoginCredentials } from 'zcmodels'

import ApiBase from '@/lib/api/v1/api-base'

class Auth extends ApiBase {
  /**
   * Initialize a new instance of the Auth class.
   * @param transport
   * @param apiKey
   */
  constructor(transport, apiKey) {
    super(transport)
    this.apiKey = apiKey
  }

  /**
   * Authorize user and obtain refresh and access tokens.
   * @param username
   * @param password
   * @returns {*|AxiosPromise<any>|void}
   */
  login(username, password) {
    const credentials = new LoginCredentials({
      username,
      password
    })
    if (!credentials.isValid()) {
      throw new Error('Credentials are invalid!')
    }
    return this.transport.post(this.getEndpointUrl('/user/login'), credentials)
  }

  /**
   * Perform a logout request.
   * @returns {*}
   */
  logout() {
    return this.transport.post(this.getEndpointUrl('/user/logout'))
  }

  /**
   * Refresh auth token.
   * @param refreshToken
   * @returns {*|void|AxiosPromise<any>}
   */
  refreshAuthToken(refreshToken) {
    return this.transport.post(this.getEndpointUrl('/user/refresh-token'), {
      refreshToken
    })
  }
}

export default Auth
