import { eventBus } from '@/lib/utils/bus'

const disableKeys = function (event) {
  event.preventDefault()
  return false
}

const ModalLoader = {
  start() {
    window.addEventListener('keydown', disableKeys)
    eventBus.$emit('modalLoader.start')
  },
  stop() {
    eventBus.$emit('modalLoader.stop')
    window.removeEventListener('keydown', disableKeys)
  }
}

const Loader = {
  start() {
    eventBus.$emit('loader.start')
  },
  stop() {
    eventBus.$emit('loader.stop')
  }
}

export { ModalLoader, Loader }
