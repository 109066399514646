import ApiBase from '@/lib/api/v1/api-base'

const primaryImageType = 'PRIMARY'
const galleryImageType = 'GALLERY'

class Products extends ApiBase {
  /**
   * Get product by its ID.
   * @mocked
   * @param id
   * @returns {Promise<any>}
   */
  getProduct(id) {
    const url = this.getEndpointUrl(`/products/${id}`)
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => response.data)
        .then((product) => {
          return this.prepareProductModel(product, true)
        })
        .then((product) => {
          resolve(product)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Get Products.
   * @param start
   * @param limit
   * @returns {Promise<any>}
   */
  getProducts(start, limit) {
    const url = this.getEndpointUrl(`/products?start=${start}&limit=${limit}`)
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => response.data)
        .then((products) => {
          products.items.forEach((product, i) => {
            products.items[i] = this.prepareProductModel(product, false)
          })
          return products
        })
        .then((products) => {
          resolve(products)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Prepare products for output (fill with data that doesn't come from API).
   * @param products
   * @returns {*}
   */
  prepareProducts(products) {
    if (!products || !products.length) {
      return products
    }
    products.forEach((product, i, arr) => {
      arr[i] = this.prepareProductModel(product)
    })
    return products
  }

  /**
   * Prepare product.
   * @param product
   */
  prepareProductModel(product, isSingle = false) {
    if (!('code' in product)) {
      return product
    }

    // Make sure the product has sku and ID attributes.
    if (!('sku' in product)) {
      product.sku = product.code
    }
    if (!('id' in product)) {
      product.id = product.sku
    }

    product = this.selectPrimaryImage(product, isSingle)
    return product
  }

  /**
   * Select product's primary image (if not set).
   * @param product
   * @param isSingle
   */
  selectPrimaryImage(product, isSingle = false) {
    if (!product.images || !product.images.length) {
      return product
    }

    if (isSingle) {
      product.images = product.images.filter(
        (image) => image.imageType === galleryImageType
      )
      if (product.images.length > 0) {
        product.image = product.images[0]
      }
    } else {
      if ('image' in product) {
        return product.image
      }
      product.images.forEach((image) => {
        if (image.imageType === primaryImageType) {
          product.image = image
        }
      })
    }
    return product
  }
}

export default Products
