import { eventBus } from '@/lib/utils/bus'
import routes from '@/lib/utils/routes'

export default {
  watch: {
    $route() {
      this.$nextTick(() => {
        this.buildPath()
      })
    }
  },
  computed: {
    rootRoute() {
      return routes.getRoot()
    },
    homeTitle() {
      const route = this.rootRoute
      if (!route) {
        return this.$t('common.home')
      }
      return this.getCaptionByRouteName(route.name)
    }
  },
  mounted() {
    this.buildPath()
  },
  methods: {
    /**
     * Get caption by route name.
     * @param routeName
     * @returns {*}
     */
    getCaptionByRouteName(routeName) {
      const translationKey = `routes.${routeName}`

      // Check to avoid warning in console.
      if (this.$te(translationKey)) {
        return this.$t(translationKey)
      }
      return routeName
    },

    /**
     * Get path based on the current location.
     * @returns {Array}
     */
    getPath() {
      const path = []

      const addItem = (start) => {
        if (start) {
          path.unshift({
            name: this.getCaptionByRouteName(start.name),
            link: start.path
          })
          if ('parent' in start) {
            addItem(routes.find(start.parent))
          }
        }
      }

      // Build path only if current route is not root one.
      if (!routes.isRoot(this.$route.name)) {
        addItem(routes.find(this.$route.name))
      }
      return path
    },

    /**
     * Apply path.
     */
    buildPath() {
      const path = this.getPath()
      this.path = path

      eventBus.$emit('breadcrumbs.buildPath', {
        path,
        return: (path) => {
          this.path = path
        }
      })
    }
  }
}
