import { defineStore } from 'pinia'

import api from '@/lib/api'
import { Invoice } from '@/lib/models/invoice/invoice'

const state = {
  paginationData: {},
  currentInvoices: []
}

const actions = {
  transformInvoice(invoice) {
    return new Invoice(invoice)
  },

  async transformInvoices(invoices) {
    return await Promise.all(
      invoices.map(async (invoice) => await this.transformInvoice(invoice))
    )
  },

  async getInvoices({ searchData = {}, currentPage, pageSize, asc, code }) {
    try {
      const res = await api.invoices.getInvoices(
        searchData,
        currentPage,
        pageSize,
        asc,
        code
      )

      if (res.data?.httpCode) {
        throw new Error(res.data?.errorMsg)
      }

      const transformedInvoices = await this.transformInvoices(
        res.data.invoices
      )
      this.currentInvoices = transformedInvoices
      this.paginationData = res.data.pagination

      return res.data
    } catch (_error) {
      this.currentInvoices = []
      this.paginationData = {}
    }
  },
  searchInvoices({ term = '', currentPage, pageSize, asc, code }) {
    return api.invoices
      .getInvoices(
        {
          term
        },
        currentPage,
        pageSize,
        asc,
        code
      )
      .then((res) => {
        return res.data
      })
  },
  downloadInvoicesXLSX({ searchData = {}, currentPage, pageSize, asc, code }) {
    return api.invoices.downloadInvoicesXLSX(
      searchData,
      currentPage,
      pageSize,
      asc,
      code
    )
  },
  downloadInvoice({
    invoiceNumber,
    transactionSource,
    customerTransactionId,
    businessUnit
  }) {
    return api.invoices.downloadInvoice(
      invoiceNumber,
      transactionSource,
      customerTransactionId,
      businessUnit
    )
  },
  sendInvoices({ invoices, to, businessUnit }) {
    return api.invoices.sendInvoices(invoices, to, businessUnit)
  }
}

export const invoicesStore = defineStore('invoices', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions
})
