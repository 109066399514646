<template>
  <nav class="mt-navigation-desktop block lg:hidden">
    <ul class="flex">
      <li v-for="link in links" :key="link.id">
        <router-link
          :to="link.path"
          class="flex items-center p-3 text-base text-white hover:no-underline focus-within:no-underline"
        >
          {{ link.title }}
        </router-link>
      </li>
      <slot name="menu-items"></slot>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'NavigationDesktop',
  props: {
    links: {
      type: Array,
      default: () => []
    }
  }
}
</script>
