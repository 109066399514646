import BaseCustomers from '@/lib/api/v1/customers'
import string from '@/lib/utils/applyWildCards'

class Customers extends BaseCustomers {
  getOtherAccounts({ page, query }) {
    return this.transport.get(
      `/rest/api/v3/b2bunits/other?page=${page}&count=9&query=${query}`,
      null,
      { locale: '' }
    )
  }

  getFavoriteAccounts({
    query = string.applyWildCards(''),
    currentPage = 0,
    pageSize = 10
  }) {
    return this.transport.get(
      `/rest/api/v3/b2bunits/favourite?query=${query}&currentPage=${currentPage}&pageSize=${pageSize}`,
      null,
      { locale: '' }
    )
  }

  addFavoriteAccount(accountId) {
    return this.transport.post(
      `/rest/api/v3/b2bunits/favourite?favouriteUserId=${accountId}`,
      undefined,
      { locale: '' }
    )
  }

  removeFavoriteAccount(accountId) {
    return this.transport.delete(
      `/rest/api/v3/b2bunits/favourite/${accountId}`,
      undefined,
      { locale: '' }
    )
  }

  searchCustomers(term, currentPage = 0, pageSize = 10) {
    return new Promise((resolve, reject) => {
      this.transport
        .get(
          `/rest/api/v3/b2bunits/search?query=${encodeURIComponent(term)}`,
          null,
          {
            locale: '',
            requestOptions: {
              params: {
                currentPage,
                pageSize
              }
            }
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  getCustomers({
    searchData,
    currentPage = 0,
    pageSize = 10,
    asc = false,
    code = '',
    hierarchy = false
  }) {
    return this.transport.get('/rest/api/v3/b2bunits/search', null, {
      locale: '',
      requestOptions: {
        params: {
          ...searchData,
          asc,
          code,
          currentPage,
          pageSize,
          hierarchy
        }
      }
    })
  }

  advancedSearchFavorites(searchData, currentPage = 0, pageSize = 10) {
    return this.transport.get(
      `/rest/api/v3/b2bunits/favourite?currentPage=${currentPage}&pageSize=${pageSize}`,
      null,
      { locale: '', requestOptions: { params: searchData } }
    )
  }

  getRegions(countryCode) {
    return this.transport.get(
      `/rest/api/v3/carts/countries/${countryCode}/regions`,
      null,
      { locale: '' }
    )
  }

  getCurrencies() {
    return this.transport.get('/rest/api/v3/b2bunits/currencies', null, {
      locale: ''
    })
  }

  getLookupTableValue(key) {
    return this.transport.get(`/rest/api/v3/lookuptable/${key}`, null, {
      locale: ''
    })
  }
}

export default Customers
