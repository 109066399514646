import { getUniqueId } from '@/lib/utils/helpers'

export class ReturnProduct {
  constructor(entry) {
    this.discontinued = entry.discontinued || false
    this.entryNumber = entry.entryNumber
    this.errorCode = entry.errorCode
    this.errorMessage = entry.errorMessage
    this.hierarchy = entry.hierarchy
    this.isSelected = entry.isSelected || false
    this.itemNumber = entry.itemNumber || ''
    this.lineRecId = entry.lineRecId || ''
    this.netPrice = entry.netPrice || null
    this.orderType = entry.orderType || 'Return'
    this.overrideBy = entry.overrideBy
    this.highestQtyAllowed = entry.highestQtyAllowed
    this.lowestQtyAllowed = entry.lowestQtyAllowed
    this.multipleQty = entry.multipleQty
    this.quantity = entry.quantity || 0
    this.pceCode = entry.pceCode || ''
    this.salesOrderReference = entry.salesOrderReference || null
    this.invoiceId = entry.invoiceId || null
    this.referenceSalesOrderLineId = entry.referenceSalesOrderLineId || null
    this.standardValue = entry.standardValue || null
    this.totalPrice = entry.totalPrice || null
    this.type = entry.type
  }

  getRequestPayload() {
    return {
      hierarchy: this.hierarchy,
      itemNumber: this.itemNumber,
      quantity: this.quantity,
      pceCode: this.pceCode,
      netPrice: this.netPrice,
      salesOrderReference: this.salesOrderReference,
      invoiceId: this.invoiceId,
      referenceSalesOrderLineId: this.referenceSalesOrderLineId,
      lineRecId: getUniqueId()
    }
  }
}
