<template src="@/templates/b2b/App"></template>
<script>
import { mapActions, mapState } from 'pinia'

import { eventBus } from '@/lib/utils/bus'
import routes from '@/lib/utils/routes'

import { DisplayToastMixin } from '@/mixins/DisplayToast.mixin.js'

import Idle from '@/components/common/Idle'

import App from '@/components/b2b/App'
import router from '@/router'
import { rolesStore } from '@/stores/roles'
import { uploadOrdersStore } from '@/stores/upload-orders'
import { userStore } from '@/stores/user'

export default {
  name: 'App',
  components: { Idle },
  extends: App,
  mixins: [DisplayToastMixin],
  data() {
    return {
      layoutVisibleRoutes: [
        'product',
        'carts',
        'quote-form',
        'accounts',
        'account-settings'
      ]
    }
  },
  computed: {
    ...mapState(uploadOrdersStore, ['pendingUploads']),
    isSimpleView() {
      return routes.isSimpleView(this.$route.name)
    },
    canShowHeader() {
      return (
        ((this.isLoggedIn && !routes.isPublic(this.$route.name)) ||
          this.layoutVisibleRoutes.includes(this.$route.name)) &&
        !this.isSimpleView
      )
    },
    canShowFooter() {
      return (
        ((this.isLoggedIn && !routes.isFatalError(this.$route.name)) ||
          this.layoutVisibleRoutes.includes(this.$route.name)) &&
        !this.isSimpleView
      )
    }
  },
  watch: {
    pendingUploads: {
      handler(val) {
        if (val.length) {
          this.getUploadOrderHistoryStatuses({
            fileUids: val,
            errorMessage: this.$t('order.upload.errorMessage')
          })
        }
      },
      deep: true
    }
  },
  created() {
    eventBus.$on('auth.login', async () => {
      await this.getUser()
      this.SET_USER_ERROR('')
    })
    eventBus.$on('auth.authorizedError', (payload) => {
      this.SET_USER_ERROR(payload?.response?.data?.message)
      router.push({ name: 'login-fail' })
    })
    eventBus.$on('auth.logout', () => {
      this.resetImitationCustomer()
      this.resetRoles()
    })

    eventBus.$on('transport.apiError500', (res) => {
      this.showErrorToast(res?.data?.message || '')
    })
  },
  methods: {
    ...mapActions(userStore, [
      'resetImitationCustomer',
      'getUser',
      'SET_USER_ERROR'
    ]),
    ...mapActions(uploadOrdersStore, ['getUploadOrderHistoryStatuses']),
    ...mapActions(rolesStore, ['resetRoles'])
  }
}
</script>
