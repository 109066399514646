import BaseUser from '@/lib/api/v1/user.js'
import Auth from '@/lib/core/auth'

class User extends BaseUser {
  setImitationCustomer(userId) {
    return this.transport.put(
      `/rest/api/v3/b2bunits/virtual?virtualUserId=${userId}`,
      undefined,
      { locale: '' }
    )
  }

  getImitationCustomer() {
    return this.transport.get('/rest/api/v3/b2bunits/virtual', null, {
      locale: ''
    })
  }

  getSimpleWidgetData(
    widgetType,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  ) {
    return this.transport.get(`/rest/api/v3/widgets/${widgetType}`, null, {
      locale: '',
      requestOptions: {
        params: {
          employee,
          customerAccount,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode
        }
      }
    })
  }

  getDateToDateQuotes(
    code,
    dateRange,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  ) {
    return this.transport.get(`/rest/api/v3/widgets/${code}`, null, {
      locale: '',
      requestOptions: {
        params: {
          dateRange,
          employee,
          customerAccount,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode
        }
      }
    })
  }

  getCartTypeKPI(
    code,
    cartType,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  ) {
    return this.transport.get(`/rest/api/v3/widgets/${code}`, null, {
      locale: '',
      requestOptions: {
        params: {
          cartType,
          employee,
          customerAccount,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode
        }
      }
    })
  }

  getBasePromoPercentWidgetData(
    widgetSubtype,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  ) {
    return this.transport.get('/rest/api/v3/widgets/BASE_PROMO_PERCENT', null, {
      locale: '',
      requestOptions: {
        params: {
          subtype: widgetSubtype,
          employee,
          customerAccount,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode
        }
      }
    })
  }

  getUser() {
    return this.transport.get('/rest/api/v3/me', null, {
      headers: {
        authorization: `Bearer ${Auth.auth?.idToken}`
      },
      locale: ''
    })
  }

  getMetrics() {
    return this.transport.get('/rest/api/v3/widgets/get', null, { locale: '' })
  }

  getAvailableMetricsList() {
    return this.transport.get('/rest/api/v3/widgets', null, { locale: '' })
  }

  addKPIWidget(widgetType) {
    return this.transport.post(
      `/rest/api/v3/widgets?type=${widgetType}`,
      undefined,
      { locale: '' }
    )
  }

  deleteKPIWidget(widgetType) {
    return this.transport.delete(
      `/rest/api/v3/widgets/${widgetType}`,
      undefined,
      { locale: '' }
    )
  }

  getUsersList(currentPage, pageSize = 10) {
    return this.transport.get('/rest/api/v3/widgets/favouriteEmployee', null, {
      locale: '',
      requestOptions: {
        params: {
          currentPage,
          pageSize
        }
      }
    })
  }

  getPlFiltersList(bu = '', pl1 = '', pl2 = '', pl3 = '') {
    return this.transport.get('/rest/api/v3/widgets/filters', null, {
      locale: '',
      requestOptions: {
        params: {
          bu,
          pl1,
          pl2,
          pl3
        }
      }
    })
  }

  getConsents() {
    return this.transport.get('/rest/api/v3/consents/get', null, { locale: '' })
  }

  downloadFile(payload) {
    return this.transport.post('/rest/api/v3/common/downloadFile', payload, {
      locale: '',
      requestOptions: { responseType: 'blob' }
    })
  }

  approveConsents(payload) {
    return this.transport.post('/rest/api/v3/consents/edit', payload, {
      locale: ''
    })
  }

  continueWithOutApprovedConsents() {
    return this.transport.post(
      '/rest/api/v3/consents/continue',
      {},
      {
        locale: ''
      }
    )
  }
}

export default User
