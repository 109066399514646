'use strict'
import { TweenMax, Power4 } from 'gsap'

export default {
  mode: 'out-in',
  enter: function (el, done) {
    const overlay = el.querySelector('.overlay')
    const cart = el.querySelector('.cart-section')
    if (overlay) {
      TweenMax.fromTo(
        overlay,
        0.2,
        { opacity: 0 },
        {
          opacity: 1
        }
      )
    }
    if (cart) {
      TweenMax.fromTo(
        cart,
        0.35,
        { right: -400 },
        {
          right: 0,
          ease: Power4.easeInOut,
          onComplete: function () {
            done()
          }
        }
      )
    }
  },
  leave: function (el, done) {
    const overlay = el.querySelector('.overlay')
    const cart = el.querySelector('.cart-section')
    if (overlay) {
      TweenMax.fromTo(
        overlay,
        0.3,
        { opacity: 1 },
        {
          ease: Power4.easeIn,
          opacity: 0
        }
      )
    }
    if (cart) {
      TweenMax.fromTo(
        cart,
        0.3,
        {
          right: 0
        },
        {
          right: -400,
          ease: Power4.easeIn,
          onComplete: function () {
            done()
          }
        }
      )
    }
  }
}
