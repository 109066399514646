import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'
import { appEnv } from '@extension/lib/utils/environment'
import jwtDecode from 'jwt-decode'
import Vue from 'vue'

import coreAuth from '@/lib/core/auth'
import Auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'
import Auth0Api from '@/lib/services/auth0/api'
import { eventBus } from '@/lib/utils/bus'

import router from '@/router/index'

let instance

export const getInstance = () => instance
const convertToAuthData = (user, accessToken, idToken) => {
  const jwt = jwtDecode(accessToken)
  const now = Math.floor(Date.now() / 1000)
  return {
    username: user.email,
    accessToken,
    idToken,
    loggedIn: now,
    expiresIn: jwt.exp,
    jwt,
    accountDetails: {
      ...user,
      id: user.email,
      firstName: user.given_name,
      lastName: user.family_name,
      title: {
        code: '',
        name: ''
      },
      companyName: ''
    }
  }
}

const DEFAULT_REDIRECT_CALLBACK = async (appState, auth) => {
  if (appState && appState.targetUrl) {
    window.history.replaceState({}, document.title, `/#${appState.targetUrl}`)
  } else {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  await auth.updateUserDetails()
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        isReady: false,
        isReadyResolver: null
      }
    },
    watch: {
      isReady(value) {
        if (value && this.isReadyResolver) {
          this.isReadyResolver()
        }
      }
    },
    async created() {
      const isLocalEnv = appEnv === 'LOCAL'

      const options = {
        audience:
          settings.app.extensionEnvVariables.ZCOMMERCE_AUTH0_MILWAUKEE_AUDIENCE,
        domain:
          settings.app.extensionEnvVariables.ZCOMMERCE_AUTH0_MILWAUKEE_DOMAIN,
        client_id:
          settings.app.extensionEnvVariables.ZCOMMERCE_AUTH0_MILWAUKEE_ID,
        redirect_uri: redirectUri,
        cacheLocation: 'localstorage',
        authorizeTimeoutInSeconds: isLocalEnv ? 5 : 60 // 60 is a default
      }

      try {
        this.auth0Client = await createAuth0Client(options)
      } catch (e) {
        if (isLocalEnv && e?.message === 'Timeout') {
          // this is only for the localhost timeout error as a fallback wihout refreshing token as far as we can't refresh token from the localhost
          this.auth0Client = new Auth0Client(options)
        } else {
          throw e
        }
      }
      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          onRedirectCallback(appState, this)
          this.error = null
        }
      } catch (e) {
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.loading = false
        this.isReady = true
      }
    },
    methods: {
      whenReady(resolver) {
        if (this.isReady) {
          resolver()
        } else {
          this.isReadyResolver = resolver
        }
      },

      loginWithRedirect(options) {
        eventBus.$emit('modalLoader.start')
        return this.auth0Client.loginWithRedirect(options)
      },

      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },

      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },

      logout(o) {
        return this.auth0Client.logout(o)
      },

      async updateUserDetails() {
        try {
          const user = await this.auth0Client.getUser()
          const accessToken = await this.getTokenSilently()
          const { __raw: idToken } = await this.getIdTokenClaims()
          const authData = convertToAuthData(user, accessToken, idToken)

          const auth0Api = new Auth0Api()
          await auth0Api.createSession(authData)

          Auth.setAuthData(authData)
          coreAuth.storeAuthData(authData)

          eventBus.$emit('auth.login', { status: 'success' })
        } catch (error) {
          eventBus.$emit('auth.authorizedError', error)
          throw error
        }
      }
    }
  })

  return instance
}

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
