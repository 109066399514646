<template>
  <button
    ref="button"
    :type="buttonType"
    :class="['mt-btn', ...btnClasses, btnModifierClasses, classList]"
    :disabled="disabled"
    :title="getTitle"
    v-on="$listeners"
  >
    <div :class="['mt-btn-content flex', truncate, contentModifierClasses]">
      <slot />
    </div>
    <span
      v-if="badge"
      class="absolute -top-3 -right-2 bg-primary-500 text-white border-2 border-white w-7 h-7 rounded-full leading-6"
    >
      {{ badge }}
    </span>
  </button>
</template>

<script>
const ALLOWED_COLORS = new Set(['primary', 'secondary', 'blue', 'gray'])
const ALLOWED_VARIANTS = new Set(['', 'outline', 'link', 'light-link'])
const ALLOWED_SIZES = new Set(['full', 'xl', 'lg', 'md', 'sm', 'xs'])
const ALLOWED_MODIFIERS = new Set(['fluid', 'no-paddings'])
const ALLOWED_CONTENT_MODIFIERS = new Set(['center', 'start'])
const ALLOWED_TYPES = new Set(['button', 'submit', 'reset'])

export default {
  name: 'AppButton',
  props: {
    color: {
      type: String,
      default: 'primary',
      validator(color) {
        return ALLOWED_COLORS.has(color)
      }
    },
    variant: {
      type: String,
      default: '',
      validator(variant) {
        return ALLOWED_VARIANTS.has(variant)
      }
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ALLOWED_SIZES.has(size)
      }
    },
    modifiers: {
      type: String,
      default: null,
      validator(modifiers) {
        return modifiers.split(' ').every((modifierName) => {
          return ALLOWED_MODIFIERS.has(modifierName)
        })
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Number,
      default: 0
    },
    icon: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    classList: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: 'button',
      validator(buttonType) {
        return ALLOWED_TYPES.has(buttonType)
      }
    },
    contentAlignment: {
      type: String,
      default: 'start',
      validator(contentAlignment) {
        return contentAlignment.split(' ').every((modifierName) => {
          return ALLOWED_CONTENT_MODIFIERS.has(modifierName)
        })
      }
    }
  },
  computed: {
    getTitle() {
      if (this.buttonTitle) {
        return this.buttonTitle
      }
      return this.$slots?.default ? this.$slots?.default[0]?.text : ''
    },
    btnClasses() {
      const classes = [
        `mt-btn${this.variant && '-' + this.variant}-${this.color}`,
        `mt-btn-${this.size}`
      ]
      if (this.badge) {
        classes.push('relative')
      }
      if (this.icon) {
        classes.push('mt-btn-icon')
      }
      return classes
    },

    btnModifierClasses() {
      if (!this.modifiers) {
        return ''
      }
      return this.modifiers
        .split(' ')
        .map((modifierName) => `mt-btn--${modifierName}`)
        .join(' ')
    },
    contentModifierClasses() {
      return this.contentAlignment
        .split(' ')
        .map((modifierName) => `mt-btn-content--${modifierName}`)
        .join(' ')
    },
    truncate() {
      return !this.icon
    }
  }
}
</script>

<style scoped lang="scss">
.truncate {
  display: inline;
}
</style>
