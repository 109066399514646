import { internalFormat, momentDateEST } from '@/lib/utils/formatDate'

export class Order {
  constructor(order) {
    this.accountName = order?.accountName ? order.accountName : ''
    this.createdDate = order?.createdDate
      ? order.createdDate
      : momentDateEST().format(internalFormat)
    this.customerAccountNumber = order?.customerAccountNumber
      ? order.customerAccountNumber
      : ''
    this.deliveryPostalAddress = order?.deliveryPostalAddress
      ? order.deliveryPostalAddress
      : ''
    this.deliveryPostalAddressLine = order?.deliveryPostalAddressLine
      ? order.deliveryPostalAddressLine
      : ''
    this.id = order?.orderNumber ? order.orderNumber : ''
    this.invoiceAccount = order?.invoiceAccount ? order.invoiceAccount : ''
    this.invoiceNumber = order?.invoiceNumber ? order.invoiceNumber : ''
    this.lines = order?.lines ? order.lines : []
    this.orderNumber = order?.orderNumber ? order.orderNumber : ''
    this.payment = order?.payment ? order.payment : ''
    this.paymentMethod = order?.paymentMethod ? order.paymentMethod : ''
    this.purchaseOrder = order?.purchaseOrder ? order.purchaseOrder : ''
    this.shipping = order?.shipping ? order.shipping : []
    this.status = order?.status ? order.status : ''
    this.statusDisplay = order?.statusDisplay ? order.statusDisplay : ''
    this.totalPrice = order?.totalPrice ? order.totalPrice : ''
    this.internalNotes = order?.notes?.length > 0
    this.orderType = order?.orderType ? order.orderType : ''
    this.refOrders = order?.refOrders ? order.refOrders : []
  }
}
