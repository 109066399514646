import PubNub from 'pubnub'
import PubNubVue from 'pubnub-vue'
import Vue from 'vue'
import Chat from 'vue-beautiful-chat'

import auth from '@/lib/core/auth'
import settings from '@/lib/core/settings'

// Pubnub instantiation
const { publishKey, subscribeKey } = settings.services.pubnub
if (!publishKey || !subscribeKey) {
  console.error('PubNub Keys are missing.')
}

Vue.use(Chat, {})

// Pubnub Standard JS SDK
const pubnub = new PubNub({
  subscribeKey,
  publishKey,
  uuid: auth.getCurrentUserId() || 'guest'
})

Vue.prototype.$pubnub = pubnub

// Pubnub Vue SDK
Vue.use(PubNubVue, {
  subscribeKey,
  publishKey,
  ssl: true,
  uuid: auth.getCurrentUserId() || 'guest'
})
