import { internalFormat, momentDateEST } from '@/lib/utils/formatDate'

const validation = {
  validate(value, rules) {
    const {
      required,
      email,
      number,
      boolean,
      date,
      alphaNumeric,
      postalCode,
      lessThanSystemDate
    } = rules
    const errors = []

    const isEmpty = isStringEmpty(value)

    if (required && isEmpty) {
      errors.push('required')
    }

    if (!isEmpty) {
      if (email) {
        const isValid = isValidEmail(value)
        if (!isValid) errors.push('email')
      }
      if (number) {
        const isValid = isNumber(value)
        if (!isValid) errors.push('number')
      }
      if (boolean) {
        const isValid = isBoolean(value)
        if (!isValid) errors.push('boolean')
      }
      if (date) {
        const isValid = isValidDate(value)
        if (!isValid) errors.push('date')
      }
      if (alphaNumeric) {
        const isValid = isAlphaNumeric(value)
        if (!isValid) errors.push('alphaNumeric')
      }
      if (postalCode) {
        const isValid = isPostalCode(value)
        if (!isValid) errors.push('postalCode')
      }
      if (lessThanSystemDate) {
        const isValid = isLessThanSystemDate(value)
        if (!isValid) errors.push('lessThanSystemDate')
      }
    }

    return {
      isValid: !errors.length,
      errors
    }
  }
}

const isStringEmpty = (value) => {
  if (isBoolean(value)) {
    return !value
  }
  if (isNumber(value)) return

  return !value?.trim().length
}

const isValidEmail = (email) => {
  if (email.length < 4) {
    return false
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const isNumber = (value) => {
  const parsed = parseInt(value)
  return Number.isInteger(value) && !isNaN(parsed)
}

const isBoolean = (value) => {
  return typeof value === 'boolean'
}

const isValidDate = (value) => {
  const date = new Date(value)
  return date instanceof Date && !isNaN(date)
}

const isAlphaNumeric = (value) => {
  return /^[a-zA-Z0-9]*$/.test(value)
}

const isPostalCode = (value) => {
  // zip code validation regex supports only 5 digits
  return /(^\d{5}$)/.test(value)
}

const isLessThanSystemDate = (value) => {
  const systemDate = momentDateEST()
  const shippingDateRequested = momentDateEST(value)

  return shippingDateRequested > systemDate
}

export const validateOrderSubmit = (sections, cart, validationRules) => {
  const validationErrors = {}

  sections.forEach((section) => {
    const sectionValue = cart[section]
    const fields = Object.keys(sectionValue)

    fields.forEach((field) => {
      if (section in validationRules) {
        const fieldValue = sectionValue[field]
        const rules = validationRules[section][field]
        if (rules) {
          const { isValid } = validation.validate(fieldValue, rules)
          if (!isValid) {
            _.set(validationErrors, `${section}.${field}`, {
              isMandatory: true
            })
          }
        }
      }
    })
  })

  return Object.keys(validationErrors).length ? validationErrors : null
}

export default validation
