<template>
  <button
    :class="['dropdown-trigger__button', `dropdown-trigger__button--${size}`]"
  >
    <span class="dropdown-trigger-button__dot"></span>
  </button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: ''
    }
  }
}
</script>
