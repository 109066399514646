import auth from '@/lib/core/auth'
import { eventBus } from '@/lib/utils/bus'

export default {
  methods: {
    startRefreshToken() {
      if (!this.$settings.app.authRefreshToken) {
        return
      }

      this.stopRefreshToken()
      const duration = (auth.getCurrentUser().expiresIn - 5) * 1000
      if (!duration) {
        return
      }
      this.refreshTokenTimer = window.setTimeout(() => {
        auth.refreshToken()
      }, duration)
    },
    stopRefreshToken() {
      if (this.refreshTokenTimer) {
        window.clearTimeout(this.refreshTokenTimer)
        this.refreshTokenTimer = null
      }
    }
  },
  beforeDestroy() {
    this.stopRefreshToken()
    eventBus.$off('auth.logout')
    eventBus.$off('auth.refreshToken')
    eventBus.$off('auth.login')
  },
  data() {
    return {
      refreshTokenTimer: null
    }
  }
}
