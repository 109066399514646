import { render, staticRenderFns } from "@/templates/common/dialog/ModalMessage?vue&type=template&id=3cb12d4f&"
import script from "./ModalMessage.vue?vue&type=script&lang=js&"
export * from "./ModalMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports