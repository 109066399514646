import { getUniqueId } from '@/lib/utils/helpers'

export class CreditMemoProduct {
  constructor(entry) {
    this.errorCode = entry.errorCode || ''
    this.errorMessage = entry.errorMessage || ''
    this.entryNumber = entry.entryNumber
    this.isSelected = entry.isSelected || false
    this.itemNumber = entry.itemNumber || ''
    this.lineRecId = entry.lineRecId || ''
    this.hierarchy = entry.hierarchy
    this.overrideBy = entry.overrideBy
    this.highestQtyAllowed = entry.highestQtyAllowed
    this.lowestQtyAllowed = entry.lowestQtyAllowed
    this.multipleQty = entry.multipleQty
    this.netPrice = entry.netPrice || null
    this.quantity = entry.quantity || 0
    this.pceCode = entry.pceCode || ''
    this.priceOverridden = entry.priceOverridden
    this.standardValue = entry.standardValue || null
    this.stockStatus = entry.stockStatus
    this.totalPrice = entry.totalPrice || null
  }

  getRequestPayload() {
    return {
      hierarchy: this.hierarchy,
      itemNumber: this.itemNumber,
      quantity: this.quantity,
      pceCode: this.pceCode,
      netPrice: this.netPrice,
      lineRecId: getUniqueId()
    }
  }
}
