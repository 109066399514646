export class OrderHistoryEntry {
  constructor(item) {
    this.code = item.code || ''
    this.fileName = item.fileName || ''
    this.status = item.status || ''
    this.submittedBy = item.submittedBy || ''
    this.uploadDate = item.uploadDate || ''
    this.orderGroupingInError = item.orderGroupingInError || ''
    this.errorCode = item.errorCode || ''
    this.type = item.type || ''
    this.orderCreated = item.orderCreated
  }
}
