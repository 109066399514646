import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { DefaultReturnProduct } from '@/lib/models/product/product'
import { getInternalformatDate } from '@/lib/utils/formatDate'
import { getCartDisabledStatusByCartStatus } from '@/lib/utils/helpers'
import { validateOrderSubmit } from '@/lib/utils/validation'

import { quotesStore } from '@/stores/quotes'

export class Return {
  constructor(cart) {
    this.header = {
      APIType: cart?.header?.APIType ? cart.header.APIType : '',
      cartCode: cart?.header?.cartCode ? cart.header.cartCode : '',
      cartStatus: cart?.header?.cartStatus ? cart.header.cartStatus : '',
      createdBy: cart?.header?.createdBy ? cart.header.createdBy : '',
      currency: cart?.header?.currency ? cart.header.currency : '',
      customerAccount: cart?.header?.customerAccount
        ? cart.header.customerAccount
        : '',
      customerName: cart?.header?.customerName ? cart.header.customerName : '',
      errorCode: cart?.header?.errorCode ? cart.header.errorCode : '',
      errorMessage: cart?.header?.errorMessage ? cart.header.errorMessage : '',
      formattedCurrency: cart?.header?.formattedCurrency
        ? cart.header.formattedCurrency
        : '',
      invoiceAccount: cart?.header?.invoiceAccount
        ? cart.header.invoiceAccount
        : '',
      lastUpdatedOn: cart?.header?.lastUpdatedOn
        ? getInternalformatDate(cart.header.lastUpdatedOn)
        : '',
      priceDate: cart?.header?.priceDate
        ? getInternalformatDate(cart.header.priceDate)
        : '',
      quoteCreationDate: cart?.header?.quoteCreationDate
        ? getInternalformatDate(cart.header.quoteCreationDate)
        : '',
      quoteExpirationDate: cart?.header?.quoteExpirationDate
        ? getInternalformatDate(cart.header.quoteExpirationDate)
        : '',
      returnReasonCode: cart?.header?.returnReasonCode
        ? cart.header.returnReasonCode
        : '',
      salesId: cart?.header?.salesId ? cart.header.salesId : '',
      salesOriginId: cart?.header?.salesOriginId
        ? cart.header.salesOriginId
        : '',
      salesType: cart?.header?.salesType ? cart.header.salesType : '',
      shortTypeCode: cart?.header?.shortTypeCode
        ? cart.header.shortTypeCode
        : '',
      type: cart?.header?.type ? cart.header.type : 'Return'
    }

    this.account = {
      customerRequirements: cart?.account?.customerRequirements
        ? cart.account.customerRequirements
        : '',
      invoiceAccount: cart?.account?.invoiceAccount
        ? cart.account.invoiceAccount
        : '',
      salesContact1: cart?.account?.salesContact1
        ? cart.account.salesContact1
        : '',
      salesContact2: cart?.account?.salesContact2
        ? cart.account.salesContact2
        : '',
      sendRGAEmailCheckbox: cart?.account?.sendRGAEmailCheckbox
        ? cart.account.sendRGAEmailCheckbox
        : false,
      contactName: cart?.account?.contactName ? cart.account.contactName : '',
      contactEmail: cart?.account?.contactEmail ? cart.account.contactEmail : ''
    }

    this.returnManagement = {
      returnLocation: cart?.returnManagement?.returnLocation
        ? cart.returnManagement.returnLocation
        : '',
      returnReasonCode: cart?.returnManagement?.returnReasonCode,
      PO: cart?.returnManagement?.PO ? cart.returnManagement.PO : '',
      invoice: cart?.returnManagement?.invoice
        ? cart.returnManagement.invoice
        : '',
      salesOrderReference: cart?.returnManagement?.salesOrderReference
        ? cart.returnManagement.salesOrderReference
        : ''
    }

    this.autoSave = false

    this.id = cart?.header?.cartCode ? cart.header.cartCode : ''

    this.other = {
      notes: cart?.other?.notes ? cart.other.notes : [],
      workflow:
        cart?.other?.workflow && Object.keys(cart.other.workflow).length > 0
          ? {
              type: cart?.other?.workflow?.type,
              assignee: cart?.other?.workflow?.assignee,
              startDate: cart?.other?.workflow?.startDate,
              createdBy: cart?.other?.workflow?.createdBy,
              rejectReason: cart?.other?.workflow?.rejectReason,
              rejectNote: cart?.other?.workflow?.rejectNote,
              status: cart?.other?.workflow?.status
            }
          : null
    }

    this.lines = cart?.lines ? cart.lines : []

    this.summary = {
      subTotal: cart?.summary?.subTotal ? cart.summary.subTotal : null,
      restockingFee: cart?.summary?.restockingFee
        ? cart.summary.restockingFee
        : null,
      totalTaxes: cart?.summary?.totalTaxes ? cart.summary.totalTaxes : null,
      totalPrice: cart?.summary?.totalPrice ? cart.summary.totalPrice : null,
      totalItems: cart?.summary?.totalItems ? cart.summary.totalItems : null,
      taxCalculationStatus: cart?.summary?.taxCalculationStatus
        ? cart.summary.taxCalculationStatus
        : null
    }

    this.internalNotes = cart?.internalNotes ? cart.internalNotes : ''

    this.attachments = cart?.attachments?.length ? cart.attachments : []

    this.deliveryAddress = cart?.deliveryAddress ? cart.deliveryAddress : null

    this.paymentAddress = cart?.paymentAddress ? cart.paymentAddress : null

    this.validationErrors = cart?.validationErrors || {}

    this.isDisabledProductSuggest = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledQtyInput = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledPromoCell = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledFreeGoodCell = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.cartType = CART_TYPES.RETURN.currentCart
    this.isDisabledByStatus = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
  }

  updateField(payload, withPatchRequest = true) {
    quotesStore().updateReturnField(payload)
    if (withPatchRequest) return quotesStore().returnFieldChanged()
  }

  setNoteField(payload) {
    quotesStore().setNoteReturnField(payload)
  }

  createDefaultProduct() {
    return new DefaultReturnProduct()
  }

  createNew() {
    return quotesStore().createReturn()
  }

  submitValidationRules() {
    const currentReturn = quotesStore().currentReturn
    const isInvoiceRequired =
      !currentReturn?.lines.length ||
      currentReturn?.lines?.every((line) => !line?.salesOrderReference)
    const isSalesOrderReferenceRequired =
      !currentReturn?.lines.length && !this.returnManagement.invoice

    return {
      account: {
        contactEmail: {
          required: this.account.sendRGAEmailCheckbox
        }
      },
      returnManagement: {
        returnLocation: {
          required: true
        },
        returnReasonCode: {
          required: true
        },
        PO: {
          required: true
        },
        invoice: {
          required: isInvoiceRequired
        },
        salesOrderReference: {
          required: isSalesOrderReferenceRequired
        }
      }
    }
  }

  validateSubmitOrderPayload() {
    const validationRules = this.submitValidationRules()
    const sections = ['returnManagement', 'account']
    return validateOrderSubmit(sections, this, validationRules)
  }
}
