import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Shipping extends ApiBase {
  /**
   * Get shipping addresses for the currently logged in user.
   * @returns {Promise<any>}
   */
  getShippingAddresses() {
    const userId = auth.getCurrentUserId()
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getEndpointUrl(`/addresses/${userId}`))
        .then((response) => {
          if (!('data' in response) || !response.data) {
            resolve([])
          } else {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Get shipping options for the currently logged in user.
   * @returns {Promise<any>}
   */
  getShippingOptions(cart) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/delivery-modes/${userId}/${cart.id}`
    )
    return new Promise((resolve, reject) => {
      this.transport
        .get(endpoint)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Set shipping option.
   * @param cart
   * @param shippingOption
   * @returns {Promise<any>}
   */
  setShippingOption(cart, shippingOption) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/delivery-modes/${userId}/${cart.id}`
    )
    const data = {
      deliveryModeId: shippingOption.code
    }
    return new Promise((resolve, reject) => {
      this.transport
        .put(endpoint, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Set delivery address for the given cart.
   * @param cart
   * @param address
   * @returns {*|AxiosPromise<any>|IDBRequest|Promise<void>}
   */
  setDeliveryAddress(cart, address) {
    const userId = auth.getCurrentUserId()
    const endpoint = this.getEndpointUrl(
      `/checkout/delivery-address/${userId}/${cart.id}`
    )
    const data = {
      addressId: address.id
    }
    return new Promise((resolve, reject) => {
      this.transport
        .put(endpoint, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Shipping
