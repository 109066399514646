import { defineStore } from 'pinia'

import api from '@/lib/api'
import settings from '@/lib/core/settings'
import { eventBus } from '@/lib/utils/bus'

const state = {
  isSidebarVisible: false,
  billingInfo: {},
  carts: []
}

const getters = {
  totalProducts: (state) => {
    let quantity = 0
    if (!state.carts) {
      return quantity
    }
    state.carts.forEach((cart) => {
      if (!cart.entries) {
        return
      }
      cart.entries.forEach((entry) => {
        quantity += entry.quantity
      })
    })
    return quantity
  }
}

const actions = {
  /**
   * Set cart items.
   * @param carts
   */
  setCarts(carts) {
    if (!settings.feature('cart.multiCarts')) {
      if (carts && carts.length > 1) {
        carts = [carts[0]]
      }
    }
    this.carts = carts
    eventBus.$emit('cart.refreshed')
  },

  /**
   * Refresh carts.
   * @returns {Promise<any>}
   */
  refreshCarts() {
    return new Promise((resolve, reject) => {
      api.cart
        .getCarts()
        .then((carts) => {
          this.setCarts(carts)
          resolve()
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  /**
   * Set sidebar's visibility state.
   * @param isVisible
   */
  setSidebarVisibility(isVisible) {
    this.isSidebarVisible = isVisible
  },

  /**
   * Set billing info.
   * @param billingInfo
   */
  setBillingInfo(billingInfo) {
    this.billingInfo = billingInfo
  },

  /**
   * Reset billing info data.
   */
  resetBillingInfo() {
    this.billingInfo = {}
  },

  /**
   * Update quantity.
   * @param payload
   * @returns {Promise<any>}
   */
  updateQuantity(payload) {
    return new Promise((resolve, reject) => {
      const quantity = parseInt(payload.quantity, 10)
      if (isNaN(quantity)) {
        reject(new Error('Quantity is wrong'))
        return
      }

      if (!payload.cart || !payload.item) {
        reject(new Error('Cart or item is missing'))
        return
      }

      api.cart
        .updateQuantity(payload.cart, payload.item, quantity)
        .then(() => this.refreshCarts())
        .then(() => resolve())
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  /**
   * Remove item from cart
   * @param item
   */
  removeItem(item) {
    return new Promise((resolve, reject) => {
      if (!('cart' in item) || !('item' in item)) {
        reject(new Error('Cart or item is missing'))
        return
      }

      api.cart
        .remove(item.cart, item.item)
        .then(() => this.refreshCarts())
        .then(() => resolve())
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export const cartStore = defineStore('cart', {
  persist: true,
  state: () => ({
    ...state
  }),

  getters,
  actions
})
