import BaseInvoices from '@/lib/api/v1/invoices'

class Invoices extends BaseInvoices {
  getInvoices(
    searchData,
    currentPage = 0,
    pageSize = 10,
    asc = false,
    code = 'byDate'
  ) {
    return this.transport.get('/rest/api/v3/invoice', null, {
      locale: '',
      requestOptions: {
        params: {
          ...searchData,
          currentPage,
          pageSize,
          asc,
          code
        }
      }
    })
  }

  downloadInvoice(
    invoiceNumber,
    transactionSource,
    customerTransactionId,
    businessUnitId
  ) {
    return this.transport.get('/rest/api/v3/invoice/download', null, {
      locale: '',
      requestOptions: {
        responseType: 'blob',
        params: {
          invoiceNumber,
          transactionSource,
          customerTransactionId,
          businessUnitId
        }
      }
    })
  }

  sendInvoices(invoices, to, businessUnit) {
    return this.transport.post(
      '/rest/api/v3/invoice/email',
      {
        invoices,
        to,
        businessUnit
      },
      {
        locale: ''
      }
    )
  }

  downloadInvoicesXLSX(
    searchData,
    currentPage = 0,
    pageSize = 10,
    asc = false,
    code = 'byDate'
  ) {
    return this.transport.get('/rest/api/v3/invoice/export/xlsx', null, {
      locale: '',
      requestOptions: {
        responseType: 'blob',
        params: {
          ...searchData,
          currentPage,
          pageSize,
          asc,
          code
        }
      }
    })
  }
}

export default Invoices
