import BaseQuotes from '@/lib/api/v1/quotes'
import { internalFormat, momentDateEST } from '@/lib/utils/formatDate'

class Quotes extends BaseQuotes {
  createCart(cartType = 'None') {
    return this.transport.post(
      `/rest/api/v3/carts?cartType=${cartType}`,
      undefined,
      { locale: '' }
    )
  }

  deleteCart(cartId) {
    return this.transport.delete(`/rest/api/v3/carts/${cartId}`, undefined, {
      locale: ''
    })
  }

  saveQuote(data, businessUnit) {
    return this.transport.patch(
      `/rest/api/v3/carts/${data.header.cartCode}`,
      data,
      {
        locale: '',
        requestOptions: {
          params: {
            businessUnit,
            hierarchy: true
          }
        }
      }
    )
  }

  getQuotes(params) {
    return this.transport.get('/rest/api/v3/carts', null, {
      locale: '',
      requestOptions: {
        params: {
          ...params
        }
      }
    })
  }

  getCartsSaleOrders({
    asc = false,
    code = 'code',
    tab,
    currentPage,
    pageSize = 10,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  }) {
    return this.transport.get(
      `/rest/api/v3/widgets/${tab}/quotesSaleOrders`,
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            asc,
            code,
            employee,
            currentPage,
            pageSize,
            customerAccount,
            bu,
            pl1,
            pl2,
            pl3,
            sku,
            pceCode,
            type
          }
        }
      }
    )
  }

  getFailedCarts({
    asc = false,
    code = 'code',
    dateRange,
    currentPage,
    pageSize = 10,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return this.transport.get(
      '/rest/api/v3/widgets/FAIL/quotesSaleOrders',
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            dateRange,
            asc,
            code,
            employee,
            currentPage,
            pageSize,
            customerAccount,
            bu,
            pl1,
            pl2,
            pl3,
            sku,
            pceCode
          }
        }
      }
    )
  }

  getWorkflowApproval({ cartCode, shortList }) {
    return this.transport.get(
      `/rest/api/v3/workflows/assignments/${cartCode}?shortList=${shortList}`,
      null,
      { locale: '' }
    )
  }

  downloadCartsSaleOrders(
    quoteStatus,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  ) {
    return this.transport.get(
      `/rest/api/v3/order/widget/export/${quoteStatus}/xlsx`,
      null,
      {
        locale: '',
        requestOptions: {
          responseType: 'blob',
          params: {
            customerAccount,
            employee,
            bu,
            pl1,
            pl2,
            pl3,
            sku,
            pceCode,
            type
          }
        }
      }
    )
  }

  downloadTeamMetrics(
    tab,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  ) {
    return this.transport.get(
      `/rest/api/v3/order/widget/export/TEAM_METRICS/xlsx?dateRange=${tab}`,
      null,
      {
        locale: '',
        requestOptions: {
          responseType: 'blob',
          params: {
            customerAccount,
            employee,
            bu,
            pl1,
            pl2,
            pl3,
            sku,
            pceCode
          }
        }
      }
    )
  }

  submitWorkflowAction(data) {
    return this.transport.post('/rest/api/v3/workflows/decide', data, {
      locale: ''
    })
  }

  getCartsQtyByStatus(
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  ) {
    return this.transport.get('/rest/api/v3/widgets/quotes/counts', null, {
      locale: '',
      requestOptions: {
        params: {
          customerAccount,
          employee,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode,
          type
        }
      }
    })
  }

  getFailedQuotesQtyByStatus(
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  ) {
    return this.transport.get(
      '/rest/api/v3/widgets/failedQuotes/counts',
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            customerAccount,
            employee,
            bu,
            pl1,
            pl2,
            pl3,
            sku,
            pceCode,
            type
          }
        }
      }
    )
  }

  getQuote(quoteId, businessUnit) {
    return this.transport.get(`/rest/api/v3/carts/${quoteId}`, null, {
      locale: '',
      requestOptions: {
        params: {
          businessUnit,
          hierarchy: true
        }
      }
    })
  }

  addListToQuote({
    cartId,
    lines,
    cartType,
    businessUnit,
    returnLevelInvoiceId
  }) {
    return this.transport.post(
      '/rest/api/v3/carts/addList',
      {
        cartId,
        lines,
        cartType,
        returnLevelInvoiceId
      },
      {
        locale: '',
        requestOptions: {
          params: {
            businessUnit
          }
        }
      }
    )
  }

  addMultipleProductsToMultipleQuotes(data) {
    return this.transport.post('/rest/api/v3/multiple/carts', data, {
      locale: ''
    })
  }

  submitOrder({ quoteId, businessUnit, cartType }) {
    return this.transport.post(
      `/rest/api/v3/carts/submit/${quoteId}`,
      {},
      {
        locale: '',
        requestOptions: {
          params: {
            businessUnit,
            cartType
          }
        }
      }
    )
  }

  sendQuoteToCustomer({ quoteId, emails }) {
    return this.transport.post(
      `/rest/api/v3/carts/${quoteId}/send`,
      { to: emails },
      { locale: '' }
    )
  }

  convertQuoteToOrder(cartCode) {
    return this.transport.post(
      `/rest/api/v3/carts/convertToOrder/${cartCode}`,
      { cartCode },
      { locale: '' }
    )
  }

  updateQuoteStatus(data) {
    return this.transport.patch(
      `/rest/api/v3/carts/${data.id}`,
      { ...data },
      { locale: '' }
    )
  }

  getAccountInfo(currentCustomer) {
    return this.transport.get(
      `/rest/api/v3/b2bunits/${currentCustomer}`,
      currentCustomer,
      { locale: '' }
    )
  }

  /**
   * Set shipping address for the cart
   * @param {Object} data
   * @param {string} data.cartId cart guid
   * @param {string} data.addressId address guid
   */
  setShippingAddress({ cartId, addressId }) {
    return this.transport.put(
      `/rest/api/v3/carts/${cartId}/addresses/delivery?addressId=${addressId}`,
      undefined,
      { locale: '' }
    )
  }

  setDropshipAddress({ cartId, address }) {
    return this.transport.post(
      `/rest/api/v3/carts/${cartId}/addresses/dropship`,
      address,
      { locale: '' }
    )
  }

  getDropshipAddress(quoteId) {
    return this.transport.get(
      `/rest/api/v3/carts/${quoteId}/addresses/dropship`,
      null,
      { locale: '' }
    )
  }

  editCartEntry({ cartId, entryNumber, businessUnit, ...data }) {
    return this.transport.patch(
      `/rest/api/v3/carts/${cartId}/entries/${entryNumber}`,
      data,
      {
        locale: '',
        requestOptions: {
          params: {
            businessUnit
          }
        }
      }
    )
  }

  removeCartEntry({ cartId, entryNumber }) {
    return this.transport.delete(
      `/rest/api/v3/carts/${cartId}/entries/${entryNumber}`,
      { cartId, entryNumber },
      { locale: '' }
    )
  }

  removeMultipleCartEntries(quoteId, lines) {
    return this.transport.delete(
      `/rest/api/v3/carts/${quoteId}/entries`,
      lines,
      { locale: '' }
    )
  }

  downloadCart(cartId, fileType) {
    return this.transport.get(
      `/rest/api/v3/carts/${cartId}/export/${fileType}`,
      null,
      { locale: '', requestOptions: { responseType: 'blob' } }
    )
  }

  downloadQuotesXlsx(searchData) {
    return this.transport.get('/rest/api/v3/carts/export/xlsx', null, {
      locale: '',
      requestOptions: { responseType: 'blob', params: searchData }
    })
  }

  calculateProductPrice({ productSKU, customerAccount, quantity = 1 }) {
    const dateFormatted = momentDateEST().format(internalFormat)
    const payload = {
      header: {
        APIType: 'Sales order',
        customerAccount: customerAccount,
        salesId: 'AX06304806',
        type: 'None',
        salesOriginId: 'SHOW',
        priceDate: dateFormatted
      },
      lines: [
        {
          itemNumber: productSKU,
          quantity: quantity,
          lineRecId: '5674698593'
        }
      ]
    }
    return this.transport.post(
      '/rest/api/v3/carts/calculate/formattedValue',
      payload,
      { locale: '' }
    )
  }

  /**
   * Get non authorized cart by token
   * @param {string} token
   */
  getNonAuthorizedCart(token) {
    return this.transport.get(
      `/rest/api/v3/carts/token?token=${encodeURIComponent(token)}`,
      null,
      { locale: '' }
    )
  }

  /**
   * Approve quote
   * @param {Object} data
   * @param {string} data.token cart token
   * @param {string} data.comment additional comments
   * @param {string} data.po po number
   */
  approveQuote(data) {
    return this.transport.post('/rest/api/v3/widgets/approve', data, {
      locale: ''
    })
  }

  /**
   * Reject quote
   * @param {Object} data
   * @param {string} data.token cart token
   * @param {string} data.comment additional comments
   * @param {string} data.po po number
   */
  rejectQuote(data) {
    return this.transport.post('/rest/api/v3/widgets/reject', data, {
      locale: ''
    })
  }

  getSalesOriginIdOrderFields(b2bUnitUid) {
    return this.transport.get(
      '/rest/api/v3/carts/orderfieldvalue/b2bUnit/SalesoriginidOrderFieldValue',
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            b2bUnitUid
          }
        }
      }
    )
  }

  getPaymentOrderFields() {
    return this.transport.get(
      '/rest/api/v3/carts/orderfieldvalue/PaymentOrderFieldValue',
      null,
      { locale: '' }
    )
  }

  getWarehouseId(cartCode) {
    return this.transport.get(
      `/rest/api/v3/carts/${cartCode}/warehouses`,
      null,
      { locale: '' }
    )
  }

  getModeOfDelivery(cartCode, warehouse = '') {
    return this.transport.get(
      `/rest/api/v3/carts/${cartCode}/modeOfDeliveries`,
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            warehouse
          }
        }
      }
    )
  }

  getDeliveryReasonsFields() {
    return this.transport.get(
      '/rest/api/v3/carts/orderfieldvalue/DeliveryReasonOrderFieldValue',
      null,
      { locale: '' }
    )
  }

  duplicatePONumberCheck(orderSearchData) {
    return this.transport.get('/rest/api/v3/carts/duplicate', null, {
      locale: '',
      requestOptions: {
        params: {
          ...orderSearchData
        }
      }
    })
  }

  checkFreightPolicy(quoteId) {
    return this.transport.get(
      `/rest/api/v3/carts/${quoteId}/validateFreightPolicy`,
      null,
      { locale: '' }
    )
  }

  getTeamMetrics(
    dateRange,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    currentPage = 0,
    pageSize = 10
  ) {
    return this.transport.get('/rest/api/v3/widgets/team', null, {
      locale: '',
      requestOptions: {
        params: {
          dateRange,
          customerAccount,
          employee,
          currentPage,
          pageSize,
          bu,
          pl1,
          pl2,
          pl3,
          sku,
          pceCode
        }
      }
    })
  }

  downloadAttachment(cartId, attachmentCode) {
    return this.transport.get(
      `/rest/api/v3/carts/${cartId}/attachment/download/${attachmentCode}`,
      null,
      { locale: '', requestOptions: { responseType: 'blob' } }
    )
  }

  deleteAttachment(cartId, attachmentCode) {
    return this.transport.delete(
      `/rest/api/v3/carts/${cartId}/attachment/delete/${attachmentCode}`,
      undefined,
      { locale: '' }
    )
  }

  getReasonCodes(b2bUnitUid, type) {
    return this.transport.get('/rest/api/v3/carts/code/reason', null, {
      locale: '',
      requestOptions: {
        params: {
          b2bUnitUid,
          type
        }
      }
    })
  }

  getTypeCodes() {
    return this.transport.get(
      '/rest/api/v3/carts/orderfieldvalue/TypeOrderFieldValue',
      null,
      {
        locale: ''
      }
    )
  }

  getCostCenters(b2bUnitUid) {
    return this.transport.get('/rest/api/v3/carts/costCenters', null, {
      locale: '',
      requestOptions: {
        params: {
          b2bUnitUid
        }
      }
    })
  }

  getReturnLocations(b2bUnitUid) {
    return this.transport.get('/rest/api/v3/carts/returnLocations', null, {
      locale: '',
      requestOptions: {
        params: {
          b2bUnitUid
        }
      }
    })
  }

  getSalesReference({
    salesId,
    businessUnit,
    invoiceNumber,
    itemNumber,
    currentPage = 0,
    pageSize = 20,
    code = 'byInvoiceNumber',
    asc = true
  }) {
    return this.transport.get('/rest/api/v3/return/salesReference', null, {
      locale: '',
      requestOptions: {
        params: {
          salesId,
          businessUnit,
          invoiceNumber,
          itemNumber,
          currentPage,
          pageSize,
          code,
          asc
        }
      }
    })
  }

  getReturnSuggestedProducts({ salesId, businessUnit, itemNumber }) {
    return this.transport.get('/rest/api/v3/return/productSuggestion', null, {
      locale: '',
      requestOptions: {
        params: {
          salesId,
          businessUnit,
          itemNumber
        }
      }
    })
  }
}

export default Quotes
