<template>
  <span
    class="material-icons-outlined leading-none"
    :class="[color, sizeClasses]"
    @click="$emit('click')"
    >{{ icon }}</span
  >
</template>

<script>
const SIZE_MODIFICATORS = {
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  xxl: 'text-2xl',
  xxxl: 'text-3xl'
}
export default {
  name: 'AppIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    color: {
      type: String,
      default: 'text-secondary-400'
    }
  },

  computed: {
    sizeClasses() {
      return SIZE_MODIFICATORS[this.size]
    }
  }
}
</script>
