import LightboxUi from '@/components/common/Lightbox/Lightbox'

/**
 * Modal dialog
 */
class Lightbox {
  /**
   * Lightbox constructor.
   * @param Vue
   */
  constructor(Vue) {
    this.Vue = Vue
    this.mounted = false
    this.$root = {}
  }

  /**
   * Mount component
   */
  mount() {
    if (this.mounted) {
      return
    }

    this.mounted = true
    this.$root = (() => {
      const LightboxComponent = this.Vue.extend(LightboxUi)
      const node = document.createElement('div')

      document.querySelector('body').appendChild(node)
      return new LightboxComponent().$mount(node)
    })()
  }

  /**
   * Destroy component
   */
  destroy() {
    if (!this.mounted) {
      return
    }

    const domElement = this.$root.$el
    this.$root.$destroy()
    this.$root.$off()
    domElement.remove()

    this.mounted = false
  }

  show(imageUrl) {
    this.mount()
    this.$root.show(imageUrl)
  }
}

export default {
  /**
   * @param Vue
   * @returns {Lightbox}
   */
  install(Vue) {
    Vue.lightbox = new Lightbox(Vue)
    Object.defineProperties(Vue.prototype, {
      $lightbox: {
        get() {
          return Vue.lightbox
        }
      }
    })
  }
}
