import { defineStore } from 'pinia'

import api from '@/lib/api'

const state = {
  teamMetricUsers: [],
  failedCarts: [],
  userMetrics: [],
  availableMetricsList: []
}

const mutations = {
  SET_TEAM_METRIC_USERS(users) {
    this.teamMetricUsers = users
  },
  ADD_TEAM_METRIC_USERS(users) {
    this.teamMetricUsers = this.teamMetricUsers.concat(users)
  },
  ADD_FAILED_CARTS_LIST(failedCarts) {
    this.failedCarts = this.failedCarts.concat(failedCarts)
  }
}

const actions = {
  getSimpleWidgetData({
    widgetType,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return api.user
      .getSimpleWidgetData(
        widgetType,
        employee,
        customerAccount,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode
      )
      .then((res) => {
        return res.data
      })
  },
  getDateToDateQuotes({
    code,
    dateRange,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return api.user.getDateToDateQuotes(
      code,
      dateRange,
      employee,
      customerAccount,
      bu,
      pl1,
      pl2,
      pl3,
      sku,
      pceCode
    )
  },
  getCartTypeKPI({
    code,
    cartType,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return api.user.getCartTypeKPI(
      code,
      cartType,
      employee,
      customerAccount,
      bu,
      pl1,
      pl2,
      pl3,
      sku,
      pceCode
    )
  },
  getBasePromoPercentWidgetData({
    widgetSubtype,
    employee,
    customerAccount,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return api.user.getBasePromoPercentWidgetData(
      widgetSubtype,
      employee,
      customerAccount,
      bu,
      pl1,
      pl2,
      pl3,
      sku,
      pceCode
    )
  },
  getMetrics() {
    return api.user.getMetrics().then((res) => {
      this.userMetrics = res.data
    })
  },
  getAvailableMetricsList() {
    return api.user.getAvailableMetricsList().then((res) => {
      this.availableMetricsList = res.data
    })
  },
  addKPIWidget(widgetType) {
    return api.user.addKPIWidget(widgetType).then(async () => {
      await this.getMetrics()
    })
  },
  deleteKPIWidget(widgetType) {
    return api.user.deleteKPIWidget(widgetType).then(async () => {
      await this.getMetrics()
    })
  },
  async getTeamMetrics({
    dateRange,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    currentPage,
    pageSize,
    isAdditionalLoad = false
  }) {
    return await api.quotes
      .getTeamMetrics(
        dateRange,
        customerAccount,
        employee,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode,
        currentPage,
        pageSize
      )
      .then((res) => {
        if (res.data.metrics) {
          isAdditionalLoad
            ? this.ADD_TEAM_METRIC_USERS(res.data.metrics)
            : this.SET_TEAM_METRIC_USERS(res.data.metrics)
        }
        return res.data
      })
  },
  async getFailedCartWidget({
    dateRange,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    currentPage,
    pageSize,
    isAdditionalLoad = false
  }) {
    return await api.quotes
      .getFailedCarts({
        dateRange,
        customerAccount,
        employee,
        bu,
        pl1,
        pl2,
        pl3,
        sku,
        pceCode,
        currentPage,
        pageSize
      })
      .then((res) => {
        if (res.data) {
          isAdditionalLoad
            ? this.ADD_FAILED_CARTS_LIST(res.data?.orders || [])
            : (this.failedCarts = res.data?.orders || [])
        }
        return res
      })
  },
  downloadCartsSaleOrders({
    quoteStatus,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode,
    type
  }) {
    return api.quotes.downloadCartsSaleOrders(
      quoteStatus,
      customerAccount,
      employee,
      bu,
      pl1,
      pl2,
      pl3,
      sku,
      pceCode,
      type
    )
  },
  downloadTeamMetrics({
    tab,
    customerAccount,
    employee,
    bu,
    pl1,
    pl2,
    pl3,
    sku,
    pceCode
  }) {
    return api.quotes.downloadTeamMetrics(
      tab,
      customerAccount,
      employee,
      bu,
      pl1,
      pl2,
      pl3,
      sku,
      pceCode
    )
  }
}

export const metricsStore = defineStore('metrics', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions: {
    ...mutations,
    ...actions
  }
})
