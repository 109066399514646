<template src="@/templates/b2b/cart/Cart/CartSidebar"></template>
<script>
import { mapState, mapActions } from 'pinia'

import ImageLoader from '@/components/common/ImageLoader'

import RemoveButton from '@/components/b2b/cart/Cart/RemoveButton'
import { cartStore } from '@/stores/cart'
import cartItemTransition from '@/transitions/cart-item'
import simpleTransition from '@/transitions/simple'

export default {
  name: 'CartSidebar',
  components: {
    RemoveButton,
    ImageLoader
  },
  data() {
    return {
      cartItemTransition,
      simpleTransition,
      sectionStates: {}
    }
  },
  computed: {
    ...mapState(cartStore, ['carts']),
    isEmpty() {
      return !this.carts || !this.carts.length
    }
  },
  methods: {
    ...mapActions(cartStore, ['setSidebarVisibility']),
    getCartIndex(cart) {
      return this.carts.findIndex((item) => cart.id === item.id)
    },
    isCollapsedCart(cart) {
      const index = this.getCartIndex(cart)
      const stateCollapsed =
        !(cart.id in this.sectionStates) || this.sectionStates[cart.id] !== true
      return index > 0 && stateCollapsed
    },
    toggleSection(cart) {
      if (!(cart.id in this.sectionStates)) {
        this.$set(this.sectionStates, cart.id, false)
      }
      this.$set(this.sectionStates, cart.id, !this.sectionStates[cart.id])
    },
    navigateToProduct(item) {
      this.$router.push(`/product/${item.product.sku}`)
      this.close()
    },
    myCarts() {
      this.$router.push('/carts')
      this.close()
    },
    viewCart(cart) {
      this.$router.push(`/cart/${cart.id}`)
      this.close()
    },
    close() {
      this.setSidebarVisibility(false)
    }
  }
}
</script>
