<template>
  <div class="loader-control">
    <div v-if="modal" class="overlay"></div>
    <div
      class="loader"
      :class="{
        fixed: !inline && !relativeToParent,
        relative: relativeToParent
      }"
    >
      <div class="spinner" :style="sizeProps"></div>
      <div v-if="text.length > 0">{{ text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    relativeToParent: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 30
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    sizeProps() {
      const borderWidth = Math.min(Math.ceil(this.size / 10), 3)
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderWidth: `${borderWidth}px`
      }
    }
  }
}
</script>
