import Vue from 'vue'

import coreAuth from '@/lib/core/auth'
import settings from '@/lib/core/settings'
import { authGuard } from '@/lib/services/auth0/guard'
import { getInstance, Auth0Plugin } from '@/lib/services/auth0/plugin'
import { eventBus } from '@/lib/utils/bus'
import routes from '@/lib/utils/routes'

import router from '@/router'

export default {
  methods: {
    initAuth0() {
      router.beforeEach(authGuard)

      const authService = getInstance()
      authService.whenReady(this.checkAuth)

      this.$settings.features.user.canChangePassword = false
      this.$settings.features.user.canChangeProfileInfo = false
    },
    checkAuth() {
      // don't checkAuth if the route isn't ready yet (name is "null"), otherwise check for public routes
      if (
        !this.$route.name ||
        (this.$route.name !== 'login' && routes.isPublic(this.$route.name))
      )
        return

      const authService = getInstance()
      if (!authService.isAuthenticated) {
        authService.loginWithRedirect({ appState: { targetUrl: '/' } })
      }
    },
    logoutFromAuth0(params) {
      if (params.status === 'success') {
        getInstance().logout({
          returnTo: window.location.origin
        })
      }
    }
  },
  created() {
    Vue.use(Auth0Plugin, {})
  },
  mounted() {
    eventBus.$on('auth.init', this.initAuth0)
    eventBus.$on('auth.logout', this.logoutFromAuth0)
  },
  beforeDestroy() {
    eventBus.$off('auth.init', this.initAuth0)
    eventBus.$off('auth.logout', this.logoutFromAuth0)
  }
}
