/* eslint-disable no-undef */
import assignment from 'assignment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import settings from '@/lib/core/settings'
import storedSessions from '@/lib/core/stored-sessions'

Vue.use(VueI18n)

const reqCore = require.context('./', true, /^\.\/.*\.json$/)
const reqExtension = require.context(
  `../${EXTENSION_DIR}/i18n/`,
  true,
  /^\.\/.*\.json$/
)

let locale = settings.i18n.defaultLocale
let messages = {}
const numberFormats = {}
const dateTimeFormats = {}

if (!('i18n' in settings) || !('locales' in settings.i18n)) {
  console.error('i18n settings are missing in settings.')
  messages = {
    en: reqCore(`./${locale}.json`)
  }
} else {
  locale = storedSessions.language

  for (const l in settings.i18n.locales) {
    let strings = {}
    try {
      // Load core locale
      strings = reqCore(`./${l}.json`)
    } catch (e) {
      console.error(`Locale ${settings.i18n.locales[l]} not found!`)
    }

    try {
      // Load extension's locale
      const extensionStrings = reqExtension(`./${l}.json`)
      strings = assignment(strings, extensionStrings)
    } catch (e) {
      console.log(
        'Settings: Settings not defined, fallback to default settings.'
      )
    }

    messages[l] = strings

    if ('currency' in strings) {
      numberFormats[l] = {
        ...numberFormats[l],
        currency: strings.currency
      }
    }

    if ('currencyNoCents' in strings) {
      numberFormats[l] = {
        ...numberFormats[l],
        currencyNoCents: strings.currencyNoCents
      }
    }

    if ('dateTimeFormats' in strings) {
      dateTimeFormats[l] = strings.dateTimeFormats
    }
  }
}

const i18n = new VueI18n({
  locale,
  messages,
  numberFormats,
  dateTimeFormats
})

Vue.translate = Vue.prototype.$translate = (message) => {
  if (i18n.te(message)) {
    return i18n.t(message)
  }
  return message
}

const locales = Object.keys(messages)
export { i18n, locales }
