import { defineStore } from 'pinia'

import api from '@/lib/api'
import { US_REGION, MEXICO_REGION } from '@/lib/models/user/met-region'
import { ROLES } from '@/lib/models/user/roles'

import router from '@/router'
import { langStore } from '@/stores/lang'
import { rolesStore } from '@/stores/roles'

const metRegionLang = {
  en: 'en-US',
  es: 'es-ES'
}

const metBusinessUnit = {
  [US_REGION]: '',
  [MEXICO_REGION]: 'TTI MEXICO'
}

const regionConfig = {
  [US_REGION]: {
    displayQuoteNotesTooltip: false,
    dateFormat: 'MM/DD/YYYY'
  },
  [MEXICO_REGION]: {
    displayQuoteNotesTooltip: true,
    disablePaymentTerms: true,
    disableReturnLocation: true,
    disableWarhouseId: true,
    dateFormat: 'DD/MM/YYYY'
  }
}

const state = {
  imitationCustomer: null,
  user: null,
  dashboardFilters: {
    customerAccount: null,
    employee: null,
    bu: null,
    pl1: null,
    pl2: null,
    pl3: null,
    sku: null,
    pceCode: null
  },
  selectedAccount: null,
  availableCustomers: [],
  usersList: [],
  plFilters: {},
  metRegion: MEXICO_REGION,
  businessUnit: null
}

const mutations = {
  SET_DASHBOARD_FILTER(filter) {
    Object.assign(this.dashboardFilters, filter)
  },
  ADD_ACCOUNTS_SEARCH(customers) {
    this.availableCustomers = this.availableCustomers.concat(customers)
  },
  ADD_USERS_LIST(users) {
    this.usersList = this.usersList.concat(users)
  },
  SET_SELECTED_ACCOUNT(account) {
    this.selectedAccount = Object.assign({}, account)
  },
  SET_USER_ERROR(userError) {
    this.userError = userError
  }
}

const actions = {
  setImitationCustomer(userId) {
    return api.user.setImitationCustomer(userId).then(async () => {
      await this.getImitationCustomer(userId)
    })
  },
  getImitationCustomer() {
    return api.user.getImitationCustomer().then((res) => {
      this.imitationCustomer = res.data
    })
  },
  resetImitationCustomer() {
    return (this.imitationCustomer = null)
  },
  async getUser() {
    try {
      const { data } = await api.user.getUser()
      const metRegion = data?.metRegion || MEXICO_REGION
      this.SET_USER_ERROR('')
      this.user = data
      this.metRegion = metRegion
      this.businessUnit = metBusinessUnit[metRegion]
      langStore().setLang(metRegionLang[data?.locale] || metRegionLang.es)
      rolesStore().setRoles(data?.roles)
      if (data?.roles.includes(ROLES[metRegion]['distributor'])) {
        const consents = await api.user.getConsents()
        if (consents.data.length) {
          router
            .push({ name: 'consents', params: { consents: consents.data } })
            .catch(() => {})
        }
      }
      return data
    } catch (e) {
      if (e.response.data?.message) {
        this.SET_USER_ERROR(e.response.data?.message || '')
      }
      router.push({ name: 'login-fail' })
      throw error
    }
  },
  getAccounts({
    searchData,
    pageSize = 20,
    hierarchy,
    currentPage,
    isLoadMore
  }) {
    return api.customers
      .getCustomers({ searchData, pageSize, hierarchy, currentPage })
      .then((res) => {
        if (isLoadMore) {
          this.ADD_ACCOUNTS_SEARCH(res.data.units)
        } else {
          this.availableCustomers = res.data.units
        }
      })
  },
  getUsersList({ currentPage, pageSize, isLoadMore }) {
    return api.user.getUsersList(currentPage, pageSize).then((res) => {
      if (isLoadMore) {
        this.ADD_USERS_LIST(res.data.employees || [])
      } else {
        this.usersList = res.data.employees || []
      }
    })
  },
  getPlFiltersList() {
    const { bu, pl1, pl2, pl3 } = this.dashboardFilters
    return api.user.getPlFiltersList(bu, pl1, pl2, pl3).then((res) => {
      this.plFilters = res.data
    })
  },
  setBusinessUnit(businessUnit) {
    this.businessUnit = businessUnit
  }
}

const getters = {
  isCustomerImitated: (state) => {
    return !!state.imitationCustomer
  },
  getConfig: (state) => (config) => regionConfig[state.metRegion][config],
  isMexicoRegion: (state) => state.metRegion === MEXICO_REGION,
  obrPhase: (state) => state.metRegion === US_REGION && !state.businessUnit
}

export const userStore = defineStore('user', {
  persist: true,
  state: () => ({
    ...state
  }),

  getters,

  actions: {
    ...mutations,
    ...actions
  }
})
