import BaseSearch from '@/lib/api/v1/search'

class Search extends BaseSearch {
  /**
   * Products Autosuggestion
   * @param {string} query
   * @param {boolean} allowReplacement
   * @param {boolean} hideBom
   * @param {boolean} suggestedSearch
   * @param {string} customerId
   * @param {string} cartType
   * @param {string} cartId
   * @returns {*}
   */
  searchProducts(
    query = '',
    allowReplacement = false,
    hideBom = false,
    suggestedSearch = true,
    customerId = null,
    cartType = null,
    cartId = null
  ) {
    const endpoint = '/rest/api/v3/products/autosuggestions'
    return this.transport.get(endpoint, null, {
      requestOptions: {
        params: {
          term: encodeURIComponent(query),
          allowReplacement,
          hideBom,
          suggestedSearch,
          customerId,
          cartType,
          cartId
        }
      }
    })
  }
}

export default Search
