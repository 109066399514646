import BaseOrders from '@/lib/api/v1/orders'

class Orders extends BaseOrders {
  getOrders(
    searchData,
    currentPage = 0,
    pageSize = 10,
    asc = false,
    code = 'byStartDate'
  ) {
    return this.transport.get('/rest/api/v3/orderhistory', null, {
      locale: '',
      requestOptions: {
        params: {
          ...searchData,
          currentPage,
          pageSize,
          asc,
          code
        }
      }
    })
  }

  getOrderDetails(orderNumber, businessUnit) {
    return this.transport.get(
      `/rest/api/v3/orderhistory/${orderNumber}`,
      null,
      {
        locale: '',
        requestOptions: {
          params: {
            businessUnit
          }
        }
      }
    )
  }

  getWorkflowApprovalAndAttachments(orderNumber) {
    return this.transport.get(
      `/rest/api/v3/order/${orderNumber}/approvalWorkflowAndAttachments`,
      null,
      { locale: '' }
    )
  }

  createOrder(data) {
    return this.transport.post(
      '/rest/api/v3/carts?orderStatus=ORDER_ON_HOLD',
      data,
      { locale: '' }
    )
  }

  downloadOrderHistoryXLSX(
    searchData,
    currentPage = 0,
    pageSize = 10,
    asc = false,
    code = 'byStartDate'
  ) {
    return this.transport.get('/rest/api/v3/order/export/xlsx', null, {
      locale: '',
      requestOptions: {
        responseType: 'blob',
        params: {
          ...searchData,
          currentPage,
          pageSize,
          asc,
          code
        }
      }
    })
  }

  downloadOrderDetailsXLSX(orderNumber, businessUnit) {
    return this.transport.get(
      `/rest/api/v3/order/${orderNumber}/export/xlsx`,
      null,
      {
        locale: '',
        requestOptions: {
          responseType: 'blob',
          params: {
            businessUnit
          }
        }
      }
    )
  }

  downloadUploadOrderTemplate(orderUploadType) {
    return this.transport.get(
      `/rest/api/v3/orders/upload/templates/${orderUploadType}`,
      null,
      { locale: '', requestOptions: { responseType: 'blob', params: null } }
    )
  }

  getUploadOrderHistory({
    code = 'uploaddate',
    asc = false,
    currentPage = 0,
    pageSize = 10
  }) {
    return this.transport.get('/rest/api/v3/orders/upload/history', null, {
      locale: '',
      requestOptions: {
        params: {
          code,
          asc,
          currentPage,
          pageSize
        }
      }
    })
  }

  getUploadOrderHistoryStatuses(fileUids) {
    const searchParam = fileUids.join(',')
    return this.transport.get(
      `/rest/api/v3/orders/upload/importStatus?entries=${searchParam}`,
      null,
      { locale: '' }
    )
  }

  async getStats() {
    return {}
  }
}

export default Orders
