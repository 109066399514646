import { i18n } from '@root/i18n'
import { defineStore } from 'pinia'

import settings from '@/lib/core/settings'
import storedSessions from '@/lib/core/stored-sessions'

const state = {
  localeId: settings.i18n.defaultLocale
}

const getters = {
  getCurrentLocale: (state) => state.localeId
}

const actions = {
  setLang(localeId) {
    this.localeId = localeId
    i18n.locale = localeId
    storedSessions.saveLanguage(localeId)
  }
}

export const langStore = defineStore('lang', {
  persist: true,
  state: () => ({
    ...state
  }),

  getters,
  actions
})
