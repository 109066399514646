import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Addresses extends ApiBase {
  /**
   * Get API endpoint base URL.
   * @returns {string}
   */
  getBaseUrl() {
    const userId = auth.getCurrentUserId()
    return this.getEndpointUrl(`/addresses/${userId}`)
  }

  /**
   * Get all addresses.
   * @returns {Promise<any>}
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getBaseUrl())
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Get address by ID.
   * @param id
   * @returns {Promise<any>}
   */
  getById(id) {
    const url = `${this.getBaseUrl()}/${id}`
    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Create a new address.
   * @param data
   * @returns {Promise<any>}
   */
  create(data) {
    return new Promise((resolve, reject) => {
      this.transport
        .post(this.getBaseUrl(), data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Update address by ID.
   * @param id
   * @param data
   * @returns {Promise<any>}
   */
  update(id, data) {
    const url = `${this.getBaseUrl()}/${id}`
    return new Promise((resolve, reject) => {
      this.transport
        .patch(url, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Remove address by ID.
   * @param id
   * @returns {Promise<any>}
   */
  remove(id) {
    const url = `${this.getBaseUrl()}/${id}`
    return new Promise((resolve, reject) => {
      this.transport
        .delete(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Addresses
