<template>
  <div class="mt-help-nav text-left m-3">
    <div class="text-white font-normal uppercase mb-4 text-base">
      {{ $t('footer.title') }}
    </div>
    <ul class="p-0 m-0 list-none grid grid-cols-2 gap-x-8">
      <li class="p-0 pt-3">
        <a
          class="hover:text-gray-50 no-underline"
          :href="`${manualAndDownloadsUrl}`"
          target="_blank"
          >{{ $t('footer.manuals') }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { userStore } from '@/stores/user'

export default {
  name: 'HelpNav',
  computed: {
    ...mapState(userStore, ['isMexicoRegion']),
    manualAndDownloadsUrl() {
      if (this.isMexicoRegion) {
        return 'https://www.milwaukeetool.mx/Support/Manuals-and-Downloads'
      }

      return 'https://www.milwaukeetool.com/Support/Manuals-and-Downloads'
    }
  }
}
</script>

