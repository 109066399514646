import Transport from '@/lib/api/transport'
import Addresses from '@/lib/api/v1/addresses'
import Auth from '@/lib/api/v1/auth'
import Billing from '@/lib/api/v1/billing'
import Cart from '@/lib/api/v1/cart'
import Content from '@/lib/api/v1/content'
import Customers from '@/lib/api/v1/customers'
import Data from '@/lib/api/v1/data'
import Invoices from '@/lib/api/v1/invoices'
import Notifications from '@/lib/api/v1/notifications'
import Orders from '@/lib/api/v1/orders'
import Products from '@/lib/api/v1/products'
import Quotes from '@/lib/api/v1/quotes'
import Search from '@/lib/api/v1/search'
import Settings from '@/lib/api/v1/settings'
import Shipping from '@/lib/api/v1/shipping'
import User from '@/lib/api/v1/user'

class Api {
  static instance = null

  /**
   * Initialize a new instance of the API class.
   * @param settings
   * @return {*}
   */
  constructor(settings = null) {
    if (Api.instance) {
      return Api.instance
    }
    Api.instance = this
    this.initApiModules(settings)
  }

  /**
   * Initialize API modules.
   * @param settings
   */
  initApiModules(settings) {
    this.transport = new Transport(settings.services.api.baseUrl)
    this.user = new User(this.transport)
    this.orders = new Orders(this.transport)
    this.products = new Products(this.transport)
    this.cart = new Cart(this.transport)
    this.shipping = new Shipping(this.transport)
    this.billing = new Billing(this.transport)
    this.auth = new Auth(this.transport, settings.services.api.apiKey)
    this.content = new Content(this.transport)
    this.settings = new Settings(this.transport)
    this.search = new Search(this.transport)
    this.data = new Data(this.transport)
    this.addresses = new Addresses(this.transport)
    this.notifications = new Notifications(this.transport)
    this.customers = new Customers(this.transport)
    this.invoices = new Invoices(this.transport)
    this.quotes = new Quotes(this.transport)
  }
}

export default Api
