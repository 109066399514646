<template>
  <div class="image-loader" :class="{ loading }">
    <img
      v-if="!loaded && !loading"
      :src="$settings.client.imagePlaceholder"
      class="image-placeholder"
    />
    <img v-if="loaded" :src="imageUrl" />
  </div>
</template>
<script>
export default {
  name: 'ImageLoader',
  props: {
    src: {
      type: Object || String,
      default: ''
    }
  },
  data() {
    return {
      imageUrl: '',
      loading: false,
      loaded: false
    }
  },
  created() {
    if (this.src) {
      if (typeof this.src === 'object') {
        if (this.src.PRIMARY) {
          this.imageUrl =
            this.src.PRIMARY.product || this.src.PRIMARY.thumbnail || ''
        } else {
          this.imageUrl = 'url' in this.src ? this.src.url : ''
        }
      } else {
        this.imageUrl = this.src
      }
    }
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.imageUrl || !this.imageUrl.length) {
        this.loaded = false
        return
      }

      const image = new Image()
      this.loading = true

      image.onload = () => {
        this.loading = false
        this.loaded = true
      }
      image.onerror = () => {
        this.loading = false
      }
      image.src = this.imageUrl
    }
  }
}
</script>
