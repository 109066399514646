import api from '@/lib/api'
import ApiBase from '@/lib/api/v1/api-base'

class Auth0Api extends ApiBase {
  constructor() {
    super(api.transport)
  }

  async createSession(credentials) {
    return this.transport.post(this.getEndpointUrl('/user/login'), credentials)
  }
}

export default Auth0Api
