/**
 * Compare two DOM nodes.
 * @param a
 * @param b
 * @return {boolean}
 */
const compareNodes = (a, b) => (!a.isSameNode ? a === b : a.isSameNode(b))

/**
 * Check whether node is equal or is a children of the given possible parent.
 * @param node
 * @param possibleParent
 */
const hasParentLike = (node, possibleParent) => {
  for (; node && node !== document; node = node.parentNode) {
    if (compareNodes(node, possibleParent)) {
      return true
    }
  }
  return false
}

/**
 * Click outside mixin factory.
 * @param ignoreSelector
 * @param eventName
 * @return {{created(): void, methods: {clickedOutside(*): void}, beforeDestroy(): void}}
 * @constructor
 */
const ClickOutside = (ignoreSelector = [], eventName = 'clicked-outside') => {
  return {
    created() {
      window.addEventListener('click', this.clickedOutside)
    },
    methods: {
      clickedOutside(ev) {
        if (!ignoreSelector.length) {
          this.$emit(eventName, ev.target)
          return
        }

        let ignore = false
        for (const selector of ignoreSelector) {
          const ignoreElement = document.querySelector(selector)
          if (ignoreElement && hasParentLike(ev.target, ignoreElement)) {
            ignore = true
            break
          }
        }
        if (!ignore) {
          this.$emit(eventName, ev.target)
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('click', this.clickedOutside)
    }
  }
}

export { ClickOutside }
