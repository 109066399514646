import { defineStore } from 'pinia'

import api from '@/lib/api'
import { Order } from '@/lib/models/orders/order'

const state = {
  ordersList: [],
  paginationData: {},
  currentOrder: {},
  currentWorkflowApprovalsAndAttachments: {}
}
const mutations = {
  SET_PAGINATION_DATA(paginationData) {
    paginationData.page = Number(paginationData.page)
    this.paginationData = paginationData
  }
}
const actions = {
  transformOrder(order) {
    return new Order(order)
  },

  async transformOrders(orders) {
    return await Promise.all(
      orders.map(async (order) => await this.transformOrder(order))
    )
  },

  async getOrders({ searchData, currentPage, pageSize, asc, code }) {
    try {
      const res = await api.orders.getOrders(
        searchData,
        currentPage,
        pageSize,
        asc,
        code
      )

      const transformedOrders = await this.transformOrders(res.data.orders)
      this.ordersList = transformedOrders
      this.SET_PAGINATION_DATA(res.data.pagination)

      return res.data
    } catch (_error) {
      this.ordersList = []
      this.SET_PAGINATION_DATA({})
    }
  },

  async getOrderDetails({ orderNumber, businessUnit }) {
    try {
      const res = await api.orders.getOrderDetails(orderNumber, businessUnit)
      const transformedOrder = await this.transformOrder(res.data)

      this.currentOrder = transformedOrder
    } catch (_error) {
      this.currentOrder = null
    }
  },
  downloadOrderHistoryXLSX({ searchData, currentPage, pageSize, asc, code }) {
    return api.orders.downloadOrderHistoryXLSX(
      searchData,
      currentPage,
      pageSize,
      asc,
      code
    )
  },
  downloadOrderDetailsXLSX({ orderNumber, businessUnit }) {
    return api.orders.downloadOrderDetailsXLSX(orderNumber, businessUnit)
  },
  async getWorkflowApprovalAndAttachments(orderNumber) {
    try {
      const res = await api.orders.getWorkflowApprovalAndAttachments(
        orderNumber
      )
      this.currentWorkflowApprovalsAndAttachments = res.data
    } catch (_error) {
      this.currentWorkflowApprovalsAndAttachments = null
    }
  }
}

export const ordersStore = defineStore('orders', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions: {
    ...mutations,
    ...actions
  }
})
