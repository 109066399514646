import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { DefaultQuoteProduct } from '@/lib/models/product/product'
import { getInternalformatDate } from '@/lib/utils/formatDate'
import { getCartDisabledStatusByCartStatus } from '@/lib/utils/helpers'
import { validateOrderSubmit } from '@/lib/utils/validation'

import { quotesStore } from '@/stores/quotes'
import { rolesStore } from '@/stores/roles'

export class Quote {
  constructor(cart) {
    const isDistributorRole = rolesStore().distributor

    this.header = {
      APIType: cart?.header?.APIType ? cart.header.APIType : '',
      cartCode: cart?.header?.cartCode ? cart.header.cartCode : '',
      cartStatus: cart?.header?.cartStatus ? cart.header.cartStatus : '',
      createdBy: cart?.header?.createdBy ? cart.header.createdBy : '',
      currency: cart?.header?.currency ? cart.header.currency : '',
      customerAccount: cart?.header?.customerAccount
        ? cart.header.customerAccount
        : '',
      customerName: cart?.header?.customerName ? cart.header.customerName : '',
      errorCode: cart?.header?.errorCode ? cart.header.errorCode : '',
      errorMessage: cart?.header?.errorMessage ? cart.header.errorMessage : '',
      formattedCurrency: cart?.header?.formattedCurrency
        ? cart.header.formattedCurrency
        : '',
      invoiceAccount: cart?.header?.invoiceAccount
        ? cart.header.invoiceAccount
        : '',
      lastUpdatedOn: cart?.header?.lastUpdatedOn
        ? cart.header.lastUpdatedOn
        : '',
      priceDate: cart?.header?.priceDate
        ? getInternalformatDate(cart.header.priceDate)
        : '',
      quoteCreationDate: cart?.header?.quoteCreationDate
        ? getInternalformatDate(cart.header.quoteCreationDate)
        : '',
      quoteExpirationDate: cart?.header?.quoteExpirationDate
        ? getInternalformatDate(cart.header.quoteExpirationDate)
        : '',
      returnReasonCode: cart?.header?.returnReasonCode
        ? cart.header.returnReasonCode
        : '',
      salesId: cart?.header?.salesId ? cart.header.salesId : '',
      salesOriginId: cart?.header?.salesOriginId
        ? cart.header.salesOriginId
        : '',
      salesType: cart?.header?.salesType ? cart.header.salesType : '',
      shortTypeCode: cart?.header?.shortTypeCode
        ? cart.header.shortTypeCode
        : '',
      token: cart?.header?.token ? cart.header.token : '',
      type: cart?.header?.type ? cart.header.type : 'None'
    }
    this.charges = cart?.charges ? cart.charges : null
    this.deliveryAddress = cart?.deliveryAddress ? cart.deliveryAddress : null
    this.paymentAddress = cart?.paymentAddress ? cart.paymentAddress : null
    this.lines = cart?.lines ? cart?.lines : []
    this.summary = {
      subTotal: {
        value: cart?.summary?.subTotal?.value
          ? cart.summary.subTotal.value
          : '',
        formattedValue: cart?.summary?.subTotal?.formattedValue
          ? cart.summary.subTotal.formattedValue
          : ''
      },
      totalDiscounts: cart?.summary?.totalDiscounts
        ? cart.summary.totalDiscounts
        : '',
      totalTaxes: cart?.summary?.totalTaxes ? cart.summary.totalTaxes : '',
      totalShipping: cart?.summary?.totalShipping
        ? cart.summary.totalShipping
        : '',
      totalPrice: cart?.summary?.totalPrice ? cart.summary.totalPrice : '',
      totalItems: cart?.summary?.totalItems ? cart.summary.totalItems : '',
      taxCalculationStatus: cart?.summary?.taxCalculationStatus
        ? cart.summary.taxCalculationStatus
        : null
    }
    this.account = {
      contactName: cart?.account?.contactName ? cart.account.contactName : '',
      contactEmail: cart?.account?.contactEmail
        ? cart.account.contactEmail
        : '',
      ...(!isDistributorRole && {
        customerRequirements: cart?.account?.customerRequirements
          ? cart.account.customerRequirements
          : ''
      }),
      invoiceAccount: cart?.account?.invoiceAccount
        ? cart.account.invoiceAccount
        : '',
      salesContact1: cart?.account?.salesContact1
        ? cart.account.salesContact1
        : '',
      salesContact2: cart?.account?.salesContact2
        ? cart.account.salesContact2
        : '',
      sendOrderAcknowledgementEmail: cart?.account
        ?.sendOrderAcknowledgementEmail
        ? cart.account.sendOrderAcknowledgementEmail
        : false
    }
    this.orderManagement = isDistributorRole
      ? null
      : {
          salesOriginId: cart?.orderManagement?.salesOriginId
            ? cart.orderManagement.salesOriginId
            : '',
          priceEffectiveDate: cart?.orderManagement?.priceEffectiveDate
            ? getInternalformatDate(cart.orderManagement.priceEffectiveDate)
            : ''
        }
    this.payment = {
      PO: cart?.payment?.PO ? cart.payment.PO : '',
      paymentTerms: cart?.payment?.paymentTerms ? cart.payment.paymentTerms : ''
    }
    this.fulfillment = isDistributorRole
      ? {
          allowBackorder: cart?.fulfillment?.allowBackorder
            ? cart.fulfillment.allowBackorder
            : false,
          allowReplacements: cart?.fulfillment?.allowReplacements
            ? cart.fulfillment.allowReplacements
            : false
        }
      : {
          warehouseId: cart?.fulfillment?.warehouseId
            ? cart.fulfillment.warehouseId
            : '',
          allowBackorder: cart?.fulfillment?.allowBackorder
            ? cart.fulfillment.allowBackorder
            : false,
          allowReplacements: cart?.fulfillment?.allowReplacements
            ? cart.fulfillment.allowReplacements
            : false,
          allowPartialLineShipment: cart?.fulfillment?.allowPartialLineShipment
            ? cart.fulfillment.allowPartialLineShipment
            : false,
          allowDropship: cart?.fulfillment?.allowDropship
            ? cart.fulfillment.allowDropship
            : false
        }
    this.shipping = isDistributorRole
      ? null
      : {
          deliveryMode: cart?.shipping?.deliveryMode
            ? cart.shipping.deliveryMode
            : '',
          deliveryReason: cart?.shipping?.deliveryReason,
          carrierAccount: cart?.shipping?.carrierAccount
            ? cart.shipping.carrierAccount
            : '',
          applyFreight: cart?.shipping?.applyFreight
            ? cart.shipping.applyFreight
            : false,
          shippingDateRequested: cart?.shipping?.shippingDateRequested
            ? getInternalformatDate(cart.shipping.shippingDateRequested)
            : '',
          defaultShippingDate: cart?.shipping?.defaultShippingDate
            ? getInternalformatDate(cart.shipping.defaultShippingDate)
            : '',
          isResidentialAddress: cart?.shipping?.isResidentialAddress
            ? cart?.shipping?.isResidentialAddress
            : false
        }
    this.other = isDistributorRole
      ? null
      : {
          notes: cart?.other?.notes ? cart.other.notes : [],
          reasonCode: cart?.other?.reasonCode ? cart.other.reasonCode : '',
          specialPromotionDenialNotes: cart?.other?.specialPromotionDenialNotes
            ? cart.other.specialPromotionDenialNotes
            : null,
          workflow:
            cart?.other?.workflow && Object.keys(cart.other.workflow).length > 0
              ? {
                  type: cart?.other?.workflow?.type,
                  assignee: cart?.other?.workflow?.assignee,
                  startDate: cart?.other?.workflow?.startDate,
                  createdBy: cart?.other?.workflow?.createdBy,
                  rejectReason: cart?.other?.workflow?.rejectReason,
                  rejectNote: cart?.other?.workflow?.rejectNote,
                  status: cart?.other?.workflow?.status
                }
              : null
        }

    this.internalNotes = cart?.internalNotes ? cart.internalNotes : ''

    this.marginCalculator = cart?.marginCalculator
      ? { ...cart.marginCalculator }
      : null

    this.attachments = cart?.attachments ? cart.attachments : []

    this.id = cart?.header?.cartCode ? cart.header.cartCode : ''

    this.autoSave = false

    this.validationErrors = cart?.validationErrors || {}

    this.isDisabledProductSuggest = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.isDisabledQtyInput = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.isDisabledPromoCell = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.isDisabledFreeGoodCell = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.cartType = CART_TYPES.QUOTE.currentCart
    this.isDisabledByStatus = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
  }

  updateField(payload, withPatchRequest = true) {
    if (payload.path === 'fulfillment.allowReplacements') {
      quotesStore().CLEAR_NEW_PRODUCT()
    }
    quotesStore().updateQuoteField(payload)
    if (withPatchRequest) return quotesStore().quoteFieldChanged()
  }

  setNoteField(payload) {
    quotesStore().setNoteQuoteField(payload)
  }

  createDefaultProduct() {
    return new DefaultQuoteProduct()
  }

  createNew() {
    return quotesStore().isOrder
      ? quotesStore().createOrder()
      : quotesStore().createQuote()
  }

  submitValidationRules(action = 'SUBMIT') {
    return {
      account: {
        contactEmail: {
          required: this.account.sendOrderAcknowledgementEmail
        },
        contactName: {
          required: this.account.sendOrderAcknowledgementEmail
        }
      },
      orderManagement: {
        priceEffectiveDate: {
          required: true,
          date: true
        },
        salesOriginId: {
          required: true
        }
      },
      fulfillment: {
        warehouseId: {
          required: true
        }
      },
      shipping: {
        shippingDateRequested: {
          required: true,
          date: true,
          lessThanSystemDate: true
        },
        deliveryMode: {
          required: true
        }
      },
      payment: {
        PO: {
          required: action === 'SUBMIT'
        },
        paymentTerms: {
          required: true
        }
      }
    }
  }

  validateSubmitOrderPayload(action) {
    const validationRules = this.submitValidationRules(action)
    const sections = rolesStore().distributor
      ? ['account', 'payment']
      : ['account', 'orderManagement', 'fulfillment', 'shipping', 'payment']
    return validateOrderSubmit(sections, this, validationRules)
  }
}
