<template>
  <transition name="mt-navigation-mobile">
    <nav
      v-show="isNavVisibleLocal"
      class="mt-navigation-mobile__nav mt-z-400 fixed top-0 bottom-0 left-0 hidden lg:flex flex-col transition duration-300 ease-in-out"
    >
      <div
        class="hidden lg:flex justify-between items-center bg-primary-500 py-2 px-5 border-b border-white text-white"
      >
        <div class="w-20">
          <logo />
        </div>
        <button
          class="flex justify-center items-center w-8 h-8 text-white cursor-pointer"
          @click="closeNav"
        >
          <span class="material-icons-outlined">close</span>
        </button>
      </div>

      <div
        v-if="user"
        class="hidden lg:flex items-center bg-primary-500 py-3 px-5 text-white"
      >
        <div
          class="mt-navigation-mobile__avatar rounded-full mr-3 border-2 border-white flex items-center justify-center"
        >
          <vue-letter-avatar :name="firstName" size="26" :rounded="true" />
        </div>
        <div class="truncate">
          <div class="text-sm font-semibold">{{ user.name }}</div>
          <div class="text-xs truncate">{{ user.email }}</div>
        </div>
      </div>

      <div class="flex flex-col flex-grow bg-white overflow-y-auto">
        <ul class="hidden lg:block list-none">
          <li v-for="link in links" :key="link.id">
            <router-link
              :to="link.path"
              class="flex justify-between items-center py-4 px-5 border-b border-gray-200 font-semibold hover:no-underline focus-within:no-underline"
            >
              {{ link.title }}
            </router-link>
          </li>
        </ul>
        <div class="flex justify-center py-10 px-7">
          <app-button color="secondary" class="mr-2">New Quote</app-button>
          <app-button color="secondary">New Order</app-button>
        </div>
      </div>
    </nav>
  </transition>
</template>

<script>
import { mapState } from 'pinia'

import AppButton from '@/components/common/AppButton'
import Logo from '@/components/common/Logo'

import { userStore } from '@/stores/user'

export default {
  name: 'NavigationMobile',
  components: { Logo, AppButton },
  props: {
    isNavVisible: {
      type: Boolean,
      required: true
    },
    links: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(userStore, ['user']),
    isNavVisibleLocal: {
      get() {
        return this.isNavVisible
      },
      set(value) {
        this.$emit('isNavVisibleChange', value)
      }
    },
    firstName() {
      return this.user?.firstName || 'Undefined'
    }
  },
  watch: {
    $route() {
      this.closeNav()
    }
  },
  methods: {
    closeNav() {
      this.isNavVisibleLocal = false
    },
    closeMenu() {
      this.isMenuVisible = false
    },
    openMenu() {
      this.isMenuVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.mt-navigation-mobile {
  transform: translateX(-300px);
  &__nav {
    width: 300px;
  }
  &__avatar {
    width: 30px;
    height: 30px;
  }
}
</style>
