import ModalConfirmation from './ModalConfirmation'

/**
 * Modal dialog
 */
class ModalDialog {
  /**
   * ModalDialog constructor.
   * @param Vue
   * @param i18n
   */
  constructor(Vue, options) {
    const defaults = {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }

    options = Object.assign(defaults, options)
    this.Vue = Vue
    this.mounted = false
    this.confirmButtonText = options.confirmButtonText
    this.cancelButtonText = options.cancelButtonText
    this.$root = {}
  }

  /**
   * Mount component
   */
  mount() {
    if (this.mounted) {
      return
    }

    this.mounted = true
    this.$root = (() => {
      const Dialog = this.Vue.extend(ModalConfirmation)
      const node = document.createElement('div')

      document.querySelector('body').appendChild(node)
      return new Dialog().$mount(node)
    })()
  }

  /**
   * Destroy component
   */
  destroy() {
    if (!this.mounted) {
      return
    }

    const domElement = this.$root.$el
    this.$root.$destroy()
    this.$root.$off()
    domElement.remove()

    this.mounted = false
  }

  /**
   * Close any active confirmation windows.
   */
  closeAll() {
    if (this.$root && this.$root.hide) {
      this.$root.hide()
    }
  }

  /**
   * Display a message box.
   * @param title
   * @param message
   * @param options
   * @returns {Promise<any>}
   */
  messageBox(title, message, options = {}) {
    this.mount()

    const defaults = {
      messageType: 'info',
      cancelButton: false,
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText
    }

    options = Object.assign(defaults, options)
    return new Promise((resolve) => {
      this.$root.title = title
      this.$root.message = message
      this.$root.confirmButtonText = options.confirmButtonText
      this.$root.cancelButtonText = options.cancelButtonText
      this.$root.showCancelButton = options.cancelButton
      this.$root.type = options.messageType
      this.$root.show()

      const root = this.$root
      this.$root.$on('yes', () => {
        setTimeout(() => {
          root.hide()
          if (resolve) {
            resolve(true)
          }
        }, 1)
      })
      this.$root.$on('no', () => {
        setTimeout(() => {
          root.hide()
          if (resolve) {
            resolve(false)
          }
        }, 1)
      })
    })
  }
}

export default {
  /**
   * @param Vue
   * @param options
   * @returns {ModalDialog}
   */
  install(Vue, options) {
    Vue.dialog = new ModalDialog(Vue, options)
    Object.defineProperties(Vue.prototype, {
      $dialog: {
        get() {
          return Vue.dialog
        }
      }
    })
  }
}
