import get from 'lodash/get'

import routes from '@/router/routes'

export default {
  /**
   * Find a route and check whether it's root route or not.
   * @param name
   * @returns {*|boolean}
   */
  isRoot(name) {
    return get(this.find(name), 'root', false)
  },

  /**
   * Get root route.
   * @returns {*}
   */
  getRoot: () => routes.find((item) => item.root === true),

  /**
   * Get parent route name.
   * @param name
   * @returns {null}
   */
  getParent(name) {
    return get(this.find(name), 'parent', null)
  },

  /**
   * Find route by its name.
   * @param name
   * @returns {*}
   */
  find(name) {
    return routes.find((item) => item.name === name)
  },

  /**
   * Get route's property.
   * @param routeName
   * @param property
   * @param defaultValue
   * @return {*}
   */
  getProperty(routeName, property, defaultValue = false) {
    return get(this.find(routeName), property, false)
  },

  /**
   * Check whether the given route is public or not.
   * @param name
   * @return {*}
   */
  isPublic(name) {
    return this.getProperty(name, 'publicRoute')
  },

  /**
   * Check whether the page will use a simplified view with no header or footer
   * @param name
   * @return {*}
   */
  isSimpleView(name) {
    return this.getProperty(name, 'simpleView')
  },

  /**
   * Check whether the given route is public or not.
   * @param name
   * @return {*}
   */
  isFatalError(name) {
    return this.getProperty(name, 'fatalError')
  },

  /**
   * Check whether the route has setting to hide breadcrumbs or not.
   * @param name
   * @return {*}
   */
  isHideBreadcrumbs(name) {
    return this.getProperty(name, 'hideBreadcrumbs')
  }
}
