<template>
  <div>
    <div class="mt-overlay" @click="cancelIdle"></div>
    <div
      class="fixed transform left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 mt-z-600 w-full mt-max-width--380 text-center p-16 bg-white shadow-lg"
    >
      <p>{{ $t('idle.message') }}</p>
      <div class="text-7xl font-bold my-4">
        {{ $t('idle.time', { seconds: secondsLeft }) }}
      </div>
      <app-button class="my-0 mx-auto" @click="cancelIdle">{{
        $t('idle.cancel')
      }}</app-button>
    </div>
  </div>
</template>
<script>
import disableBodyScroll from '@/lib/utils/disableBodyScroll'

import AppButton from '@/components/common/AppButton'
import Idle from '@/components/common/Idle'

export default {
  name: 'Idle',
  components: {
    AppButton
  },
  extends: Idle,
  mounted() {
    disableBodyScroll(true)
  },
  methods: {
    cancelIdle() {
      disableBodyScroll(false)
      this.cancel()
    }
  }
}
</script>

