<template>
  <div class="mt-account relative">
    <button
      class="mt-account__toggle-btn ml-5 relative bg-white rounded-lg w-11 h-11 cursor-pointer"
      @click="toggleMenuState"
    >
      <vue-letter-avatar
        class="mx-auto my-0"
        :name="firstName"
        size="32"
        :rounded="true"
      />
    </button>

    <account-menu v-if="isAccountMenuVisible" @closeMenu="closeMenu" />

    <transition name="fade">
      <div v-show="isAccountMenuVisible" class="back-layer"></div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { ClickOutside } from '@/lib/utils/click-outside'

import AccountMenu from '@/components/common/AppHeader/AccountMenu'

import { userStore } from '@/stores/user'

export default {
  name: 'Account',
  components: { AccountMenu },
  mixins: [ClickOutside(['.mt-account__toggle-btn'])],
  props: {
    isAccountMenuVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasNotifications: true,
      notificationsCount: 10
    }
  },
  computed: {
    ...mapState(userStore, ['user']),
    firstName() {
      return this.user?.firstName || 'Undefined'
    }
  },
  mounted() {
    this.$on('clicked-outside', this.closeMenu)
  },
  beforeDestroy() {
    this.$off('clicked-outside', this.closeMenu)
  },
  methods: {
    toggleMenuState() {
      this.$emit('changeAccountMenuState', !this.isAccountMenuVisible)
    },
    closeMenu() {
      this.$emit('changeAccountMenuState', false)
    }
  }
}
</script>
