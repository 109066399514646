<template>
  <app-button
    :color="color"
    :class-list="classList"
    :variant="variant"
    @click="create"
  >
    <slot>
      <span class="block sm:hidden">{{ title }}</span>
    </slot>
  </app-button>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import AppButton from '@/components/common/AppButton'

import { quotesStore } from '@/stores/quotes'

export default {
  name: 'CreateBtn',
  components: {
    AppButton
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    variant: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    classList: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(quotesStore, ['isReasonCodeFilled', 'currentQuoteCustomerId']),
    isQuotePage() {
      return this.$route.name === 'quote-form'
    }
  },
  methods: {
    ...mapActions(quotesStore, ['showReasonCodeModal']),
    create() {
      if (
        this.isQuotePage &&
        !this.isReasonCodeFilled &&
        this.currentQuoteCustomerId
      ) {
        this.showReasonCodeModal()

        return
      }
      this.$emit('create')
    }
  }
}
</script>

<style>

</style>
