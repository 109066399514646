<template src="@/templates/common/dialog/ModalConfirmation"></template>
<script>
import ModalMessage from './ModalMessage'

export default {
  name: 'ModalConfirmation',
  components: { ModalMessage },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Title'
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'info'
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: 'Yes'
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'No'
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
