import { UserTitle, UserSignupData } from 'zcmodels'

import ApiBase from '@/lib/api/v1/api-base'

class User extends ApiBase {
  /**
   * Get user titles.
   * @returns {Promise<any>}
   */
  getTitles() {
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getEndpointUrl('/data/titles'))
        .then((response) => {
          const titles = []
          if (response.data && response.data.length) {
            response.data.forEach((item) => {
              titles.push(new UserTitle(item))
            })
          }
          resolve(titles)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Confirm signup by username and verification code.
   * @param username
   * @param verificationCode
   * @param userSub - id in cognito pool
   * @return {Promise<unknown>}
   */
  confirmSignup(username, verificationCode, userSub) {
    return new Promise((resolve, reject) => {
      if (!username || !verificationCode) {
        reject(new Error('Invalid data'))
      }

      this.transport
        .post(this.getEndpointUrl('/user/signup-confirm'), {
          username,
          verificationCode,
          userSub
        })
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          if (reason.response && reason.response.data.message) {
            reject(new Error(reason.response.data.message))
          } else {
            reject(reason)
          }
        })
    })
  }

  /**
   * Resend confirmation code.
   * @param username
   * @return {Promise<unknown>}
   */
  resendConfirmation(username) {
    return new Promise((resolve, reject) => {
      if (!username) {
        reject(new Error('Invalid data'))
      }

      this.transport
        .post(this.getEndpointUrl('/user/signup-resend-code'), { username })
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          if (reason.response && reason.response.data.message) {
            reject(new Error(reason.response.data.message))
          } else {
            reject(reason)
          }
        })
    })
  }

  /**
   * Create a new account.
   * @param userSignupData
   * @returns {Promise<any>}
   */
  signup(userSignupData) {
    return new Promise((resolve, reject) => {
      if (!(userSignupData instanceof UserSignupData)) {
        reject(new Error('Invalid data'))
      }

      this.transport
        .post(this.getEndpointUrl('/user/signup'), userSignupData)
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          if (reason.response && reason.response.data.message) {
            reject(new Error(reason.response.data.message))
          } else {
            reject(reason)
          }
        })
    })
  }

  /**
   * Get User account info.
   * @param username
   * @return {Promise<any>}
   */
  accountDetails(username) {
    return new Promise((resolve, reject) => {
      this.transport
        .get(this.getEndpointUrl(`/user/${encodeURIComponent(username)}`))
        .then((response) => {
          if (!response.data || !Object.keys(response.data).length) {
            reject(new Error('Failed to retrieve account details'))
          } else {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  /**
   * Update user's profile.
   * @param username
   * @param profileData
   * @returns {*|void|AxiosPromise<any>}
   */
  updateProfile(username, profileData) {
    const url = this.getEndpointUrl(`/user/${encodeURIComponent(username)}`)
    return this.transport.patch(url, profileData)
  }
}

export default User
