import { defineStore } from 'pinia'

const state = {
  previousRoute: null
}

const actions = {
  /**
   * Set object containing information about the previous route.
   * @param route
   */
  setPreviousRoute(route) {
    this.previousRoute = route
  }
}

export const appStore = defineStore('app', {
  state: () => ({
    ...state
  }),

  actions
})
