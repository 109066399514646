const files = require.context('.', false, /\.js$/)
import stores from '@extension/stores'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Vue, { markRaw } from 'vue'

import router from '@/router'

localStorage.removeItem('vuex') // remove vuex persisted state

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

/**
 * The bootstrapPiniaStores enables `@/stores/index.js` to import all pinia modules
 * in a one-shot manner.
 */
export const bootstrapPiniaStores = () => {
  const modules = {}

  Object.keys(stores).forEach((key) => {
    if (key === './index.js') return
    modules[key.replace(/Store/, '')] = stores[key]()
  })

  files.keys().forEach((key) => {
    if (key === './index.js') return
    modules[key.replace(/(\.\/|\.js)/g, '')] =
      files(key)[Object.keys(files(key))[0]]()
  })

  return modules
}

export default pinia
