import coreAuth from '@/lib/core/auth'
import routes from '@/lib/utils/routes'

import { getInstance } from './plugin'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  authService.whenReady(() => {
    const fn = () => {
      // bypass auth for public routes
      if (routes.isPublic(to.name)) {
        return next()
      }

      if (authService.isAuthenticated) {
        if (!coreAuth.getIsLoggedIn()) {
          authService.updateUserDetails()
        }
        return next()
      }

      authService.loginWithRedirect({
        appState: { targetUrl: to.query.redirect }
      })
    }

    if (!authService.loading) {
      return fn()
    }

    authService.$watch('loading', (loading) => {
      if (!loading) {
        return fn()
      }
    })
  })
}
