import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Quotes extends ApiBase {
  /**
   * Get list of quotes.
   * @param userQuery
   * @returns {Promise<any>}
   */
  getQuotes(userQuery) {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/quotes/${userId}`)

    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Quotes
