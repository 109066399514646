import { getUniqueId } from '@/lib/utils/helpers'

export class Product {
  constructor(product) {
    const isBaseProduct =
      product &&
      'matrix' in product &&
      'purchasable' in product &&
      product.purchasable === false
    const isVariantProduct =
      product &&
      'matrix' in product &&
      'purchasable' in product &&
      product.purchasable === true
    if (isBaseProduct) {
      this.code = product.code
      this.purchasable = false
      this.images = product?.productImages ? product.productImages : ''
      this.matrix = product.matrix
      this.isBaseProduct = true
    } else {
      if (isVariantProduct) {
        this.matrix = product.matrix
        this.isVariantProduct = true
      }
      this.batterySystem = product?.batterySystem ? product.batterySystem : ''
      this.caseConversionFactor = product?.caseConversionFactor
        ? product.caseConversionFactor
        : ''
      this.code = product?.code ? product.code : ''
      this.height = product?.height ? product.height : null
      this.images = product?.productImages ? product.productImages : []
      this.mainImage = product?.mainImage ? product.mainImage : ''
      this.length = product?.length ? product.length : ''
      this.lowestQtyAllowed = product?.lowestQtyAllowed
        ? product.lowestQtyAllowed
        : ''
      this.multipleQty = product?.multipleQty ? product.multipleQty : ''
      this.name = product?.name ? product.name : ''
      this.overviewHtml = product?.overviewHtml ? product.overviewHtml : ''
      this.palletConversionFactor = product?.palletConversionFactor
        ? product.palletConversionFactor
        : ''
      this.powerSource = product?.powerSource ? product.powerSource : ''
      this.featuresHtml = product?.featuresHtml ? product.featuresHtml : ''
      this.replacementProduct = product?.replacementProduct
        ? product.replacementProduct
        : ''
      this.replacementStatus = product?.replacementStatus
        ? product.replacementStatus
        : ''
      this.weight = product?.weight ? product.weight : null
      this.width = product?.width ? product.width : null

      this.categories = product?.categories
      this.includedItems = product?.includedItems
      this.stockStatus = product?.stockStatus
      this.relatedAccessories = product?.relatedAccessories
      this.relatedProducts = product?.relatedProducts
      this.purchasable = product?.purchasable
    }
  }
}

class DefaultProduct {
  constructor() {
    this.itemNumber = ''
    this.quantity = 1
    this.netPrice = 0
    this.stockStatus = null
    this.isProductFound = false
    this.errorMessage = ''
    this.replacementProduct = null
    this.lineRecId = getUniqueId()
  }
}

export class DefaultQuoteProduct extends DefaultProduct {
  constructor() {
    super()
    this.pceCode = null
    this.note = null
    this.ManualFreeGood = false
  }
}

export class DefaultCreditMemoProduct extends DefaultProduct {}
export class DefaultNoChargeProduct extends DefaultProduct {}
export class DefaultReturnProduct extends DefaultProduct {}
