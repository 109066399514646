const QuoteStatuses = {
  ORDER_ON_HOLD: 'ORDER_ON_HOLD',
  COMPLETED: 'COMPLETED',
  SUBMITTED: 'SUBMITTED',
  SAVED: 'SAVED',
  SENT: 'SENT',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  EXPIRED: 'EXPIRED',
  REVIEW: 'REVIEW',
  WORKFLOW: 'WORKFLOW'
}

Object.freeze(QuoteStatuses)

export { QuoteStatuses }
