import assignment from 'assignment'

import settings from '@/lib/core/settings'
import Storage from '@/lib/core/storage'

const sessionsKeyName = 'zcsessions'

class StoredSessions {
  /**
   * Get list of stored sessions.
   * @returns {*}
   */
  get sessions() {
    return assignment(this.getEmptySessions(), Storage.read(sessionsKeyName))
  }

  /**
   * Get last session.
   * @returns {*}
   */
  get lastSession() {
    const sessions = this.sessions
    if (!sessions.lastSessionId.length || !sessions.sessions.length) {
      return null
    }

    return this.find(sessions.lastSessionId)
  }

  /**
   * Get last session.
   * @returns {*}
   */
  get language() {
    const sessions = this.sessions
    if (!sessions.language.length) {
      const browserPreferredLanguage =
        window.navigator.userLanguage || window.navigator.language
      if (
        browserPreferredLanguage &&
        browserPreferredLanguage in settings.i18n.locales
      ) {
        return browserPreferredLanguage
      }

      return settings.i18n.defaultLocale
    }

    return sessions.language
  }

  /**
   * Get an object representing an empty sessions.
   * @returns {{lastSessionId: string, sessions: Array}}
   */
  getEmptySessions() {
    return {
      lastSessionId: '',
      language: '',
      sessions: []
    }
  }

  saveLanguage(language) {
    const sessions = this.sessions
    sessions.language = language
    this.store(sessions)
  }

  /**
   * Save session.
   * @param id
   * @param accessCode
   * @param data
   */
  save(id, accessCode, data) {
    const sessions = this.sessions
    if (!this.isStored(id)) {
      sessions.sessions.push({
        id,
        accessCode,
        data
      })
    } else {
      for (const i in sessions.sessions) {
        if (sessions.sessions[i].id === id) {
          sessions.sessions[i].data = data
          break
        }
      }
    }
    sessions.lastSessionId = id
    this.store(sessions)
  }

  /**
   * Check whether the list of sessions is empty or not.
   * @returns {boolean}
   */
  isEmpty() {
    return !this.sessions.sessions.length
  }

  /**
   * Remove session by id.
   * @param id
   */
  remove(id) {
    const sessions = this.sessions
    if (!sessions.sessions.length) {
      return
    }

    let found = false
    for (const i in sessions.sessions) {
      if (sessions.sessions[i].id === id) {
        found = true
        sessions.sessions.splice(i, 1)
        if (sessions.lastSessionId === id) {
          if (sessions.sessions.length > 0) {
            sessions.lastSessionId = sessions.sessions[0].id
          } else {
            sessions.lastSessionId = ''
          }
        }
        break
      }
    }

    if (found) {
      this.store(sessions)
    }
  }

  /**
   * Clear all stored sessions
   */
  clear() {
    this.store(this.getEmptySessions())
  }

  /**
   * Store sessions.
   * @param sessions
   */
  store(sessions) {
    Storage.save(sessionsKeyName, sessions)
  }

  /**
   * Check whether the session is stored or not.
   * @param id
   * @returns {boolean}
   */
  isStored(id) {
    const session = this.find(id)
    return !!session
  }

  /**
   * Find session by ID.
   * @param id
   * @returns {*}
   */
  find(id) {
    const sessions = this.sessions
    if (!sessions.sessions.length) {
      return null
    }

    for (const i in sessions.sessions) {
      if (sessions.sessions[i].id === id) {
        return sessions.sessions[i]
      }
    }

    return null
  }
}

export default new StoredSessions()
