import ApiBase from '@/lib/api/v1/api-base'
import auth from '@/lib/core/auth'

class Invoices extends ApiBase {
  /**
   * Get list of invoices.
   * @param userQuery
   * @returns {Promise<any>}
   */
  getInvoices(userQuery) {
    const userId = auth.getCurrentUserId()
    const url = this.getEndpointUrl(`/invoices/${userId}`)

    return new Promise((resolve, reject) => {
      this.transport
        .get(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}

export default Invoices
