<template>
  <div class="mt-powered-info flex items-center text-xs m-3 text-left">
    <div class="m-auto">
      <h3 class="mb-4 text-white font-normal uppercase text-base">
        {{ $t('footer.contact') }}
      </h3>
      <div class="flex mb-2 items-center md:items-start md:flex-col">
        <span class="mt-powered-info__contact-label">{{
          $t('footer.customerService')
        }}</span>
        <a
          class="hover:text-gray-50 no-underline md:mt-1"
          :href="`mailto:${email}`"
          >{{ email }}</a
        >
      </div>
      <div
        v-if="!isMexicoRegion"
        class="flex items-center mb-2 md:items-start md:flex-col"
      >
        <span class="mt-powered-info__contact-label">{{
          $t('footer.phone')
        }}</span>
        <div>
          <a
            class="block hover:text-gray-50 no-underline md:mt-1"
            :href="`tel:${$t('footer.phoneUS')}`"
            >{{ $t('footer.phoneUS') }} ({{ $t('footer.unitedStatesAbbr') }})</a
          >
          <a
            class="block hover:text-gray-50 no-underline md:mt-1"
            :href="`tel:${$t('footer.phoneUS')}`"
            >{{ $t('footer.phoneCAN') }} ({{ $t('footer.canadaAbbr') }})</a
          >
        </div>
      </div>
      <div
        v-if="!isMexicoRegion"
        class="flex items-center mb-2 md:items-start md:flex-col"
      >
        <span class="mt-powered-info__contact-label">{{
          $t('footer.address')
        }}</span>
        <div>
          <span class="block">13135 West Lisbon Road</span>
          <span class="block">Brookfield, WI 53005-2550</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { userStore } from '@/stores/user'

export default {
  name: 'PoweredInfo',
  computed: {
    ...mapState(userStore, ['isMexicoRegion']),
    email() {
      if (this.isMexicoRegion) {
        return this.$t('footer.email')
      }

      return this.$t('footer.emailUS')
    }
  }
}
</script>

<style scoped lang="scss">
.mt-powered-info {
  max-width: 28rem;

  &__contact-label {
    min-width: 10rem;
  }
}
</style>
