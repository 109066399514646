import get from 'lodash/get'

import settings from '@/lib/core/settings'

class IntegrityCheck {
  /**
   * Get an array of required settings
   * @returns {string[]}
   */
  get requiredSettings() {
    const requiredSettings = ['services.api.apiKey', 'services.api.baseUrl']
    return requiredSettings
  }

  /**
   * Get array of missing settings.
   * @returns {string[]}
   */
  get missingSettings() {
    // Check required settings
    const missingSettings = this.requiredSettings.filter((el) => {
      const value = get(settings, el)
      return !value || !value.length
    })
    return missingSettings
  }
}

export default IntegrityCheck
