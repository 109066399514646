import {
  ACCOUNT_STATUS_ERROR_CODE,
  ACCOUNT_GROUP_RESTRICTION_ERROR_CODE
} from '@/lib/models/cart/errorCodes'
import { CreditMemo } from '@/lib/models/credit-memo/creditMemo'
import { NoCharge } from '@/lib/models/no-charge/noCharge'
import { Quote } from '@/lib/models/quotes/quote'
import { Return } from '@/lib/models/return/return'

const CART_TYPES = {
  QUOTE: {
    routeName: 'quote-form',
    currentCart: 'currentQuote',
    type: 'None',
    model: Quote,
    errorCodes: [
      ACCOUNT_STATUS_ERROR_CODE,
      ACCOUNT_GROUP_RESTRICTION_ERROR_CODE
    ]
  },
  RETURN: {
    routeName: 'return-form',
    currentCart: 'currentReturn',
    type: 'Return',
    model: Return,
    errorCodes: [ACCOUNT_STATUS_ERROR_CODE]
  },
  NO_CHARGE: {
    routeName: 'no-charge-form',
    currentCart: 'currentNoCharge',
    type: 'No Charge',
    model: NoCharge,
    errorCodes: [
      ACCOUNT_STATUS_ERROR_CODE,
      ACCOUNT_GROUP_RESTRICTION_ERROR_CODE
    ]
  },
  CREDIT_MEMO: {
    routeName: 'credit-memo-form',
    currentCart: 'currentCreditMemo',
    type: 'Credit Memo',
    model: CreditMemo,
    errorCodes: [
      ACCOUNT_STATUS_ERROR_CODE,
      ACCOUNT_GROUP_RESTRICTION_ERROR_CODE
    ]
  }
}

Object.freeze(CART_TYPES)

export { CART_TYPES }
