import { CART_TYPES } from '@/lib/models/cart/cartTypes'
import { DefaultNoChargeProduct } from '@/lib/models/product/product'
import { getInternalformatDate } from '@/lib/utils/formatDate'
import { getCartDisabledStatusByCartStatus } from '@/lib/utils/helpers'
import { validateOrderSubmit } from '@/lib/utils/validation'

import { quotesStore } from '@/stores/quotes'

export class NoCharge {
  constructor(cart) {
    this.header = {
      APIType: cart?.header?.APIType ? cart.header.APIType : '',
      cartCode: cart?.header?.cartCode ? cart.header.cartCode : '',
      cartStatus: cart?.header?.cartStatus ? cart.header.cartStatus : '',
      createdBy: cart?.header?.createdBy ? cart.header.createdBy : '',
      currency: cart?.header?.currency ? cart.header.currency : '',
      customerAccount: cart?.header?.customerAccount
        ? cart.header.customerAccount
        : '',
      customerName: cart?.header?.customerName ? cart.header.customerName : '',
      errorCode: cart?.header?.errorCode ? cart.header.errorCode : '',
      errorMessage: cart?.header?.errorMessage ? cart.header.errorMessage : '',
      invoiceAccount: cart?.header?.invoiceAccount
        ? cart.header.invoiceAccount
        : '',
      lastUpdatedOn: cart?.header?.lastUpdatedOn
        ? getInternalformatDate(cart.header.lastUpdatedOn)
        : '',
      priceDate: cart?.header?.priceDate
        ? getInternalformatDate(cart.header.priceDate)
        : '',
      quoteCreationDate: cart?.header?.quoteCreationDate
        ? getInternalformatDate(cart.header.quoteCreationDate)
        : '',
      quoteExpirationDate: cart?.header?.quoteExpirationDate
        ? getInternalformatDate(cart.header.quoteExpirationDate)
        : '',
      salesId: cart?.header?.salesId ? cart.header.salesId : '',
      salesOriginId: cart?.header?.salesOriginId
        ? cart.header.salesOriginId
        : '',
      salesType: cart?.header?.salesType ? cart.header.salesType : '',
      shortTypeCode: cart?.header?.shortTypeCode
        ? cart.header.shortTypeCode
        : '',
      type: cart?.header?.type ? cart.header.type : 'No Charge'
    }

    this.deliveryAddress = cart?.deliveryAddress ? cart.deliveryAddress : null

    this.paymentAddress = cart?.paymentAddress ? cart.paymentAddress : null

    this.account = {
      contactName: cart?.account?.contactName ? cart.account.contactName : '',
      contactEmail: cart?.account?.contactEmail
        ? cart.account.contactEmail
        : '',
      customerRequirements: cart?.account?.customerRequirements
        ? cart.account.customerRequirements
        : '',
      invoiceAccount: cart?.account?.invoiceAccount
        ? cart.account.invoiceAccount
        : '',
      salesContact1: cart?.account?.salesContact1
        ? cart.account.salesContact1
        : '',
      salesContact2: cart?.account?.salesContact2
        ? cart.account.salesContact2
        : '',
      sendOrderAcknowledgementEmail: cart?.account
        ?.sendOrderAcknowledgementEmail
        ? cart.account.sendOrderAcknowledgementEmail
        : false
    }

    this.noChargeManagement = {
      noChargeReasonCode: cart?.noChargeManagement?.noChargeReasonCode,
      PO: cart?.noChargeManagement?.PO ? cart.noChargeManagement.PO : '',
      deliveryMode: cart?.noChargeManagement?.deliveryMode
        ? cart.noChargeManagement.deliveryMode
        : '',
      shippingDateRequested: cart?.noChargeManagement?.shippingDateRequested
        ? getInternalformatDate(cart.noChargeManagement.shippingDateRequested)
        : '',
      notes: cart?.noChargeManagement?.notes
        ? cart.noChargeManagement.notes
        : []
    }

    this.fulfillment = {
      warehouseId: cart?.fulfillment?.warehouseId
        ? cart.fulfillment.warehouseId
        : '',
      allowBackorder: cart?.fulfillment?.allowBackorder
        ? cart.fulfillment.allowBackorder
        : false,
      allowReplacements: cart?.fulfillment?.allowReplacements
        ? cart.fulfillment.allowReplacements
        : false,
      allowPartialLineShipment: cart?.fulfillment?.allowPartialLineShipment
        ? cart.fulfillment.allowPartialLineShipment
        : false,
      allowDropship: cart?.fulfillment?.allowDropship
        ? cart.fulfillment.allowDropship
        : false
    }

    this.shipping = {
      defaultShippingDate: cart?.shipping?.defaultShippingDate
        ? getInternalformatDate(cart.shipping.defaultShippingDate)
        : ''
    }
    this.internalNotes = cart?.internalNotes ? cart.internalNotes : ''
    this.autoSave = false

    this.id = cart?.header?.cartCode ? cart.header.cartCode : ''

    this.lines = cart?.lines ? cart.lines : []

    this.summary = {
      totalDiscounts: cart?.summary?.totalDiscounts
        ? cart.summary.totalDiscounts
        : null,
      subTotal: cart?.summary?.subTotal ? cart.summary.subTotal : null,
      totalTaxes: cart?.summary?.totalTaxes ? cart.summary.totalTaxes : null,
      totalPrice: cart?.summary?.totalPrice ? cart.summary.totalPrice : null,
      taxCalculationStatus: cart?.summary?.taxCalculationStatus
        ? cart.summary.taxCalculationStatus
        : null
    }

    this.attachments = cart?.attachments?.length ? cart.attachments : []
    this.charges = cart?.charges ? cart.charges : null

    this.validationErrors = cart?.validationErrors || {}

    this.isDisabledProductSuggest = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
    this.isDisabledQtyInput = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )

    this.cartType = CART_TYPES.NO_CHARGE.currentCart

    this.isDisabledByStatus = getCartDisabledStatusByCartStatus(
      this?.header.cartStatus
    )
  }

  updateField(payload, withPatchRequest = true) {
    quotesStore().updateNoChargeField(payload)
    if (withPatchRequest) return quotesStore().noChargeFieldChanged()
  }

  setNoteField(payload) {
    quotesStore().setNoteNoChargeField(payload)
  }

  createDefaultProduct() {
    return new DefaultNoChargeProduct()
  }

  createNew() {
    return quotesStore().createNoCharge()
  }

  submitValidationRules() {
    return {
      account: {
        contactEmail: {
          required: this.account.sendOrderAcknowledgementEmail
        },
        contactName: {
          required: this.account.sendOrderAcknowledgementEmail
        }
      },
      noChargeManagement: {
        deliveryMode: {
          required: true
        },
        shippingDateRequested: {
          required: true,
          lessThanSystemDate: true
        },
        noChargeReasonCode: {
          required: true
        }
      },
      fulfillment: {
        warehouseId: {
          required: true
        }
      }
    }
  }

  validateSubmitOrderPayload() {
    const validationRules = this.submitValidationRules()
    const sections = ['fulfillment', 'account', 'noChargeManagement']
    return validateOrderSubmit(sections, this, validationRules)
  }
}
