/* eslint-disable no-undef */
'use strict'

import assignment from 'assignment'
import assign from 'lodash/assign'
import get from 'lodash/get'
import set from 'lodash/set'

import defaultSettings from '@/../settings/settings.default'

const coreSettingsContext = require.context(
  '@/../settings/',
  true,
  /^\.\/.*\.json$/
)
const extensionSettingsContext = require.context(
  `../../../${EXTENSION_DIR}/settings/`,
  true,
  /^\.\/.*\.json$/
)

let settings = defaultSettings

// Try to load settings set from core / settings directory.
try {
  const loadedSettings = coreSettingsContext('./settings.json')
  settings = assignment(settings, loadedSettings)
} catch (e) {}

// Try to load settings set from extension / settings directory.
try {
  const extensionSettings = extensionSettingsContext('./settings.json')
  settings = assignment(settings, extensionSettings)
} catch (e) {}

// Override specific settings from environment variables
settings.services.api.apiKey = ENV_API_KEY || settings.services.api.apiKey
settings.services.api.baseUrl = ENV_API_BASEURL || settings.services.api.baseUrl
settings.app.extensionEnvVariables =
  EXTENSION_ENV_VARIABLES || settings.app.extensionEnvVariables

settings = {
  ...settings,
  get(path) {
    return get(settings, path, null)
  },
  feature(path) {
    return get(settings.features, path, null)
  },
  set(path, value) {
    const defaultValue = get(settings, path, {})
    set(this, path, assign(defaultValue, value))
  }
}

export default settings
