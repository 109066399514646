import BaseAuth from '@/lib/api/v1/auth'

class Auth extends BaseAuth {
  /**
   * Validate API key.
   * @returns {*}
   */
  validateApiKey() {
    const data = `username=${this.apiKey}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return this.transport.post('/j_spring_security_check', data, {
      headers,
      locale: ''
    })
  }
}

export default Auth
