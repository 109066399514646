import assignment from 'assignment'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { compareObjects } from '@/lib/utils/object'

const state = {
  items: []
}

const actions = {
  /**
   * Reset all notification items.
   */
  reset() {
    this.items = []
  },

  /**
   * Add a new notification to the list.
   * @param item
   */
  addNotification(item) {
    this.items.push(item)
  },

  /**
   * Set an array of notifications.
   * @param items
   */
  setNotifications(items) {
    this.items = items
  },

  updateNotification(payload) {
    if (!this.items || !this.items.length) {
      return
    }
    this.items.forEach((item, i) => {
      if (compareObjects(item, payload.notification)) {
        Vue.set(item, i, assignment(payload.notification, payload.data))
      }
    })
  }
}

export const notificationsStore = defineStore('notifications', {
  persist: true,
  state: () => ({
    ...state
  }),

  actions
})
