const DEFAULT_DEPENDENT_DETAILS_FIELDS = {
  reasonCodes: [],
  returnLocations: [],
  deliveryModes: [],
  costCenters: [],
  inventoryWarehouseIds: [],
  salesOriginIds: []
}

Object.freeze(DEFAULT_DEPENDENT_DETAILS_FIELDS)

export { DEFAULT_DEPENDENT_DETAILS_FIELDS }
