<template src="@/templates/common/i18n/LanguageSelector"></template>
<script>
import { mapActions } from 'pinia'

import LanguageList from '@/components/common/i18n/LanguageList'

import { langStore } from '@/stores/lang'
import languageSidebarTransition from '@/transitions/language-sidebar'

export default {
  name: 'LanguageSelector',
  components: { LanguageList },
  data() {
    return {
      isSelectorVisible: false,
      languageSidebarTransition
    }
  },
  computed: {
    locales() {
      const settings = Object.keys(this.$settings.i18n.locales).reduce(
        (a, b) => {
          const localeData = this.$settings.i18n.locales[b]
          if (localeData) a[b] = localeData
          return a
        },
        {}
      )
      return settings
    },

    currentLocale() {
      if (this.$i18n.locale in this.$settings.i18n.locales) {
        return this.$settings.i18n.locales[this.$i18n.locale]
      }

      if (
        this.$settings.i18n.defaultLocale &&
        this.$settings.i18n.defaultLocale in this.$settings.i18n.locales
      ) {
        return this.$settings.i18n.locales[this.$settings.i18n.defaultLocale]
      }

      return null
    },

    isEnabled() {
      return (
        !!this.$settings.i18n.enabled &&
        this.currentLocale &&
        Object.keys(this.currentLocale).length > 0 &&
        Object.keys(this.$settings.i18n.locales).length > 1
      )
    }
  },
  methods: {
    ...mapActions(langStore, ['setLang']),
    selectLocale(locale) {
      this.setLang(locale.id)
      this.hideLocales()
    },
    hideLocales() {
      this.isSelectorVisible = false
    },
    showLocales() {
      this.isSelectorVisible = true
    }
  }
}
</script>
