import SelectCartDialog from '@/components/b2b/cart/SelectCart/SelectCartDialog'

class SelectCart {
  constructor(Vue, options) {
    this.Vue = Vue
    this.mounted = false
    this.$root = {}
  }

  /**
   * Mount component.
   */
  mount() {
    if (this.mounted) {
      return
    }
    this.mounted = true
    this.$root = (() => {
      const Dialog = this.Vue.extend(SelectCartDialog)
      const node = document.createElement('div')
      document.querySelector('body').appendChild(node)
      return new Dialog().$mount(node)
    })()
  }

  /**
   * Destroy component.
   */
  destroy() {
    if (!this.mounted) {
      return
    }

    const domElement = this.$root.$el
    this.$root.destroy()
    this.$root.$off()
    domElement.remove()
    this.mounted = false
  }

  /**
   * Select cart.
   * @returns {Promise<any>}
   */
  select() {
    this.mount()
    return new Promise((resolve) => {
      this.$root.show()
      const root = this.$root
      this.$root.$on('close', () => {
        setTimeout(() => {
          root.hide()
          if (resolve) {
            resolve(null)
          }
        }, 1)
      })
      this.$root.$on('selected', (cartId) => {
        setTimeout(() => {
          root.hide()
          if (resolve) {
            resolve(cartId)
          }
        }, 1)
      })
    })
  }
}

export default {
  /**
   * Install plugin
   * @param Vue
   * @param options
   * @returns {SelectCart}
   */
  install(Vue, options) {
    Vue.selectCart = new SelectCart(Vue, options)
    Object.defineProperties(Vue.prototype, {
      $selectCart: {
        get() {
          return Vue.selectCart
        }
      }
    })
  }
}
